<template>
  <div>
    <b-modal
      v-model="showModule"
      body-bg-variant="light"
      dialog-class="dpia-modal"
      footer-bg-variant="light"
      footer-border-variant="light"
      size="xl"
      v-bind="{'no-close-on-backdrop':true, 'no-close-on-esc':true}"
      :hide-footer='true'
    >
      <template  v-slot:modal-header="{ close }">
          <div class="col">
            <h1
                v-if="evaluationId!==null"
                class="float-left"
            >
              {{$t(evaluation.name)}}
            </h1>
            <b-button
              aria-label="Close"
              class="activity-button-save btn-nc float-right"
              type="button"
              @click="close()"
            >
              <i class="nc-close mr-1" />
              {{ $t('general.close') }}
            </b-button>
          </div>
      </template>
      <template>
        <b-container>
          <b-row>
            <div class="activity-box col m-2 pt-1" v-if="evaluation.conclusion !=null">
              <h2>{{ $t('dpia.override') }}</h2>
              <p v-if="conclusion">{{ $t('dpia.overrideTextNeedDpia') }}</p>
              <p v-else>{{ $t('dpia.overrideTextNotNeedDpia') }}</p>
            <b-button
              id="changeresult"
              class="activity-button-save mr-1 btn-nc"
              @click="$refs.canceldelete.focus()"
            >
              <i class="nc-change mr-1" />
              {{ $t('dpia.override') }}
            </b-button>
            <b-popover
              placement="bottom"
              :show.sync="alterPopoverShow"
              target="changeresult"
              triggers="focus"
            >
              <template v-slot:title>{{ $t('dpia.overrideConclusionPopupTitle') }}</template>
              <p v-if="conclusion">{{ $t('dpia.overrideConclusionPopupTextNeedDpia') }}</p>
              <p v-else>{{ $t('dpia.overrideConclusionPopupTextNotNeedDpia') }}</p>
              <b-button
                ref="canceldelete"
                class="mr-1 btn-nc"
                tabindex="2"
                @click="alterPopoverShow = false"
              >
                <i class="nc-close mr-1" />
                {{ $t('general.no') }}
              </b-button>
              <b-button
                class="mr-1 btn-nc"
                tabindex="3"
                @click.prevent="changeConclusion(); alterPopoverShow = false; close()">
                <i class="nc-delete-shade-dark mr-1 mb-1" />
                {{ $t('general.yes') }}
              </b-button>
            </b-popover>
          </div>
        </b-row>
        <b-row>
          <div class="activity-box col m-2 pt-1" v-if="evaluation.conclusion !=null">
              <h2>{{ $t('dpia.changeStatusButton') }}</h2>
              <p>{{ $t('dpia.alterStatus') }}</p>
            <b-button
              id="changestatusbutton"
              class="activity-button-save mr-1 btn-nc"
              @click="$refs.cancelclose.focus()"
            >
              <i class="nc-change mr-1" />
              {{ $t('dpia.changeStatusButton') }}
            </b-button>
            <b-popover
              placement="bottom"
              :show.sync="changePopoverShow"
              target="changestatusbutton"
              triggers="focus"
            >
              <template v-slot:title>{{ $t('dpia.changeStatus') }}</template>
              <p>{{ $t('dpia.changeStatusInfo') }}</p>
              <b-button
                ref="cancelclose"
                class="mr-1 btn-nc"
                tabindex="2"
                @click="changePopoverShow = false"
              >
                <i class="nc-close mr-1" />
                {{ $t('general.no') }}
              </b-button>
              <b-button
                class="mr-1 btn-nc"
                tabindex="3"
                @click.prevent="changeStatus(); changePopoverShow = false; close()"
              >
                <i class="nc-change mr-1" />
                {{ $t('general.yes') }}
              </b-button>
            </b-popover>
          </div>
        </b-row>
        <b-row>
          <div class="activity-box col m-2 pt-1">
              <h2>{{ $t('dpia.deleteEvaluation') }}</h2>
              <p>{{ $t('dpia.deleteEvaluationtext') }}</p>
            <b-button
              id="softdeletebutton"
              class="activity-button-save mr-1 btn-nc"
              @click="$refs.canceldelete.focus()"
            >
              <i class="nc-delete-shade-dark mr-1" />
              {{ $t('general.delete') }}
            </b-button>
            <b-popover
              placement="bottom"
              :show.sync="deletePopoverShow"
              target="softdeletebutton"
              triggers="focus"
            >
              <template v-slot:title>{{ $t('dpia.delete') }}</template>
              <p>{{ $t('dpia.deleteInfo') }}</p>
              <b-button
                ref="canceldelete"
                class="mr-1 btn-nc"
                tabindex="2"
                @click="deletePopoverShow = false"
              >
                <i class="nc-close mr-1" />
                {{ $t('general.no') }}
              </b-button>
              <b-button
                class="mr-1 btn-nc"
                tabindex="3"
                @click.prevent="onDeleteClick(); deletePopoverShow = false; close()">
                <i class="nc-delete-shade-dark mr-1 mb-1" />
                {{ $t('general.yes') }}
              </b-button>
            </b-popover>
          </div>
        </b-row>
      </b-container>
    </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: 'dpia-adminpage',
  data: function () {
    return {
      showModule: false,
      deletePopoverShow: false,
      alterPopoverShow: false,
      changePopoverShow: false,
      evaluationId: null,
      evaluation: {},
      activityID: null,
      conclusion: null
    }
  },
  props: {},
  methods: {
    show: function (evaluation) {
      this.evaluation = evaluation
      this.evaluationId = evaluation.id
      this.conclusion = evaluation.conclusion
      this.showModule = !this.showModule
    },
    onDeleteClick () {
      this.$emit('delete-dpia')
    },
    changeConclusion () {
      this.$emit('changeconclusion-dpia')
    },
    changeStatus () {
      this.$emit('changestatus-dpia')
    }
  }
}
</script>
