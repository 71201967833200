<template>
    <b-container class="mt-4" role="main">
        <div class="shadow-sm bg-white pl-4 pr-4 pt-1 ml-4 mr-4 mb-4 mt-1">
            <div class="d-flex justify-content-between mt-1 mb-1">
                <div class="d-flex justify-content-start">
                    <h2 class="h2 pr-2 mt-1 mb-1 text-left">{{ $t("admin.controller") }}</h2>
                    <b-spinner variant="secondary" class="spinner" v-if="controllerLoading" />
                </div>
                <div class="d-flex justify-content-end">
                    <b-button class="btn-nc" v-on:keyup.enter="isControllerInfoEditMode = true" @click.prevent="isControllerInfoEditMode = true" v-if="!isControllerInfoEditMode" id="edit-button-controller">
                        <i class="nc-edit-blue mr-1" />
                        {{ $t("general.edit" )}}
                    </b-button>
                </div>
            </div>
            <div>
                <p class="helptext">{{ $t("admin.controllerHelpText") }}</p>
            </div>
            <div class="form-group row">
                <label for="controllerNameRole" class="h3 col-md-5 col-lg-3 col-form-label">{{ $t("admin.nameAndRole") }}</label>
                <span class="col-md-7 col-lg-9 col-form-label font-weight-bold" v-if="!isControllerInfoEditMode">{{ this.controller.personInformation }}</span>
                <b-form-textarea id="controllerNameRole"
                    class="col-md-7 col-lg-9 col-form-label"
                    v-model="controller.personInformation"
                    v-if="isControllerInfoEditMode"
                    ref="inputcontrollerNameRole"
                    rows="3"
                    max-rows="6">
                </b-form-textarea>
            </div>
            <div class="form-group row">
                <label for="controllerContactInformation" class="h3 col-md-5 col-lg-3 col-form-label">{{ $t("admin.contactInformation") }}</label>
                <span class="col-md-7 col-lg-9 col-form-label font-weight-bold multiline" v-if="!isControllerInfoEditMode">{{ this.controller.contacts }}</span>
                <b-form-textarea id="controllerContactInformation"
                    class="col-md-7 col-lg-9 col-form-label"
                    v-model="controller.contacts"
                    v-if="isControllerInfoEditMode"
                    ref="inputcontrollerContacts"
                    rows="3"
                    max-rows="6">
                </b-form-textarea>
            </div>
            <div class="form-group row">
                <b-button v-if="isControllerInfoEditMode" @click="updateControllerInfo" class="m-1 btn-nc-primary ml-auto"><i class="nc-save mr-2 mb-1" />{{ $t("general.save") }}</b-button>
                <b-button v-if="isControllerInfoEditMode" @click="isControllerInfoEditMode = false" class="m-1 btn-nc"><i class="nc-close mr-2 mb-1" />{{ $t("general.cancel") }}</b-button>
            </div>
        </div>
        <div class="shadow-sm bg-white pl-4 pr-4 pt-1 ml-4 mr-4 mb-4 mt-1">
            <div class="d-flex justify-content-between mt-1">
                <div class="d-flex justify-content-start">
                    <h2 class="h2 pr-2 mt-1 mb-1 text-left">{{ $t("admin.securityMeasures") }}</h2>
                    <b-spinner variant="secondary" class="spinner" v-if="securityMeasuresLoading" />
                </div>
                <div class="d-flex justify-content-end">
                    <b-button class="btn-nc" v-on:keyup.enter="isSecurityMeasuresEditMode= true"
                        @click.prevent="isSecurityMeasuresEditMode = true" v-if="!isSecurityMeasuresEditMode" id="edit-button-security">
                        <i class="nc-edit-blue mr-1" />
                        {{ $t("general.edit" )}}
                    </b-button>
                </div>
            </div>
            <div>
                <p class="helptext">{{ $t("admin.securityMeasuresHelpText") }}</p>
            </div>
            <div class="form-group row">
                <label for="securityMeasuresDescription" class="h3 col-md-5 col-lg-3 col-form-label">{{ $t("admin.description") }}</label>
                <span class="col-md-7 col-lg-9 col-form-label font-weight-bold multiline" v-if="!isSecurityMeasuresEditMode">{{ this.securityMeasures }}</span>
                <b-form-textarea id="securityMeasuresDescription"
                    class="col-md-7 col-lg-9 col-form-label"
                    v-model="securityMeasures"
                    v-if="isSecurityMeasuresEditMode"
                    ref="inputsecurityMeasures"
                    rows="5"
                    max-rows="10">
                </b-form-textarea>
            </div>
            <div class="form-group row">
                <b-button v-if="isSecurityMeasuresEditMode" @click="updateSecurityMeasures" class="m-1 btn-nc-primary ml-auto"><i class="nc-save mr-2 mb-1" /> {{ $t("general.save") }}</b-button>
                <b-button v-if="isSecurityMeasuresEditMode" @click="isSecurityMeasuresEditMode = false" class="m-1 btn-nc"><i class="nc-close mr-2 mb-1" />{{ $t("general.cancel") }}</b-button>
            </div>
        </div>
        <div class="shadow-sm bg-white pl-4 pr-4 pt-1 ml-4 mr-4 mb-4 mt-1">
            <div class="d-flex justify-content-between mt-1">
                <div class="d-flex justify-content-start">
                    <h2 class="h2 pr-2 mt-1 mb-1 text-left">{{ $t("admin.approval") }}</h2>
                    <b-spinner variant="secondary" class="spinner" v-if="approvalLoading" />
                </div>
                <div class="d-flex justify-content-end">
                    <b-button class="btn-nc" v-on:keyup.enter="isApprovalEditMode = true" @click.prevent="isApprovalEditMode = true"
                        v-if="!isApprovalEditMode" id="edit-button-approval">
                        <i class="nc-edit-blue mr-1" />
                        {{ $t("general.edit" )}}
                    </b-button>
                </div>
            </div>
            <div>
                <p class="helptext">{{ $t("admin.approvalHelpText") }}</p>
            </div>
            <div class="form-group row">
                <label class="h3 col-md-5 col-lg-3 col-form-label multiline">{{ $t("admin.date") }}</label>
                <div class="col col-form-label">
                    <date-picker-input
                        :value="approval.approvedDateTime"
                        :editMode="isApprovalEditMode"
                        @update-date="updateApprovalDate" />
                </div>
            </div>
            <div class="form-group row">
                <label for="approvalApprovedBy" class="h3 col-md-5 col-lg-3 col-form-label">{{ $t("admin.approvedBy") }}</label>
                <span class="col-md-7 col-lg-9 col-form-label font-weight-bold multiline" v-if="!isApprovalEditMode">{{ this.approval.approvedBy }}</span>
                <b-form-textarea id="approvalApprovedBy"
                    class="col-md-7 col-lg-9 col-form-label"
                    v-model="approval.approvedBy"
                    v-if="isApprovalEditMode"
                    ref="inputApprovalApprovedBy"
                    rows="3"
                    max-rows="6">
                </b-form-textarea>
            </div>
            <div class="form-group row">
                <label for="approvalArchiveReference" class="h3 col-md-5 col-lg-3 col-form-label">{{ $t("admin.archivesReference") }}</label>
                <span class="col-md-7 col-lg-9 col-form-label font-weight-bold multiline" v-if="!isApprovalEditMode">{{ this.approval.archiveReference }}</span>
                <b-form-textarea id="approvalArchiveReference"
                    class="col-md-7 col-lg-9 col-form-label"
                    v-model="approval.archiveReference"
                    v-if="isApprovalEditMode"
                    ref="inputApprovalArchivesReference"
                    rows="3"
                    max-rows="6">
                </b-form-textarea>
            </div>
            <div class="form-group row">
                <label for="approvalComment" class="h3 col-md-5 col-lg-3 col-form-label">{{ $t("admin.comment") }}</label>
                <span class="col-md-7 col-lg-9 col-form-label font-weight-bold multiline" v-if="!isApprovalEditMode">{{ this.approval.comment }}</span>
                <b-form-textarea id="approvalComment"
                    class="col-md-7 col-lg-9 col-form-label"
                    v-model="approval.comment"
                    v-if="isApprovalEditMode"
                    ref="inputComment"
                    rows="5"
                    max-rows="10">
                </b-form-textarea>
            </div>
            <div class="form-group row">
                <b-button v-if="isApprovalEditMode" @click="updateApprovalInfo" class="m-1 btn-nc-primary ml-auto"><i class="nc-save mr-2 mb-1" />{{ $t("general.save") }}</b-button>
                <b-button v-if="isApprovalEditMode" @click="isApprovalEditMode = false" class="m-1 btn-nc"><i class="nc-close mr-2 mb-1" />{{ $t("general.cancel") }}</b-button>
            </div>
        </div>
        <div class="shadow-sm bg-white pl-4 pr-4 pt-1 ml-4 mr-4 mb-4 mt-1">
            <div class="d-flex justify-content-between mt-1">
                <div class="d-flex justify-content-start">
                    <h2 class="h2 pr-2 mt-1 mb-1 text-left">{{ $t("admin.customerContact") }}</h2>
                    <b-spinner variant="secondary" class="spinner" v-if="approvalLoading" />
                </div>
                <div class="d-flex justify-content-end">
                    <b-button class="btn-nc" v-on:keyup.enter="isCustomerContactEditMode = true"
                        @click.prevent="isCustomerContactEditMode = true" v-if="!isCustomerContactEditMode" id="edit-button-customercontact">
                        <i class="nc-edit-blue mr-1" />
                        {{ $t("general.edit" )}}
                    </b-button>
                </div>
            </div>
            <div>
                <p class="helptext">{{ $t("admin.customerContactHelpText") }}</p>
            </div>
            <div class="form-group row">
                <label for="customerContactNameRole" class="h3 col-md-5 col-lg-3 col-form-label">{{ $t("admin.nameAndRole") }}</label>
                <span class="col-md-7 col-lg-9 col-form-label font-weight-bold multiline" v-if="!isCustomerContactEditMode">{{ this.customerContact.personInformation }}</span>
                <b-form-textarea id="customerContactNameRole"
                    class="col-md-7 col-lg-9 col-form-label"
                    v-model="customerContact.personInformation"
                    v-if="isCustomerContactEditMode"
                    ref="inputCustomerContactNameRole"
                    rows="3"
                    max-rows="6">
                </b-form-textarea>
            </div>
            <div class="form-group row">
                <label for="customerContactContactInformation" class="h3 col-md-5 col-lg-3 col-form-label">{{ $t("admin.contactInformation") }}</label>
                <span class="col-md-7 col-lg-9 col-form-label font-weight-bold multiline" v-if="!isCustomerContactEditMode">{{ this.customerContact.contacts }}</span>
                <b-form-textarea id="customerContactContactInformation"
                    class="col-md-7 col-lg-9 col-form-label"
                    v-model="customerContact.contacts"
                    v-if="isCustomerContactEditMode"
                    ref="inputCustomerContactContacts"
                    rows="3"
                    max-rows="6">
                </b-form-textarea>
            </div>
            <div class="form-group row">
                <b-button v-if="isCustomerContactEditMode" @click="updateCustomerContactInfo" class="m-1 btn-nc-primary ml-auto"><i class="nc-save mr-2 mb-1" />{{ $t("general.save") }}</b-button>
                <b-button v-if="isCustomerContactEditMode" @click="isCustomerContactEditMode = false" class="m-1 btn-nc"><i class="nc-close mr-2 mb-1" />{{ $t("general.cancel") }}</b-button>
            </div>
        </div>
    </b-container>
</template>
<script>
import axios from 'axios'

import DatePickerInput from '../components/common/DatePickerInput'

export default {
  components: {
    DatePickerInput
  },
  data: function () {
    return {
      controller: {},
      isControllerInfoEditMode: false,
      controllerLoading: false,
      securityMeasures: '',
      isSecurityMeasuresEditMode: false,
      securityMeasuresLoading: false,
      approval: {
        approvedDateTime: null
      },
      isApprovalEditMode: false,
      approvalLoading: false,
      customerContact: {},
      isCustomerContactEditMode: false,
      customerContactLoading: false
    }
  },
  async created () {
    await this.getController()
    await this.getSecurityMeasures()
    await this.getApprovalInfo()
    await this.getCustomerContactInfo()
  },
  methods: {
    async getController () {
      this.controllerLoading = true
      axios.get('/api/tenant/contactInformation').then((res) => {
        if (res.status === 200) { this.controller = res.data }
        this.controllerLoading = false
      }).catch(() => {
        this.controllerLoading = false
      })
    },
    updateControllerInfo () {
      this.isControllerInfoEditMode = false
      axios.patch('/api/tenant/contactInformation/', {
        personInformation: this.controller.personInformation,
        contacts: this.controller.contacts
      }).then(() => {
      })
    },
    async getSecurityMeasures () {
      this.securityMeasuresLoading = true
      axios.get('/api/tenant/securityMeasures').then((res) => {
        if (res.status === 200) { this.securityMeasures = res.data.securityMeasures }
        this.securityMeasuresLoading = false
      }).catch(() => {
        this.securityMeasuresLoading = false
      })
    },
    updateSecurityMeasures () {
      this.isSecurityMeasuresEditMode = false
      axios.patch('/api/tenant/securityMeasures/', {
        securityMeasures: this.securityMeasures
      }).then(() => {
      })
    },
    async getApprovalInfo () {
      this.approvalLoading = true
      axios.get('/api/tenant/tenantApproval').then((res) => {
        if (res.status === 200) {
          this.approval = res.data
          if (this.approval.approvedDateTime) this.approval.approvedDateTime = new Date(this.approval.approvedDateTime)
        }
        this.approvalLoading = false
      }).catch(() => {
        this.approvalLoading = false
      })
    },
    updateApprovalInfo () {
      this.isApprovalEditMode = false
      axios.patch('/api/tenant/tenantApproval/', {
        approvedBy: this.approval.approvedBy,
        archiveReferences: this.approval.archiveReference,
        comment: this.approval.comment,
        approvedDateTime: this.approval.approvedDateTime
      }).then(() => {
      })
    },
    async getCustomerContactInfo () {
      this.customerContactLoading = true
      axios.get('/api/tenant/contactInformationForVisma').then((res) => {
        if (res.status === 200) { this.customerContact = res.data }
        this.customerContactLoading = false
      }).catch(() => {
        this.customerContactLoading = false
      })
    },
    updateCustomerContactInfo () {
      this.isCustomerContactEditMode = false
      axios.patch('/api/tenant/contactInformationForVisma/', {
        personInformation: this.customerContact.personInformation,
        contacts: this.customerContact.contacts
      }).then(() => {
      })
    },
    updateApprovalDate (date) {
      this.approval.approvedDateTime = date
    }
  }
}
</script>
