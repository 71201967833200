<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="shadow-sm bg-white statisticsCard m-1 mw-500px">
    <div class="title">
      <h2 class="h2 pr-2 my-1 text-center">{{cardTitle}}</h2>
    </div>

    <div class="d-flex justify-content-center h-75 mt-2">
      <div
        class="h-25"
        v-if="!sortedDeadlineActivity && (sortActivityOn === 'lastModified')"
      >
        <h2 class="h2 text-muted text-center"> {{isAdminDashboard ? $t("dashboard.noActivitiesFound") : $t("dashboard.noEditedActivities")}}</h2>
      </div>

      <div
        class="h-25"
        v-if="!sortedDeadlineActivity && (sortActivityOn === 'nextDeadline')"
      >
        <h2 class="h2 text-muted text-center"> {{isAdminDashboard ? $t("dashboard.noActivitiesFound") : $t("dashboard.noUpcomingDeadlines")}} </h2>
      </div>

      <div
        v-else-if="sortedDeadlineActivity"
        class="w-100"
      >
        <board-activity-card
          @update-processing-activities="emitResetRequest()"
          :title="sortedDeadlineActivity.aboutProcessingActivity.title"
          :assignedUser="sortedDeadlineActivity.assignedUser.assignedTo.name"
          :deadlineDate="getDeadline(sortedDeadlineActivity.assignedUser.deadline)"
          :statusCode="sortedDeadlineActivity.assignedUser.statusCode"
          :activityId="sortedDeadlineActivity.id"
          :areas="sortedDeadlineActivity.aboutProcessingActivity.areas"
          :dataSubjects="sortedDeadlineActivity.aboutProcessingActivity.categoriesOfDataSubjects"
          :isDashboardActivity="true"
        />
      </div>
    </div>
    <activity-modal
      @update-processing-activities="emitResetRequest()"
      ref="activityModal"
      :statusOptions="this.statusOptions"
    ></activity-modal>
  </div>
</template>

<script>
import BoardActivityCard from './BoardActivityCard.vue'
import ActivityModal from '@/components/processingActivity/ActivityModal'

export default {
  name: 'dashboardactivity',
  components: { ActivityModal, BoardActivityCard },
  props: {
    cardTitle: {
      type: String
    },
    activities: {
      type: Array
    },
    sortActivityOn: {
      type: String
    },
    isAdminDashboard: {
      type: Boolean
    }
  },
  created: function () {
    this.sortOn = this.sortActivityOn
  },
  computed: {
    statusOptions: function () {
      return [
        {
          id: 0,
          name: this.$t('activity.activityStatus.statusNotSet')
        },
        {
          id: 1,
          name: this.$t('activity.activityStatus.statusApproved')
        },
        {
          id: 2,
          name: this.$t('activity.activityStatus.statusNotApproved')
        },
        {
          id: 3,
          name: this.$t('activity.activityStatus.statusUnderProgress')
        },
        {
          id: 4,
          name: this.$t('activity.activityStatus.statusReadyForReview')
        },
        {
          id: 5,
          name: this.$t('activity.activityStatus.statusNeedsFollowUp')
        }
      ]
    },
    sortedDeadlineActivity () {
      return this.sortOn === 'nextDeadline'
        ? this.activities
          .slice()
          .filter((a) => a.assignedUser.statusCode !== 1)
          .filter((a) => a.assignedUser.deadline !== '0001-01-01T00:00:00')
          .sort((a, b) =>
            new Date(a.assignedUser.deadline) -
                new Date(b.assignedUser.deadline))[0]
        : this.activities
          .slice()
          .sort((a, b) =>
            new Date(b.modifiedDateTime) - new Date(a.modifiedDateTime))[0]
    }
  },
  methods: {
    emitResetRequest: function () {
      this.$emit('update-processing-activities')
    },
    getDeadline (date) {
      if (date === '0001-01-01T00:00:00') {
        return this.$t('general.shortNoDeadline')
      }
      return date.replace('T00:00:00', '')
    },
    // Metoder for modalen:
    createActivity: function () {
      this.$refs.activityModal.toggle(null)
    },
    editActivity: function (activityId) {
      this.$refs.activityModal.toggle(activityId)
    },
    getActivityColor (statusCode) {
      if (statusCode === 0 || !statusCode) {
        return 'mr-2 mt-1 dot-grey'
      }
      if (statusCode === 1) {
        return 'mr-2 mt-1 dot-green'
      }
      if (statusCode === 2) {
        return 'mr-2 mt-1 dot-red'
      }
      if ((statusCode === 3) | 4) {
        return 'mr-2 mt-1 dot-yellow'
      }
      return 'mr-2 mt-1 dot-grey'
    },
    getStatusText (statusId) {
      return this.getStatusObject(statusId).name
    },
    getStatusObject (statusId) {
      if (!statusId) return this.statusOptions.find((obj) => obj.id === 0)
      if (statusId && (!statusId.id || !statusId.name)) {
        return this.statusOptions.find((obj) => obj.id === statusId)
      }
      return statusId
    }
  }
}
</script>
