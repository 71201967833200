<template>
  <div>
    <div v-if="editMode">
      <b-form-file
        v-if="!attachment"
        :id="id"
        v-model="selectedFile"
        :browse-text="$t('file.browse')"
        class="mb-2"
        :drop-placeholder="$t('file.dropFileHere')"
        :placeholder="$t('file.chooseAFileOrDropItHere')"
        :state="Boolean(selectedFile)"
        :title="$t('file.chooseAFileOrDropItHere')"></b-form-file>
    </div>
    <div v-if="attachment">
      <button v-if="editMode" class="btn btn-link" @click.prevent="removeAttachment(attachment.id)">
        <i class="nc-remove" />
        <span class="sr-only">Delete</span>
      </button>
      <b-link @click="downloadAttachment(attachment.id, attachment.name)">
        {{ attachment.name }}
      </b-link>
    </div>
    <p v-else-if="!editMode && !attachment" :id="id" class="empty-value">{{ emptyDescription }}</p>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'file-input',
  data: function () {
    return {
      selectedFile: null
    }
  },
  props: {
    emptyDescription: {
      type: String
    },
    id: {
      type: String
    },
    attachment: {
      type: Object
    },
    activityId: {
      type: String
    },
    editMode: {
      type: Boolean
    }
  },
  methods: {
    downloadAttachment (id, name) {
      axios.get('/api/processingActivities/' + this.activityId + '/attachments/' + id, { responseType: 'blob' }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', name)
        document.body.appendChild(link)
        link.click()
        link.remove()
        window.URL.revokeObjectURL(url)
      }).catch(error => {
        console.error(error)
      })
    },
    removeAttachment (id) {
      this.$emit('remove-attachment', id)
    },
    getBase64 (file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result.split(',')[1])
        reader.onerror = error => reject(error)
      })
    },
    getFileName (file) {
      if (file) return file.name
      else return null
    }
  },
  watch: {
    selectedFile: {
      handler () {
        const vm = this
        let attachmentPromise
        if (this.selectedFile) attachmentPromise = vm.getBase64(vm.selectedFile)
        else attachmentPromise = Promise.resolve(null)
        vm.fileName = vm.getFileName(vm.selectedFile)

        attachmentPromise.then(result => {
          const file = {
            fileName: this.getFileName(vm.selectedFile),
            fileContent64: result
          }

          this.$emit('update-attachment', file)
        })
      }
    }
  }
}
</script>
