<template>
  <div>
    <b-modal
      v-model="show"
      body-bg-variant="light"
      dialog-class="activity-modal"
      footer-bg-variant="light"
      footer-border-variant="light"
      size="xl"
      :title="getModalHeader(activityId, activity)"
      @hidden="resetActivity"
      v-bind="{'no-close-on-backdrop':editMode, 'no-close-on-esc':editMode}"
    >
      <template v-slot:modal-header="{ close }">
        <h1 class="float-left activity-modal-title text-break">
          {{getModalHeader(activityId, activity)}}
        </h1>
        <div class="ml-auto pl-36">
          <b-button
            v-if="!activityId && editMode && !isLoading"
            class="activity-button-save btn-nc-primary"
            @click.once="onSaveClick()"
          >
            <i class="nc-save mr-1" />
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-if="!editMode && !isLoading"
            class="activity-button-save mr-1 btn-nc"
            @click="onEditClick()"
          >
            <i class="nc-edit-dark mr-1 mb-1" />
            {{ $t('general.edit') }}
          </b-button>
          <b-button
            v-if="activityId && editMode && $route.meta.user.isAdmin && !isLoading"
            id="softdeletebutton"
            class="activity-button-save mr-1 btn-nc"
            @click="$refs.canceldelete.focus()"
          >
            <i class="nc-delete-shade-dark mr-1" />
            {{ $t('general.delete') }}
          </b-button>
          <b-popover
            v-if="activityId && editMode && $route.meta.user.isAdmin && !isLoading"
            placement="bottom"
            :show.sync="deletePopoverShow"
            target="softdeletebutton"
            triggers="focus"
          >
            <template v-slot:title>{{ $t('activity.delete') }}</template>
            <p>{{ $t('activity.deleteInfo') }} </p>
            <b-button
              ref="canceldelete"
              class="mr-1 btn-nc"
              tabindex="2"
              @click="deletePopoverShow = false"
            >
              <i class="nc-close mr-1" />
              {{ $t('general.no') }}
            </b-button>
            <b-button
              class="mr-1 btn-nc"
              tabindex="3"
              @click.prevent="onDeleteClick()"
            >
              <i class="nc-delete-shade-dark mr-1 mb-1" />
              {{ $t('general.yes') }}
            </b-button>
          </b-popover>
          <b-button
            v-if="activityId && editMode && !isLoading"
            class="activity-button-save btn-nc-primary"
            @click="onUpdateClick()"
          >
            <i class="nc-save mr-1" />
            {{ $t('general.save') }}
          </b-button>
        </div>
        <div class="pl-1">
          <b-button
            v-if="editMode && !isLoading"
            id="softclosebutton"
            class="activity-button-save mr-1 btn-nc"
            @click="focusSaveCloseBtn()"
          >
            <i class="nc-close mr-1" />
            {{ $t('general.close') }}
          </b-button>
          <b-popover
            v-if="editMode && !isLoading"
            placement="bottom"
            :show.sync="closePopoverShow"
            target="softclosebutton"
            triggers="focus"
          >
            <template v-slot:title>{{ $t('activity.close') }}</template>
            <p>{{ $t('activity.closeInfo') }} </p>
            <b-button
              v-if="activityId"
              ref="cancelclose"
              class="mr-1 btn-nc-primary"
              tabindex="2"
              @click="onUpdateClick(); close(); closePopoverShow = false"
            >
              <i class="nc-save mr-1" />
              {{ $t('activity.saveclose') }}
            </b-button>
            <b-button
              v-if="!activityId"
              ref="cancelclose"
              class="mr-1 btn-nc-primary"
              tabindex="2"
              @click="onSaveClick(); closePopoverShow = false"
            >
              <i class="nc-save mr-1" />
              {{ $t('activity.saveclose') }}
            </b-button>
            <b-button
              class="mr-1 btn-nc"
              tabindex="3"
              @click="close(); closePopoverShow = false"
            >
              <i class="nc-close mr-1" />
              {{ $t('general.close') }}
            </b-button>
          </b-popover>
          <b-button
            v-else
            aria-label="Close"
            class="activity-button-save btn-nc"
            type="button"
            @click="close()"
          >
            <i class="nc-close mr-1" />
            {{ $t('general.close') }}
          </b-button>
        </div>
      </template>
      <div v-if="activity">
        <b-alert
          dismissible
          :show="errMsg !== undefined && errMsg !== null"
          variant="danger"
          @dismissed="disableAlert"
        >
          {{errMsg}}
        </b-alert>
        <div class="text-status-header container">
          <div class="row row-margin">
            <b-list-group class="status-list">
              <b-list-group-item class="d-flex align-items-center status-background">
                <b-avatar
                  class="mr-3 mb-1 badge-primary2"
                  style="width: 25px; height: 25px;"
                ></b-avatar>
                <span
                  v-if="!editMode"
                  class="mr-auto"
                >{{getAssignedUser(activity.assignedUser)}}</span>
                <single-select-input
                  v-if="editMode"
                  id="assignedUser"
                  name="assignedUser"
                  class="color-multiselect"
                  :show-label="false"
                  :editMode="editMode"
                  :emptyDescription="$t('general.noAssignedUser')"
                  :options="getUserOptions()"
                  :placeholder="$t('activity.assignedSetting.assignedUser')"
                  :selectedOption="checkIsValidnGet(activity.assignedUser.assignedTo)"
                  @update-select="updateAssignedUser"
                />
              </b-list-group-item>
              <b-list-group-item class="d-flex align-items-center status-margin status-background">
                <b-icon
                  :class="this.$parent.getActivityColor(activity.assignedUser.statusCode)"
                  name="icontDot"
                  class="mb-1 ml-2"
                ></b-icon>
                <span
                  v-if="!editMode"
                  class="mr-auto"
                >{{this.$parent.getStatusText(activity.assignedUser.statusCode)}}</span>
                <single-select-input
                  v-if="editMode"
                  id="statusId"
                  name="statusId"
                  class="color-multiselect"
                  :editMode="editMode"
                  :emptyDescription="$t('general.noActiveStatus')"
                  :options="availableStatusOptions"
                  :placeholder="$t('activity.assignedSetting.activityStatus')"
                  :selectedOption="this.$parent.getStatusObject(activity.assignedUser.statusCode)"
                  @update-select="updateStatus"
                />
              </b-list-group-item>
              <b-list-group-item class="d-flex align-items-center status-margin status-background">
                <label
                  v-if="editMode && isValidDeadline(activity.assignedUser.deadline)"
                  for="example-datepicker"
                >{{$t('activity.assignedSetting.activityDeadline')}}</label>
                <i
                  v-if="!editMode"
                  class="mr-2 nc-calender"
                  name="iconCalender"
                  style="width: 20px; height: 20px;"
                />
                <span
                  v-if="!editMode"
                  class="mr-auto"
                >{{getDeadline(activity.assignedUser.deadline)}}</span>
                <b-form-datepicker
                  v-if="editMode"
                  id="example-datepicker"
                  :placeholder=getDeadline(activity.assignedUser.deadline)
                  v-model="activity.assignedUser.deadline"
                  class="ml-2 mb-2"
                  :min='new Date()'
                  :date-format-options="{ day: 'numeric', month: 'numeric', year: 'numeric' }"
                  locale="no"
                  @update-text="updateDeadlineDate"
                ></b-form-datepicker>
                <i
                  v-if="editMode && isValidDeadline(activity.assignedUser.deadline)"
                  style="width: 20px; height: 20px;"
                  :title="$t('general.removeDeadline')"
                  id="deadlineDate"
                  class="ml-2 nc-close"
                  tabindex="0"
                  @click="removeDeadline"
                  @keypress="removeDeadline"
                />
              </b-list-group-item>
            </b-list-group>
          </div>
        </div>
        <div
          v-if="isLoading"
          class="loadingOverlay d-flex justify-content-center"
        >
          <b-spinner
            class="spinner m-5"
            variant="secondary"
          >
            <span class="sr-only">Loading</span>
          </b-spinner>
        </div>
        <b-tabs
          v-model="tabIndex"
          active-nav-item-class="activity-tab-item-active"
          active-tab-class="activity-tab-content-active"
          align="left"
          content-class="mt-0"
          fill
          justified
          nav-class="activity-tab-item-wrapper"
        >
          <b-tab no-body>
            <template v-slot:title>
              <h2>{{$t('activity.aboutTheProcessingActivity')}}</h2>
            </template>
            <about-processing-activity
              :aboutProcessingActivity="activity.aboutProcessingActivity"
              :activityId="activityId"
              :editMode="editMode"
              v-on:error="errMsg = $event"
              @update-about-processing-activity="updateAboutProcessingActivity"
            />
          </b-tab>
          <b-tab no-body>
            <template v-slot:title>
              <h2>{{$t('activity.processingAndStorage')}}</h2>
            </template>
            <processing-and-storage
              :activityId="activityId"
              :editMode="editMode"
              :processing-and-storage="activity.processingAndStorageOfPersonalData"
              :selectedCategoriesOfPersonalData="activity.aboutProcessingActivity.selectedCategories"
              v-on:error="errMsg = $event"
              @update-processing-and-storage="updateProcessingAndStorageOfPersonalData"
            />
          </b-tab>
          <b-tab no-body>
            <template v-slot:title>
              <h2>{{$t('activity.disclosure')}}</h2>
            </template>
            <disclosures-of-data
              :activityId="activityId"
              :disclosuresOfData="activity.disclosuresOfData"
              :editMode="editMode"
              :isDataDisclosed="activity.isDataDisclosed"
              :selectedCategoriesOfPersonalData="activity.aboutProcessingActivity.selectedCategories"
              v-on:error="errMsg = $event"
              @update-disclosure-of-data="updateDisclosuresOfData"
              @update-is-data-disclosed="updateIsDataDisclosed"
            />
          </b-tab>
          <b-tab no-body>
            <template v-slot:title>
              <h2>{{$t('activity.routinesAndApproval')}}</h2>
            </template>
            <routine-and-best-practices
              :editMode="editMode"
              :routineAndBestPractices="activity.routineAndBestPractices"
              :activityId="activityId"
              :assignedUser="activity.assignedUser"
              v-on:error="errMsg = $event"
              @update-routine-and-best-practices="updateRoutineAndBestPractices"
            />
          </b-tab>
        </b-tabs>
      </div>
      <template v-slot:modal-footer>
        <b-container>
          <b-row>
            <b-col
              class="back-next-buttons"
              md="4"
              offset="4"
            >
              <b-button
                class="m-1 btn-nc"
                :disabled="tabIndex === 0"
                @click="tabIndex--"
              >
                {{ $t('general.back') }}
              </b-button>
              <b-button
                class="m-1 btn-nc"
                :disabled="tabIndex === 3"
                @click="tabIndex++"
              >
                {{ $t('general.next') }}
              </b-button>
            </b-col>
            <created-and-changed-by
              :data="activity"
              :userModifiedData=getUsername(activity.modifiedBy)
              :userCreatedData=getUsername(activity.createdBy)
            />
          </b-row>
        </b-container>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios'
import AboutProcessingActivity from './AboutProcessingActivity'
import ProcessingAndStorage from './ProcessingAndStorage'
import DisclosuresOfData from './DisclosuresOfData'
import RoutineAndBestPractices from './RoutineAndBestPractices'
import SingleSelectInput from '../common/SingleSelectInput'
import CreatedAndChangedBy from '../common/CreatedAndChangedBy'

export default {
  name: 'activity-modal',
  components: {
    AboutProcessingActivity,
    ProcessingAndStorage,
    DisclosuresOfData,
    RoutineAndBestPractices,
    SingleSelectInput,
    CreatedAndChangedBy
  },
  created () {
    this.getOptions()
    this.user = this.$route.meta.user
  },
  props: {
    statusOptions: {
      type: Array,
      default: function () {
        return []
      }
    }
  },

  computed: {
    console: () => console,
    window: () => window,
    availableStatusOptions: function () {
      if (this.user.isAdmin) {
        return this.statusOptions
      } else {
        const nonAdminsStatusOptionIds = [0, 3, 4, 5]
        const options = this.statusOptions.filter((x) =>
          nonAdminsStatusOptionIds.includes(x.id)
        )
        return options
      }
    }
  },
  data: function () {
    return {
      show: false,
      editMode: false,
      activityId: null,
      user: {
        name: '-',
        email: '-',
        isAdmin: false
      },
      tabIndex: 0,
      activity: {
        aboutProcessingActivity: {},
        processingAndStorageOfPersonalData: {},
        disclosuresOfData: {},
        routineAndBestPractices: {},
        assignedUser: {}
      },
      userOptions: [],
      errMsg: null,
      isLoading: false,
      isLoadingCodes: false,
      deletePopoverShow: false,
      closePopoverShow: false,
      activityColor: 'mr-2 dot-grey',
      deadlineDate: ''
    }
  },
  methods: {
    toggle (activityId) {
      this.tabIndex = 0
      if (activityId) {
        this.activityId = activityId
        this.editMode = false
        this.isLoading = true
        axios
          .get('api/processingActivities/' + activityId)
          .then((response) => {
            this.activity = response.data
            if (!this.activity.aboutProcessingActivity) {
              this.activity.aboutProcessingActivity = {}
            }
            if (!this.activity.aboutProcessingActivity.selectedCategories) {
              this.activity.aboutProcessingActivity.selectedCategories = []
            }
            if (!this.activity.processingAndStorageOfPersonalData) {
              this.activity.processingAndStorageOfPersonalData = {}
            }
            if (!this.activity.disclosuresOfData) {
              this.activity.disclosuresOfData = []
            }
            if (!this.activity.routineAndBestPractices) {
              this.activity.routineAndBestPractices = {}
            }
            if (!this.activity.assignedUser) {
              this.activity.assignedUser = {
                assignedTo: {},
                assignedToId: {},
                statusCode: 0,
                deadline: ''
              }
            }
          })
          .catch((e) => {
            this.errMsg =
              this.$t('errMsg.getGenericErrorMessage') + '\n' + e.message
          })
          .finally(() => {
            this.show = !this.show
            this.isLoading = false
          })
      } else {
        this.resetActivity()
        this.activity.assignedUser.assignedTo = this.$route.meta.user
        this.activity.assignedUser.assignedToId = this.$route.meta.user.id
        this.show = !this.show
      }
    },
    returnErrorCodeSwitch (errorCode) {
      switch (errorCode) {
        case 400:
          return this.$t('errMsg.post400ErrorMessage')
        case 409:
          return this.$t('errMsg.post409ErrorMessage')
        case 401:
          return this.$t('errMsg.post401ErrorMessage')
        case 404:
          return this.$t('errMsg.post404ErrorMessage')
        case 413:
          return this.$t('errMsg.post413ErrorMessage')
        default:
          return this.$t('errMsg.postGenericErrorMessage')
      }
    },
    async getOptions () {
      this.isLoadingCodes = true
      axios
        .get('/api/users')
        .then((response) => {
          this.userOptions = response.data
        })
        .catch((error) => {
          this.$emit(
            'error',
            this.$t('errMsg.getUsersToBeApprovedErrorMessage') +
              '\n' +
              error.message
          )
        })
        .finally(() => {
          this.isLoadingCodes = false
        })
    },
    getUserOptions () {
      return [{ name: this.$t('general.noAssignedUser') }, ...this.userOptions]
    },
    onEditClick () {
      this.editMode = true
    },
    onSaveClick () {
      this.isLoading = true
      axios
        .post('api/ProcessingActivities', this.activity)
        .then((response) => {
          this.updateProcessingActivities()
          this.activity = response.data
          this.editMode = false
          this.show = false
        })
        .catch((error) => {
          this.errMsg = this.returnErrorCodeSwitch(error.response.status)
        })
        .finally(() => {
          this.isLoading = false
          this.deletePopoverShow = false
          this.closePopoverShow = false
        })
    },
    onUpdateClick () {
      const tmpActivity = this.activity
      if (this.activity.assignedUser.assignedTo) {
        tmpActivity.assignedUser.assignedToId =
          this.activity.assignedUser.assignedTo.id
      }
      this.isLoading = true
      axios
        .put('api/processingActivities/' + this.activityId, tmpActivity)
        .then((response) => {
          this.activity = response.data
          this.editMode = false
          this.show = false
          this.updateProcessingActivities()
        })
        .catch((error) => {
          this.errMsg = this.returnErrorCodeSwitch(error.response.status)
        })
        .finally(() => {
          this.isLoading = false
          this.deletePopoverShow = false
          this.closePopoverShow = false
        })
    },
    onDeleteClick () {
      this.isLoading = true
      axios
        .delete('api/ProcessingActivities/' + this.activityId)
        .then(() => {})
        .then(() => {
          this.updateProcessingActivities()
          this.show = false
        })
        .catch((error) => {
          this.errMsg =
            this.$t('errMsg.deleteGenericErrorMessage') + '\n' + error.message
        })
        .finally(() => {
          this.isLoading = false
          this.deletePopoverShow = false
        })
    },
    resetActivity () {
      this.activityId = null
      this.activity = {
        aboutProcessingActivity: {},
        processingAndStorageOfPersonalData: {},
        disclosuresOfData: [],
        routineAndBestPractices: {},
        assignedUser: {}
      }
      this.errMsg = null
      this.editMode = true
    },
    disableAlert () {
      this.$emit('disable-alert')
      this.errMsg = null
    },
    setAssignedToId () {
      if (
        !this.activity.assignedUser.assignedToId &&
        this.activity.assignedUser.assignedTo
      ) {
        this.activity.assignedUser.assignedToId =
          this.activity.assignedUser.assignedTo.id
      }
    },
    updateProcessingAndStorageOfPersonalData (
      processingAndStorageOfPersonalData
    ) {
      this.activity.processingAndStorageOfPersonalData =
        processingAndStorageOfPersonalData
    },
    updateAboutProcessingActivity (aboutProcessingActivity) {
      this.activity.aboutProcessingActivity = aboutProcessingActivity
    },
    updateDisclosuresOfData (disclosuresOfData) {
      this.activity.disclosuresOfData = disclosuresOfData
    },
    updateIsDataDisclosed (isDataDisclosed) {
      this.activity.isDataDisclosed = isDataDisclosed
    },
    updateRoutineAndBestPractices (routineAndBestPractices) {
      this.activity.routineAndBestPractices = routineAndBestPractices
    },
    updateProcessingActivities () {
      this.$emit('update-processing-activities')
    },
    updateAssignedUser (newAssignedUser) {
      if (newAssignedUser && newAssignedUser.id) {
        this.activity.assignedUser.assignedToId = newAssignedUser.id
        this.activity.assignedUser.assignedTo = newAssignedUser
      } else {
        this.activity.assignedUser.assignedToId = null
        this.activity.assignedUser.assignedTo = null
      }
    },
    updateStatus (status) {
      if (!status) {
        this.activity.assignedUser.statusCode = 0
        this.activityColor = this.$parent.getActivityColor(0)
      }
      if (status) {
        this.activity.assignedUser.statusCode = status.id
        this.activityColor = this.$parent.getActivityColor(status.id)
      }
      this.setAssignedToId()
    },
    updateDeadlineDate (assignedDeadline) {
      this.activity.assignedUser.deadline = assignedDeadline
      this.setAssignedToId()
    },
    getModalHeader (activityId, activity) {
      if (!activityId) return this.$t('activity.newRecord')
      else return activity.aboutProcessingActivity.title
    },
    getUsername (user) {
      if (!user) return ''
      return user.name
    },
    getAssignedUser (user) {
      if (user && user.assignedTo && user.assignedTo.name) {
        return user.assignedTo.name
      }
      return this.$t('general.noAssignedUser')
    },
    getDeadline (date) {
      if (!date || date === '0001-01-01T00:00:00') {
        this.activity.assignedUser.deadline = null
        return this.$t('general.shortNoDeadline')
      }
      const trim = this.activity.assignedUser.deadline
      return trim.replace('T00:00:00', '')
    },
    isValidDeadline (date) {
      if (!date || date === '0001-01-01T00:00:00') {
        return false
      }
      return true
    },
    removeDeadline () {
      this.activity.assignedUser.deadline = null
    },
    checkIsValidnGet (user) {
      if (user && !user.id) {
        return null
      }
      return user
    },
    focusSaveCloseBtn () {
      if (!this.$refs.cancelclose) {
        setTimeout(() => {
          this.$refs.cancelclose.focus()
        }, 50)
      } else this.$refs.cancelclose.focus()
    }
  }
}
</script>
<style lang="scss">
@import "../../styles/_variables.scss";

.activity-modal {
  display: flex;
}

.activity-modal-title {
  max-width: 70%;
}

.activity-button-save {
  float: right;
}
.activity-tab-item-wrapper {
  border: none !important;
}
.text-status-header {
  display: block;
  border-bottom: 1px solid $nc-shade-brighter;
  margin-bottom: 1em;
}
.status-list {
  display: contents !important;
}
.row-margin {
  margin-bottom: 10px;
}
.text-footer {
  font-size: 0.8rem;
  text-align: right;
}
.status-margin {
  margin-left: 5rem;
}
.status-background {
  background-color: $background-color-3 !important;
}

.badge-primary2 {
  color: $white !important;
  background-color: $nc-blue-1 !important;
  border: none !important;
}
.back-next-buttons {
  text-align: center;
}
</style>
