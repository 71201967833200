<template>
  <b-row class="m-0">
    <b-col class="activity-box">
      <loading-overlay :isLoading="isLoadingCodes" />
      <div class="activity-box-part">
        <heading-and-label
          :editMode="editMode"
          labelFor="about-title"
          :level="3"
        >
          <span class="required">* </span>1. {{ $t('activity.aboutRecordAndProcessing.title') }}
        </heading-and-label>
        <text-input
          id="about-title"
          :editMode="editMode"
          :placeholder="$t('activity.aboutRecordAndProcessing.title')"
          :value="myAboutProcessingActivity.title"
          @update-text="updateTitle"
          :max-length="'750'"
        />
      </div>
      <div class="activity-box-part">
        <HelptextPopup
          target="Q2"
          :helptext="$t('helptext.q2')"
        />
        <heading-and-label
          :editMode="editMode"
          labelFor="about-purpose"
          :level="3"
          class="mt-1"
        >
          2. {{ $t('activity.aboutRecordAndProcessing.purpose') }}
        </heading-and-label>
        <text-area-input
          id="about-purpose"
          :editMode="editMode"
          :emptyDescription="$t('general.emptydata')"
          :placeholder="$t('activity.aboutRecordAndProcessing.purpose')"
          :value="myAboutProcessingActivity.purpose"
          @update-text="updatePurpose"
        />
      </div>
      <div class="activity-box-part">
        <heading-and-label
          :editMode="editMode"
          labelFor="about-areas"
          :level="3"
        >
          3. {{ $t('activity.aboutRecordAndProcessing.area') }}
        </heading-and-label>
        <multi-select-input
          id="about-areas"
          class="about-multiselect"
          :editMode="editMode"
          :emptyDescription="$t('general.emptydata')"
          :options="areasOptions"
          :placeholder="$t('activity.aboutRecordAndProcessing.area')"
          :selectedOptions="selectedAreas"
          @update-select="updateAreas"
        />
      </div>
      <div class="activity-box-part">
        <heading-and-label
          :editMode="editMode"
          labelFor="about-responsible-manager"
          :level="3"
        >
          4. {{ $t('activity.aboutRecordAndProcessing.responsibleManager') }}
        </heading-and-label>
        <text-input
          id="about-responsible-manager"
          :editMode="editMode"
          :emptyDescription="$t('general.emptydata')"
          :placeholder="$t('activity.aboutRecordAndProcessing.responsibleManagerPlaceholder')"
          :value="myAboutProcessingActivity.responsibleManager"
          @update-text="updateResponsibleManager"
        />
      </div>
      <div class="activity-box-part color-multiselect">
        <HelptextPopup
          target="Q5"
          :helptext="$t('helptext.q5')"
        />
        <heading-and-label
          :editMode="editMode"
          labelFor="about-lawfulness-of-processing"
          :level="3"
          class="mt-1"
        >
          5. {{ $t('activity.aboutRecordAndProcessing.lawfulnessOfProcessing') }}
        </heading-and-label>
        <lawfulness-of-processing
          id="about-lawfulness-of-processing"
          :activityId="activityId"
          :editMode="editMode"
          :emptyDescription="$t('general.emptydata')"
          :lawfulnessOfProcessing="myAboutProcessingActivity.lawfulnessOfProcessing"
          :legalObligations="legalObligations"
          @update-lawfulness-of-processing="updateLawfulnessOfProcessing"
        />
      </div>
    </b-col>
    <b-col class="activity-box">
      <loading-overlay :isLoading="isLoadingCodes" />
      <div class="activity-box-part">
        <heading-and-label
          :editMode="editMode"
          labelFor="about-categories-of-datasubjects"
          :level="3"
        >
          6. {{ $t('activity.aboutRecordAndProcessing.categoriesOfDataSubjects') }}
        </heading-and-label>
        <multi-select-input
          id="about-categories-of-datasubjects"
          class="categoriesOfDataSubjects-multiselect"
          :editMode="editMode"
          :emptyDescription="$t('general.emptydata')"
          :options="categoriesOfDataSubjects"
          :placeholder="$t('activity.aboutRecordAndProcessing.categoriesOfDataSubjects')"
          :selectedOptions="selectedCategoryOfDataSubjects"
          @update-select="updateCategoriesOfDataSubjects"
        />
      </div>
      <div class="activity-box-part">
        <HelptextPopup
          target="Q7"
          :helptext="$t('helptext.q7')"
        />
        <div>
          <h3 class="mt-1">7. {{ $t('activity.aboutRecordAndProcessing.categoriesOfPersonalData') }}</h3>
        </div>
        <div class="mb-2 mt-3 color-multiselect">
          <heading-and-label
            :editMode="editMode"
            labelFor="about-general-personal-data"
            :level="4"
          >
            {{ $t('activity.aboutRecordAndProcessing.generalPersonalData') }}
          </heading-and-label>
          <multi-select-input
            id="about-general-personal-data"
            :editMode="editMode"
            :emptyDescription="$t('general.emptydata')"
            :options="generalPersonalData"
            :placeholder="$t('activity.aboutRecordAndProcessing.generalPersonalData')"
            :selectedOptions="selectedGeneralData"
            @update-select="updateGeneralData"
          />
        </div>
        <div class="mb-2 color-multiselect">
          <heading-and-label
            :editMode="editMode"
            labelFor="about-special-categories"
            :level="4"
          >
            {{ $t('activity.aboutRecordAndProcessing.specialCategoriesOfPersonalData') }}
          </heading-and-label>
          <multi-select-input
            id="about-special-categories"
            :editMode="editMode"
            :emptyDescription="$t('general.emptydata')"
            :options="specialCategoriesOfPersonalData"
            :placeholder="$t('activity.aboutRecordAndProcessing.specialCategoriesOfPersonalData')"
            :selectedOptions="selectedSpecialData"
            @update-select="updateSpecialData"
          />
        </div>
        <div class="mb-2 color-multiselect">
          <heading-and-label
            :editMode="editMode"
            labelFor="about-criminal-and-offenses"
            :level="4"
          >
            {{ $t('activity.aboutRecordAndProcessing.personalDataRegardingCriminalJudgementAndOffenses') }}
          </heading-and-label>
          <multi-select-input
            id="about-criminal-and-offenses"
            :editMode="editMode"
            :emptyDescription="$t('general.emptydata')"
            :options="personalDataRegardingCriminalJudgementAndOffences"
            :placeholder="$t('activity.aboutRecordAndProcessing.personalDataRegardingCriminalJudgementAndOffenses')"
            :selectedOptions="selectedCriminalJudgmentAndOffensesData"
            @update-select="updateCriminalJudgmentAndOffenses"
          />
        </div>
      </div>
      <div class="activity-box-part">
        <div>
          <h3 class="mt-1">8. {{ $t('activity.aboutRecordAndProcessing.automated') }}</h3>
          <yes-no-input
            :editMode="editMode"
            :value.sync="myAboutProcessingActivity.isAutomated"
            :emptyDescription="this.$t('general.emptydata')"
            @update-yes-no="updateIsProcessingActivityAutomated" />
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import LawfulnessOfProcessing from './LawfulnessOfProcessing'
import TextInput from '../common/TextInput'
import TextAreaInput from '../common/TextAreaInput'
import MultiSelectInput from '../common/MultiSelectInput'
import axios from 'axios'
import LoadingOverlay from '../common/LoadingOverlay'
import HeadingAndLabel from '../common/HeadingAndLabel'
import HelptextPopup from '../processingActivity/HelptextPopup'
import YesNoInput from '../common/YesNoInput'

export default {
  name: 'about-processing-activity',
  components: {
    HeadingAndLabel,
    LawfulnessOfProcessing,
    LoadingOverlay,
    MultiSelectInput,
    TextAreaInput,
    TextInput,
    HelptextPopup,
    YesNoInput
  },
  created: function () {
    this.getOptions()
    this.setUp()
  },
  data: function () {
    return {
      myAboutProcessingActivity: {},
      areasOptions: [],
      legalObligations: [],
      categoriesOfDataSubjects: [],
      categoriesOfPersonalDataOptions: [],
      selectedAreas: [],
      selectedlawfulnesOfProcessingType: [],
      selectedCategoryOfDataSubjects: [],
      selectedGeneralData: [],
      selectedSpecialData: [],
      selectedCriminalJudgmentAndOffensesData: [],
      isLoadingCodes: false
    }
  },
  props: {
    activityId: {
      type: String
    },
    aboutProcessingActivity: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean
    }
  },
  computed: {
    generalPersonalData: function () {
      return this.categoriesOfPersonalDataOptions.filter((data) => {
        return data.type === 1
      })
    },
    specialCategoriesOfPersonalData: function () {
      return this.categoriesOfPersonalDataOptions.filter((data) => {
        return data.type === 2
      })
    },
    personalDataRegardingCriminalJudgementAndOffences: function () {
      return this.categoriesOfPersonalDataOptions.filter((data) => {
        return data.type === 3
      })
    }
  },
  methods: {
    setUp () {
      this.myAboutProcessingActivity = this.aboutProcessingActivity

      this.myAboutProcessingActivity.isAutomated = this.myAboutProcessingActivity.isAutomated === undefined
        ? null
        : this.aboutProcessingActivity.isAutomated

      this.selectedAreas = this.myAboutProcessingActivity.areas
        ? this.myAboutProcessingActivity.areas
        : []
      this.updateAreas(this.selectedAreas)

      if (!this.myAboutProcessingActivity.lawfulnessOfProcessing) {
        this.myAboutProcessingActivity.lawfulnessOfProcessing = {}
      }

      this.selectedCategoryOfDataSubjects = this.myAboutProcessingActivity
        .categoriesOfDataSubjects
        ? this.myAboutProcessingActivity.categoriesOfDataSubjects
        : []
      this.updateCategoriesOfDataSubjects(this.selectedCategoryOfDataSubjects)

      this.myAboutProcessingActivity.categoriesOfPersonalData = this
        .myAboutProcessingActivity.categoriesOfPersonalData
        ? this.myAboutProcessingActivity.categoriesOfPersonalData
        : {}
      this.myAboutProcessingActivity.categoriesOfPersonalData.categories = this
        .myAboutProcessingActivity.categoriesOfPersonalData.categories
        ? this.myAboutProcessingActivity.categoriesOfPersonalData.categories
        : []
      this.selectedGeneralData =
        this.myAboutProcessingActivity.categoriesOfPersonalData.categories.filter(
          (category) => category.type === 1
        )
      this.selectedSpecialData =
        this.myAboutProcessingActivity.categoriesOfPersonalData.categories.filter(
          (category) => category.type === 2
        )
      this.selectedCriminalJudgmentAndOffensesData =
        this.myAboutProcessingActivity.categoriesOfPersonalData.categories.filter(
          (category) => category.type === 3
        )
      this.updateGeneralData(this.selectedGeneralData)
      this.updateSpecialData(this.selectedSpecialData)
      this.updateCriminalJudgmentAndOffenses(
        this.selectedCriminalJudgmentAndOffensesData
      )
    },
    async getOptions () {
      this.isLoadingCodes = true
      axios
        .all([
          axios.get('/api/areas'),
          axios.get('/api/legalObligations'),
          axios.get('/api/categoriesOfDataSubjects'),
          axios.get('/api/categoriesOfPersonalData')
        ])
        .then(
          axios.spread((...responses) => {
            this.areasOptions = responses[0].data
            this.legalObligations = responses[1].data
            this.categoriesOfDataSubjects = responses[2].data
            this.categoriesOfPersonalDataOptions = responses[3].data
          })
        )
        .catch((errors) => {
          this.$emit(
            'error',
            this.$t('errMsg.getGenericErrorMessage') + ' ' + errors.message
          )
        })
        .finally(() => {
          this.isLoadingCodes = false
        })
    },
    updateTitle (title) {
      this.myAboutProcessingActivity.title = title
    },
    updatePurpose (purpose) {
      this.myAboutProcessingActivity.purpose = purpose
    },
    updateAreas (areas) {
      this.myAboutProcessingActivity.areasIds = areas.map((area) => area.id)
    },
    updateResponsibleManager (responsibleManager) {
      this.myAboutProcessingActivity.responsibleManager = responsibleManager
    },
    updateLawfulnessOfProcessing (lawfulnessOfProcessing) {
      this.myAboutProcessingActivity.lawfulnessOfProcessing =
        lawfulnessOfProcessing
    },
    updateCategoriesOfDataSubjects (categoriesOfDataSubjects) {
      this.myAboutProcessingActivity.categoryOfDataSubjectsIds =
        categoriesOfDataSubjects.map((category) => category.id)
    },
    updateGeneralData (generaldata) {
      this.selectedGeneralData = generaldata
      this.updateCategoriesOfPersonalData()
    },
    updateSpecialData (specialData) {
      this.selectedSpecialData = specialData
      this.updateCategoriesOfPersonalData()
    },
    updateCriminalJudgmentAndOffenses (criminalJudgmentAndOffenses) {
      this.selectedCriminalJudgmentAndOffensesData = criminalJudgmentAndOffenses
      this.updateCategoriesOfPersonalData()
    },
    updateCategoriesOfPersonalData () {
      let categories = []
      categories = categories.concat(this.selectedGeneralData)
      categories = categories.concat(this.selectedSpecialData)
      categories = categories.concat(
        this.selectedCriminalJudgmentAndOffensesData
      )
      this.myAboutProcessingActivity.categoriesOfPersonalData.categoryIds =
        categories.map((d) => d.id)
      this.myAboutProcessingActivity.selectedCategories = categories
    },
    updateIsProcessingActivityAutomated (isAutomated) {
      this.myAboutProcessingActivity.isAutomated = isAutomated
    }
  },
  watch: {
    myAboutProcessingActivity: {
      handler () {
        this.$emit(
          'update-about-processing-activity',
          this.myAboutProcessingActivity
        )
      },
      deep: true
    },
    aboutProcessingActivity: {
      handler () {
        this.setUp()
      }
    }
  }
}
</script>
<style lang="scss">
@import "../../styles/_variables.scss";
.nc-background {
  background-color: $background-color-2;
}
</style>
