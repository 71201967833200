<template>
  <div v-if="editMode">
    <b-form-textarea
      :id="id"
      v-model="textAreaInputCommentValue"
      class="mb-2"
      :placeholder="placeholder"
      :rows="rows"
      @change="updateTextAreaInputComment()"></b-form-textarea>
  </div>
  <div v-else>
    <p v-if="!editMode && textAreaInputCommentValue" class="multiline mt-2 mb-0 font-weight-bold ">{{ textAreaInputCommentValue }}</p>
    <p v-else-if="!editMode && !textAreaInputCommentValue" class="empty-value m-0">{{emptyDescription}} </p>
  </div>
</template>

<script>
export default {
  name: 'text-area-input-comment',
  props: {
    emptyDescription: {
      type: String
    },
    id: {
      type: String
    },
    value: {
      type: String
    },
    placeholder: {
      type: String
    },
    rows: {
      type: Number,
      default: 3
    },
    editMode: {
      type: Boolean
    }
  },
  created () {
    this.textAreaInputCommentValue = this.value
  },
  methods: {
    updateTextAreaInputComment () {
      this.textAreaInputCommentValue = this.textAreaInputCommentValue.trim()
      this.$emit('update-text', this.textAreaInputCommentValue)
    }
  }
}
</script>
