import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from '@/store'
import VueAppInsights from 'vue-application-insights'
import axios from 'axios'

import VueI18n from 'vue-i18n'
import { createI18n } from 'vue-i18n-bridge'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import '@/assests/css/nc4.css'
import './styles/site.scss'

import AuthService from './services/auth.service'

Vue.use(VueI18n, { bridge: true }) // you must specify '{ bridge: true }' plugin option when install vue-i18n
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

const i18n = createI18n({
  legacy: true,
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages: loadLocaleMessages()
}, VueI18n) // `createI18n` which is provide `vue-i18n-bridge` has second argument, you **must** pass `VueI18n` constructor which is provide `vue-i18n`

Vue.use(i18n) // you must install `i18n` instance which is created by `createI18n`

Vue.config.productionTip = true

Vue.prototype.$d = function (date) {
  const d = new Date(date)
  let month = '' + (d.getMonth() + 1)
  let day = '' + d.getDate()
  const year = d.getFullYear()

  if (month.length < 2) { month = '0' + month }
  if (day.length < 2) { day = '0' + day }

  return [year, month, day].join('-')
}

Vue.prototype.$authService = new AuthService()

/**
 * Handling language
 */
router.beforeEach((to, from, next) => {
  if (store.state.language.language && store.state.language.language !== i18n.locale) {
    i18n.locale = store.state.language.language
    next()
  } else if (!store.state.language.language) {
    store.dispatch('language/setLanguage', navigator.languages)
      .then(() => {
        i18n.locale = store.state.language.language
        next()
      })
  } else {
    next()
  }
})

/**
 * Handling authentication
 */
router.beforeEach(async (to, from, next) => {
  const { authorize } = to.meta // Informaton about roles required to access next routing

  if (authorize.length === 0) {
    next()
  } else {
    const isAuthenticated = Vue.prototype.$authService.isAuthenticatedWithMsal()
    if (isAuthenticated) {
      const user = await Vue.prototype.$authService.getUser()
      to.meta.user = user // Sending user objects to the next routing
      if (authorize.length > 0 && user && user.role && authorize.includes(user.role)) {
        next()
        // If authenticated but the route has no authorization
      } else if (authorize.length > 0) {
        next('/error')
      } else {
        if (to.path === '/login') {
          next('/')
        } else {
          next()
        }
      }
    } else {
      // Go back to login if the user is trying to access another site without authenticating
      if (to.path !== '/login') {
        next('/login')
      } else {
        next()
      }
    }
  }
})

/**
 * Handling api calls
 */
if (process.env.NODE_ENV !== 'development') {
  axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
}
axios.interceptors.request.use(async function (config) {
  const ret = await Vue.prototype.$authService.acquireInternalApiToken().then(token => {
    return `Bearer ${token}`
  })
  config.headers.Authorization = ret
  switch (i18n.locale) {
    case 'no':
      config.headers['Content-Language'] = 'nb-NO'
      break
    case 'en':
    default:
      config.headers['Content-Language'] = 'en-US'
  }
  return config
})

Vue.use(VueAppInsights, {
  id: process.env.VUE_APP_INSIGHTS_ID,
  router
})

function loadLocaleMessages () {
  const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  return messages
}

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
