<template>
  <div>
    <dashboard-data-wrapper :inputFilter="{
              areaIds: this.$route.meta.user.areas.map((area) => area.id)
            }">
      <template v-slot:filter="{filter, fetchActivitiesCallback}">
        <YourAreas
          @on-cancel="getSelectedAreas"
          @on-save="saveAreas"
          @on-reset="getAllAreas"
          :filter="filter"
          :callback="fetchActivitiesCallback"
          :areasProp="areas"
          :isLoading="isLoading"
        />
      </template>
      <template v-slot:areas="{activities}">
        <YourAreasGrid
          :activities="activities"
          :areasProp="isNoAreasSelected ? allAreas : areas"
        />
      </template>
    </dashboard-data-wrapper>
  </div>
</template>

<script>
import YourAreasGrid from '../components/dashboard/YourAreasGrid.vue'
import YourAreas from '../components/dashboard/YourAreas.vue'
import DashboardDataWrapper from '../components/dashboard/DashboardDataWrapper.vue'
import axios from 'axios'

export default {
  components: {
    YourAreasGrid,
    YourAreas,
    DashboardDataWrapper
  },
  data: function () {
    return {
      areas: this.$route.meta.user.areas,
      allAreas: [],
      isNoAreasSelected: !this.$route.meta.user.areas.length,
      isLoading: false
    }
  },
  created: function () {
    this.getAllAreas()
  },
  methods: {
    async getAllAreas () {
      this.isLoading = true
      axios
        .get('/api/areas')
        .then((res) => {
          if (res.status === 200) {
            this.allAreas = res.data
          }
        })
        .catch(() => {
          this.areasErrMsg = this.$t('errMsg.getGenericErrorMessage')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    async getSelectedAreas () {
      this.isLoading = true
      axios
        .get('/api/users/' + this.$route.meta.user.id)
        .then((res) => {
          if (res.status === 200) {
            this.areas = res.data.areas
          }
        })
        .catch(() => {
          this.areasErrMsg = this.$t('errMsg.getGenericErrorMessage')
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    saveAreas (areas) {
      this.areas = areas
    }
  },
  watch: {
    areas: function () {
      this.isNoAreasSelected = !this.areas.length
    }
  }
}
</script>
