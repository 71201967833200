<template>
<div>
    <b-row class="justify-content-md-center ml-5 mr-5 mt-5" role="main">
      <b-col col md="6">
        <StaticCodesRow
        :title="$t('admin.areas')"
        badgeClass='badge-area'
        :inData="this.areas"
        :inOnlyReadMode="!this.user.isAdmin"
        :inLoading="this.areasLoading"
        :inErrMsg="this.getLocaleErrorMessage(this.areasErrMsg)"
        @disableAlert="() => this.areasErrMsg = {}"
        @create="this.createArea"
        @remove="this.deleteArea"
        @update="this.updateArea"/>
        <StaticCodesRow
        :title="$t('admin.categoriesOfDataSubjects')"
        badgeClass='badge-category'
        :inData="this.categoriesOfDataSubjects"
        :inOnlyReadMode="!this.user.isAdmin"
        :inLoading="this.categoriesOfDataSubjectsLoading"
        :inErrMsg="this.getLocaleErrorMessage(this.categoriesOfDataSubjectsErrMsg) "
        @disableAlert="() => this.categoriesOfDataSubjectsErrMsg = {}"
        @create="this.createCategoryOfDataSubjects"
        @remove="this.deleteCategoryOfDataSubject"
        @update="this.updateCategoryOfDataSubjects"/>
        <div class="shadow-sm bg-white pl-4 pr-4 pb-4 pt-3 ml-4 mr-4 mb-4 mt-1">
          <h2 class="h2 pr-2 pb-1 mt-1 mb-2 text-left border-static-codes">{{ $t("admin.categoriesOfPersonalData") }}</h2>
          <StaticCodesRow
          :title="$t('admin.generalPersonalData')"
          rowClass='shadow-none border border-top-0 border-left-0 border-right-0 border-bottom-1'
          :inData="this.categoriesOfPersonalDataGeneral"
          :inOnlyReadMode="!this.user.isAdmin"
          :inLoading="this.categoriesOfPersonalDataGeneralLoading"
          :inErrMsg="this.getLocaleErrorMessage(this.categoriesOfPersonalDataGeneralErrMsg)"
          @disableAlert="() => this.categoriesOfPersonalDataGeneralErrMsg = {}"
          @create="(value, desc)=>this.createCategoryOfPersonalData(value, desc, 1)"
          @remove="(id)=>this.deleteCategoryOfPersonalData(id, 1)"
          @update="this.updateCategoryOfPersonalData"/>
          <StaticCodesRow
          :title="$t('admin.specialCategoriesOfPersonalData')"
          rowClass='shadow-none border border-top-0 border-left-0 border-right-0 border-bottom-1'
          :inData="this.categoriesOfPersonalDataSpecial"
          :inOnlyReadMode="!this.user.isAdmin"
          :inLoading="this.categoriesOfPersonalDataSpecialLoading"
          :inErrMsg="this.getLocaleErrorMessage(this.categoriesOfPersonalDataSpecialErrMsg)"
          @disableAlert="() => this.categoriesOfPersonalDataSpecialErrMsg = {}"
          @create="(value, desc)=>this.createCategoryOfPersonalData(value, desc, 2)"
          @remove="(id)=>this.deleteCategoryOfPersonalData(id, 2)"
          @update="this.updateCategoryOfPersonalData"/>
          <StaticCodesRow
          :title="$t('admin.personalDataRegardingCriminalJugdmentAndOffences')"
          rowClass='shadow-none border border-top-0 border-left-0 border-right-0 border-bottom-1'
          :inData="this.categoriesOfPersonalDataCriminalJudgmentAndOffenses"
          :inOnlyReadMode="!this.user.isAdmin"
          :inLoading="this.categoriesOfPersonalDataCriminalJudgmentAndOffensesLoading"
          :inErrMsg="this.getLocaleErrorMessage(this.categoriesOfPersonalDataCriminalJudgmentAndOffensesErrMsg)"
          @disableAlert="() => this.categoriesOfPersonalDataCriminalJudgmentAndOffensesErrMsg = {}"
          @create="(value, desc)=>this.createCategoryOfPersonalData(value, desc, 3)"
          @remove="(id)=>this.deleteCategoryOfPersonalData(id, 3)"
          @update="this.updateCategoryOfPersonalData"/>
        </div>
      </b-col>
      <b-col col md="6">
        <StaticCodesRow
        :title="$t('admin.storingSystems')"
        :inData="this.storingSystems"
        :inOnlyReadMode="!this.user.isAdmin"
        :inLoading="this.storingSystemsLoading"
        :inErrMsg="this.getLocaleErrorMessage(this.storingSystemsErrMsg)"
        @disableAlert="() => this.storingSystemsErrMsg = {}"
        @create="this.createStoringSystem"
        @remove="this.deleteStoringSystem"
        @update="this.updateStoringSystem"/>
        <StaticCodesRow :title="$t('admin.processingSystems')"
        :inData="this.processingSystems"
        :inOnlyReadMode="!this.user.isAdmin"
        :inLoading="this.processingSystemsLoading"
        :inErrMsg="this.getLocaleErrorMessage(this.processingSystemsErrMsg)"
        @disableAlert="() => this.processingSystemsErrMsg = {}"
        @create="this.createProcessingSystem"
        @remove="this.deleteProcessingSystem"
        @update="this.updateProcessingSystem"/>
        <StaticCodesRow :title="$t('admin.businessesOrganisations')"
        :inData="this.businessOrganisations"
        :inOnlyReadMode="!this.user.isAdmin"
        :inLoading="this.businessOrganisationsLoading"
        :inErrMsg="this.getLocaleErrorMessage(this.businessOrganisationsErrMsg)"
        @disableAlert="() => this.businessOrganisationsErrMsg = {}"
        @create="this.createBusinessOrganisation"
        @remove="this.deleteBusinessOrganisation"
        @update="this.updateBusinessOrganisation"/>
        <StaticCodesRow
        :title="$t('admin.lawfulnessOfProcessing')"
        :inData="this.legalObligations"
        :inOnlyReadMode="!this.user.isAdmin"
        :inLoading="this.legalObligationsLoading"
        :inErrMsg="this.getLocaleErrorMessage(this.legalObligationsErrMsg)"
        @disableAlert="() => this.legalObligationsErrMsg = {}"
        @create="this.createLegalObligation"
        @remove="this.deleteLegalObligation"
        @update="this.updateLegalObligation"/>
      <StaticCodesRow
      :title="$t('admin.legalObligationsFromChapterFive')"
      :inData="this.legalObligationsFromGdpr"
      :inOnlyReadMode="!this.user.isAdmin"
      :inLoading="this.legalObligationsFromGdprLoading"
      :inErrMsg="this.getLocaleErrorMessage(this.legalObligationsFromGdprErrMsg)"
      @disableAlert="() => this.legalObligationsFromGdprErrMsg = {}"
      @create="this.createlegalObligationsFromGdpr"
      @remove="this.deleteLegalObligationChapterFive"
      @update="this.updatelegalObligationsFromGdpr"/>
      </b-col>
    </b-row>
</div>
</template>
<script>
import axios from 'axios'
import StaticCodesRow from '../components/staticCodes/StaticCodesRow'
export default {
  name: 'static-code',
  components: {
    StaticCodesRow
  },
  data: function () {
    return {
      user: null,
      areas: [],
      areasLoading: false,
      areasErrMsg: {},
      categoriesOfDataSubjects: [],
      categoriesOfDataSubjectsLoading: false,
      categoriesOfDataSubjectsErrMsg: {},
      legalObligations: [],
      legalObligationsLoading: false,
      legalObligationsErrMsg: {},
      processingSystems: [],
      processingSystemsLoading: false,
      processingSystemsErrMsg: {},
      storingSystems: [],
      storingSystemsLoading: false,
      storingSystemsErrMsg: {},
      businessOrganisations: [],
      businessOrganisationsLoading: false,
      businessOrganisationsErrMsg: {},
      legalObligationsFromGdpr: [],
      legalObligationsFromGdprLoading: false,
      legalObligationsFromGdprErrMsg: {},
      categoriesOfPersonalData: [],
      categoriesOfPersonalDataLoading: false,
      categoriesOfPersonalDataErrMsg: {},
      categoriesOfPersonalDataGeneral: [],
      categoriesOfPersonalDataGeneralLoading: false,
      categoriesOfPersonalDataGeneralErrMsg: {},
      categoriesOfPersonalDataSpecial: [],
      categoriesOfPersonalDataSpecialLoading: false,
      categoriesOfPersonalDataSpecialErrMsg: {},
      categoriesOfPersonalDataCriminalJudgmentAndOffenses: [],
      categoriesOfPersonalDataCriminalJudgmentAndOffensesLoading: false,
      categoriesOfPersonalDataCriminalJudgmentAndOffensesErrMsg: {}
    }
  },
  async created () {
    this.user = this.$route.meta.user
    await this.getArea()
    await this.getCategoryOfDataSubjects()
    await this.getLegalObligation()
    await this.getProcessingSystem()
    await this.getStoringSystems()
    await this.getBusinessOrganisations()
    await this.getlegalObligationsFromGdpr()
    await this.getCategoriesOfPersonalData()
  },
  methods: {
    async getArea () {
      this.areasLoading = true
      axios.get('api/areas').then((res) => {
        if (res.status === 200) {
          this.areas = res.data
          this.areasErrMsg = {}
        }
        this.areasLoading = false
      }).catch(() => {
        this.areasErrMsg.type = 'errMsg.getGenericErrorMessage'
        this.areasLoading = false
      })
    },
    async createArea (name, description) {
      this.areasLoading = true
      axios.post('/api/areas/', { name, description }).then(async () => {
        await this.getArea()
        this.areasLoading = false
      }).catch(res => {
        this.areasErrMsg.type = this.checkResponse(res)
        this.areasLoading = false
      })
    },
    async updateArea (id, name, description) {
      this.areasLoading = true
      axios.put('/api/areas/' + id, { name, description }).then(async () => {
        await this.getArea()
        this.areasLoading = false
      }).catch(res => {
        this.areasErrMsg.type = this.checkResponse(res)
        this.areasLoading = false
      })
    },
    async deleteArea (id) {
      this.areasLoading = true
      axios.delete('/api/areas/' + id).then(async (res) => {
        if (res.status === 200) {
          await this.getArea()
          this.areasLoading = false
        }
      }).catch(res => {
        if (res.message.includes('409')) {
          const activities = this.getActivitiesWithStaticCodeWhenTryingToDeleteStaticCode(res)
          this.areasErrMsg.type = 'errMsg.deleteInUseErrorMessage'
          this.areasErrMsg.activities = activities
        } else {
          this.areasErrMsg.type = 'errMsg.deleteGenericErrorMessage'
        }
        this.areasLoading = false
      })
    },
    async getCategoryOfDataSubjects () {
      this.categoriesOfDataSubjectsLoading = true
      axios.get('/api/categoriesOfDataSubjects/').then((res) => {
        if (res.status === 200) {
          this.categoriesOfDataSubjects = res.data
          this.categoriesOfDataSubjectsErrMsg = {}
        }
        this.categoriesOfDataSubjectsLoading = false
      }).catch(() => {
        this.categoriesOfDataSubjectsErrMsg.type = 'errMsg.getGenericErrorMessage'
        this.categoriesOfDataSubjectsLoading = false
      })
    },
    async createCategoryOfDataSubjects (name, description) {
      this.categoriesOfDataSubjectsLoading = true
      axios.post('/api/categoriesOfDataSubjects/', { name, description }).then(async () => {
        await this.getCategoryOfDataSubjects()
        this.categoriesOfDataSubjectsLoading = false
      }).catch(res => {
        this.categoriesOfDataSubjectsErrMsg.type = this.checkResponse(res)
        this.categoriesOfDataSubjectsLoading = false
      })
    },
    async updateCategoryOfDataSubjects (id, name, description) {
      this.categoriesOfDataSubjectsLoading = true
      axios.put('/api/categoriesOfDataSubjects/' + id, { name, description }).then(async () => {
        await this.getCategoryOfDataSubjects()
        this.categoriesOfDataSubjectsLoading = false
      }).catch(res => {
        this.categoriesOfDataSubjectsErrMsg.type = this.checkResponse(res)
        this.categoriesOfDataSubjectsLoading = false
      })
    },
    async deleteCategoryOfDataSubject (id) {
      this.categoriesOfDataSubjectsLoading = true
      axios.delete('/api/categoriesOfDataSubjects/' + id).then(async (res) => {
        if (res.status === 200) {
          this.getCategoryOfDataSubjects()
          this.categoriesOfDataSubjectsLoading = false
        }
      }).catch(res => {
        if (res.message.includes('409')) {
          const activities = this.getActivitiesWithStaticCodeWhenTryingToDeleteStaticCode(res)
          console.debug(JSON.stringify(res))
          this.categoriesOfDataSubjectsErrMsg.type = 'errMsg.deleteInUseErrorMessage'
          this.categoriesOfDataSubjectsErrMsg.activities = activities
        } else {
          this.categoriesOfDataSubjectsErrMsg.type = 'errMsg.deleteGenericErrorMessage'
        }
        this.categoriesOfDataSubjectsLoading = false
      })
    },
    async getLegalObligation () {
      this.legalObligationsLoading = true
      axios.get('/api/legalObligations/').then((res) => {
        if (res.status === 200) {
          this.legalObligations = res.data
          this.legalObligationsErrMsg = {}
        }
        this.legalObligationsLoading = false
      }).catch(() => {
        this.legalObligationsErrMsg.type = 'errMsg.getGenericErrorMessage'
        this.legalObligationsLoading = false
      })
    },
    async createLegalObligation (name, description) {
      this.legalObligationsLoading = true
      axios.post('/api/legalObligations/', { name, description }).then(async () => {
        this.getLegalObligation()
        this.legalObligationsLoading = false
      }).catch(res => {
        this.legalObligationsErrMsg.type = this.checkResponse(res)
        this.legalObligationsLoading = false
      })
    },
    async updateLegalObligation (id, name, description) {
      this.legalObligationsLoading = true
      axios.put('/api/legalObligations/' + id, { name, description }).then(async () => {
        this.getLegalObligation()
        this.legalObligationsLoading = false
      }).catch(res => {
        this.legalObligationsErrMsg.type = this.checkResponse(res)
        this.legalObligationsLoading = false
      })
    },
    async deleteLegalObligation (id) {
      this.legalObligationsLoading = true
      axios.delete('/api/legalObligations/' + id).then(async (res) => {
        if (res.status === 200) {
          this.getLegalObligation()
          this.legalObligationsLoading = false
        }
      }).catch(res => {
        if (res.message.includes('409')) {
          const activities = this.getActivitiesWithStaticCodeWhenTryingToDeleteStaticCode(res)
          this.legalObligationsErrMsg.type = 'errMsg.deleteInUseErrorMessage'
          this.legalObligationsErrMsg.activities = activities
        } else {
          this.legalObligationsErrMsg.type = 'errMsg.deleteGenericErrorMessage'
        }
        this.legalObligationsLoading = false
      })
    },
    async getProcessingSystem () {
      this.processingSystemsLoading = true
      axios.get('/api/processingSystems/').then((res) => {
        if (res.status === 200) {
          this.processingSystems = res.data
          this.processingSystemsErrMsg = {}
        }
        this.processingSystemsLoading = false
      }).catch(() => {
        this.processingSystemsErrMsg.type = 'errMsg.getGenericErrorMessage'
        this.processingSystemsLoading = false
      })
    },
    createProcessingSystem (name, description) {
      this.processingSystemsLoading = true
      axios.post('/api/processingSystems/', { name, description }).then(async () => {
        await this.getProcessingSystem()
        this.processingSystemsLoading = false
      }).catch(res => {
        this.processingSystemsErrMsg.type = this.checkResponse(res)
        this.processingSystemsLoading = false
      })
    },
    updateProcessingSystem (id, name, description) {
      this.processingSystemsLoading = true
      axios.put('/api/processingSystems/' + id, { name, description }).then(async () => {
        await this.getProcessingSystem()
        this.processingSystemsLoading = false
      }).catch(res => {
        this.processingSystemsErrMsg.type = this.checkResponse(res)
        this.processingSystemsLoading = false
      })
    },
    deleteProcessingSystem (id) {
      this.processingSystemsLoading = true
      axios.delete('/api/processingSystems/' + id).then(async (res) => {
        if (res.status === 200) {
          await this.getProcessingSystem()
          this.processingSystemsLoading = false
        }
      }).catch(res => {
        if (res.message.includes('409')) {
          const activities = this.getActivitiesWithStaticCodeWhenTryingToDeleteStaticCode(res)
          this.processingSystemsErrMsg.type = 'errMsg.deleteInUseErrorMessage'
          this.processingSystemsErrMsg.activities = activities
        } else {
          this.processingSystemsErrMsg.type = 'errMsg.deleteGenericErrorMessage'
        }
        this.processingSystemsLoading = false
      })
    },
    async getStoringSystems () {
      this.storingSystemsLoading = true
      axios.get('/api/storingSystems/').then((res) => {
        if (res.status === 200) {
          this.storingSystems = res.data
          this.storingSystemsErrMsg = {}
        }
        this.storingSystemsLoading = false
      }).catch(() => {
        this.storingSystemsErrMsg.type = 'errMsg.getGenericErrorMessage'
        this.storingSystemsLoading = false
      })
    },
    createStoringSystem (name, description) {
      this.storingSystemsLoading = true
      axios.post('/api/storingSystems/', { name, description }).then(async () => {
        await this.getStoringSystems()
        this.storingSystemsLoading = false
      }).catch(res => {
        this.storingSystemsErrMsg.type = this.checkResponse(res)
        this.storingSystemsLoading = false
      })
    },
    updateStoringSystem (id, name, description) {
      this.storingSystemsLoading = true
      axios.put('/api/storingSystems/' + id, { name, description }).then(async () => {
        await this.getStoringSystems()
        this.storingSystemsLoading = false
      }).catch(res => {
        this.storingSystemsErrMsg.type = this.checkResponse(res)
        this.storingSystemsLoading = false
      })
    },
    deleteStoringSystem (id) {
      this.storingSystemsLoading = true
      axios.delete('/api/storingSystems/' + id).then(async (res) => {
        if (res.status === 200) {
          await this.getStoringSystems()
          this.storingSystemsLoading = false
        }
      }).catch(res => {
        if (res.message.includes('409')) {
          const activities = this.getActivitiesWithStaticCodeWhenTryingToDeleteStaticCode(res)
          this.storingSystemsErrMsg.type = 'errMsg.deleteInUseErrorMessage'
          this.storingSystemsErrMsg.activities = activities
        } else {
          this.storingSystemsErrMsg.type = 'errMsg.deleteGenericErrorMessage'
        }
        this.storingSystemsLoading = false
      })
    },
    async getBusinessOrganisations () {
      this.businessOrganisationsLoading = true
      axios.get('/api/businessOrganisations/').then((res) => {
        if (res.status === 200) {
          this.businessOrganisations = res.data
          this.businessOrganisationsErrMsg = {}
        }
        this.businessOrganisationsLoading = false
      }).catch(() => {
        this.businessOrganisationsErrMsg.type = 'errMsg.getGenericErrorMessage'
        this.businessOrganisationsLoading = false
      })
    },
    createBusinessOrganisation (name, description) {
      this.businessOrganisationsLoading = true
      axios.post('/api/businessOrganisations/', { name, description }).then(async () => {
        await this.getBusinessOrganisations()
        this.businessOrganisationsLoading = false
      }).catch(res => {
        this.businessOrganisationsErrMsg.type = this.checkResponse(res)
        this.businessOrganisationsLoading = false
      })
    },
    updateBusinessOrganisation (id, name, description) {
      this.businessOrganisationsLoading = true
      axios.put('/api/businessOrganisations/' + id, { name, description }).then(async () => {
        await this.getBusinessOrganisations()
        this.businessOrganisationsLoading = false
      }).catch(res => {
        this.businessOrganisationsErrMsg.type = this.checkResponse(res)
        this.businessOrganisationsLoading = false
      })
    },
    deleteBusinessOrganisation (id) {
      this.businessOrganisationsLoading = true
      axios.delete('/api/businessOrganisations/' + id).then(async (res) => {
        if (res.status === 200) {
          await this.getBusinessOrganisations()
          this.businessOrganisationsLoading = false
        }
      }).catch(res => {
        if (res.message.includes('409')) {
          const activities = this.getActivitiesWithStaticCodeWhenTryingToDeleteStaticCode(res)
          this.businessOrganisationsErrMsg.type = 'errMsg.deleteInUseErrorMessage'
          this.businessOrganisationsErrMsg.activities = activities
        } else {
          this.businessOrganisationsErrMsg.type = 'errMsg.deleteGenericErrorMessage'
        }
        this.businessOrganisationsLoading = false
      })
    },
    async getlegalObligationsFromGdpr () {
      this.legalObligationsFromGdprLoading = true
      axios.get('/api/legalObligationsFromGdpr/').then((res) => {
        if (res.status === 200) {
          this.legalObligationsFromGdpr = res.data
          this.legalObligationsFromGdprErrMsg = {}
        }
        this.legalObligationsFromGdprLoading = false
      }).catch(() => {
        this.legalObligationsFromGdprErrMsg.type = 'errMsg.getGenericErrorMessage'
        this.legalObligationsFromGdprLoading = false
      })
    },
    createlegalObligationsFromGdpr (name, description) {
      this.legalObligationsFromGdprLoading = true
      axios.post('/api/legalObligationsFromGdpr/', { name, description }).then(async () => {
        await this.getlegalObligationsFromGdpr()
        this.legalObligationsFromGdprLoading = false
      }).catch(res => {
        this.legalObligationsFromGdprErrMsg.type = this.checkResponse(res)
        this.legalObligationsFromGdprLoading = false
      })
    },
    updatelegalObligationsFromGdpr (id, name, description) {
      this.legalObligationsFromGdprLoading = true
      axios.put('/api/legalObligationsFromGdpr/' + id, { name, description }).then(async () => {
        await this.getlegalObligationsFromGdpr()
        this.legalObligationsFromGdprLoading = false
      }).catch(res => {
        this.legalObligationsFromGdprErrMsg.type = this.checkResponse(res)
        this.legalObligationsFromGdprLoading = false
      })
    },
    deleteLegalObligationChapterFive (id) {
      this.legalObligationsFromGdprLoading = true
      axios.delete('/api/legalObligationsFromGdpr/' + id).then(async (res) => {
        if (res.status === 200) {
          await this.getlegalObligationsFromGdpr()
          this.legalObligationsFromGdprLoading = false
        }
      }).catch(res => {
        if (res.message.includes('409')) {
          const activities = this.getActivitiesWithStaticCodeWhenTryingToDeleteStaticCode(res)
          this.legalObligationsFromGdprErrMsg.type = 'errMsg.deleteInUseErrorMessage'
          this.legalObligationsFromGdprErrMsg.activities = activities
        } else {
          this.legalObligationsFromGdprErrMsg.type = 'errMsg.deleteGenericErrorMessage'
        }
        this.legalObligationsFromGdprLoading = false
      })
    },
    async getCategoriesOfPersonalData () {
      this.categoriesOfPersonalDataGeneralLoading = true
      this.categoriesOfPersonalDataSpecialLoading = true
      this.categoriesOfPersonalDataCriminalJudgmentAndOffensesLoading = true
      axios.get('/api/categoriesOfPersonalData/').then((res) => {
        if (res.status === 200) {
          this.categoriesOfPersonalDataGeneral = res.data.filter((category) => category.type === 1)
          this.categoriesOfPersonalDataSpecial = res.data.filter((category) => category.type === 2)
          this.categoriesOfPersonalDataCriminalJudgmentAndOffenses = res.data.filter((category) => category.type === 3)
          this.categoriesOfPersonalDataGeneralErrMsg = {}
          this.categoriesOfPersonalDataSpecialErrMsg = {}
          this.categoriesOfPersonalDataCriminalJudgmentAndOffensesErrMsg = {}
        }
        this.categoriesOfPersonalDataGeneralLoading = false
        this.categoriesOfPersonalDataSpecialLoading = false
        this.categoriesOfPersonalDataCriminalJudgmentAndOffensesLoading = false
      }).catch(() => {
        this.categoriesOfPersonalDataGeneralErrMsg.type = 'errMsg.getGenericErrorMessage'
        this.categoriesOfPersonalDataSpecialErrMsg.type = 'errMsg.getGenericErrorMessage'
        this.categoriesOfPersonalDataCriminalJudgmentAndOffensesErrMsg.type = 'errMsg.getGenericErrorMessage'
        this.categoriesOfPersonalDataGeneralLoading = false
        this.categoriesOfPersonalDataSpecialLoading = false
        this.categoriesOfPersonalDataCriminalJudgmentAndOffensesLoading = false
      })
    },
    createCategoryOfPersonalData (name, description, type) {
      this.categoriesOfPersonalDataGeneralLoading = true
      this.categoriesOfPersonalDataSpecialLoading = true
      this.categoriesOfPersonalDataCriminalJudgmentAndOffensesLoading = true
      axios.post('/api/categoriesOfPersonalData/', { name, description, type }).then(async () => {
        await this.getCategoriesOfPersonalData()
        this.categoriesOfPersonalDataGeneralLoading = false
        this.categoriesOfPersonalDataSpecialLoading = false
        this.categoriesOfPersonalDataCriminalJudgmentAndOffensesLoading = false
      }).catch(res => {
        if (type === 1) this.categoriesOfPersonalDataGeneralErrMsg.type = this.checkResponse(res)
        if (type === 2) this.categoriesOfPersonalDataSpecialErrMsg.type = this.checkResponse(res)
        if (type === 3) this.categoriesOfPersonalDataCriminalJudgmentAndOffensesErrMsg.type = this.checkResponse(res)
        this.categoriesOfPersonalDataGeneralLoading = false
        this.categoriesOfPersonalDataSpecialLoading = false
        this.categoriesOfPersonalDataCriminalJudgmentAndOffensesLoading = false
      })
    },
    updateCategoryOfPersonalData (id, name, description, type) {
      this.categoriesOfPersonalDataGeneralLoading = true
      this.categoriesOfPersonalDataSpecialLoading = true
      this.categoriesOfPersonalDataCriminalJudgmentAndOffensesLoading = true
      axios.put('/api/categoriesOfPersonalData/' + id, { name, description, type }).then(async () => {
        await this.getCategoriesOfPersonalData()
        this.categoriesOfPersonalDataGeneralLoading = false
        this.categoriesOfPersonalDataSpecialLoading = false
        this.categoriesOfPersonalDataCriminalJudgmentAndOffensesLoading = false
      }).catch(res => {
        if (type === 1) this.categoriesOfPersonalDataGeneralErrMsg.type = this.checkResponse(res)
        if (type === 2) this.categoriesOfPersonalDataSpecialErrMsg.type = this.checkResponse(res)
        if (type === 3) this.categoriesOfPersonalDataCriminalJudgmentAndOffensesErrMsg.type = this.checkResponse(res)
        this.categoriesOfPersonalDataGeneralLoading = false
        this.categoriesOfPersonalDataSpecialLoading = false
        this.categoriesOfPersonalDataCriminalJudgmentAndOffensesLoading = false
      })
    },
    deleteCategoryOfPersonalData (id, type) {
      this.categoriesOfPersonalDataGeneralLoading = true
      this.categoriesOfPersonalDataSpecialLoading = true
      this.categoriesOfPersonalDataCriminalJudgmentAndOffensesLoading = true
      axios.delete('/api/categoriesOfPersonalData/' + id).then(async (res) => {
        if (res.status === 200) {
          await this.getCategoriesOfPersonalData()
          this.categoriesOfPersonalDataGeneralLoading = false
          this.categoriesOfPersonalDataSpecialLoading = false
          this.categoriesOfPersonalDataCriminalJudgmentAndOffensesLoading = false
        }
      }).catch(res => {
        if (res.message.includes('409')) {
          const activities = this.getActivitiesWithStaticCodeWhenTryingToDeleteStaticCode(res)
          if (type === 1) {
            this.categoriesOfPersonalDataGeneralErrMsg.type = 'errMsg.deleteInUseErrorMessage'
            this.categoriesOfPersonalDataGeneralErrMsg.activities = activities
            console.debug(JSON.stringify(this.categoriesOfPersonalDataGeneralErrMsg))
          }
          if (type === 2) {
            this.categoriesOfPersonalDataSpecialErrMsg.type = 'errMsg.deleteInUseErrorMessage'
            this.categoriesOfPersonalDataSpecialErrMsg.activities = activities
          }
          if (type === 3) {
            this.categoriesOfPersonalDataCriminalJudgmentAndOffensesErrMsg.type = 'errMsg.deleteInUseErrorMessage'
            this.categoriesOfPersonalDataCriminalJudgmentAndOffensesErrMsg.activities = activities
          }
        } else {
          if (type === 1) this.categoriesOfPersonalDataGeneralErrMsg.type = 'errMsg.deleteGenericErrorMessage'
          if (type === 2) this.categoriesOfPersonalDataSpecialErrMsg.type = 'errMsg.deleteGenericErrorMessage'
          if (type === 3) this.categoriesOfPersonalDataCriminalJudgmentAndOffensesErrMsg.type = 'errMsg.deleteGenericErrorMessage'
        }
        this.categoriesOfPersonalDataGeneralLoading = false
        this.categoriesOfPersonalDataSpecialLoading = false
        this.categoriesOfPersonalDataCriminalJudgmentAndOffensesLoading = false
      })
    },
    getActivitiesWithStaticCodeWhenTryingToDeleteStaticCode (errorResponse) {
      const activities = []
      if (errorResponse.response.data.data) {
        for (const [key, value] of Object.entries(errorResponse.response.data.data)) {
          if (value === null || key === 'system.Object') { continue }
          const act = {}
          act.url = window.location.origin + '/?activity=' + key
          act.id = key
          act.title = value.aboutProcessingActivity.title
          activities.push(act)
        }
      }
      return activities
    },
    checkResponse (res) {
      if (res.response.status === 500) {
        return 'errMsg.saveGenericErrorMessage'
      }
      if (res.response.status === 408) {
        return 'errMsg.timeOutErrorMessage'
      }
      if (res.response.status === 401) {
        return 'errMsg.unauthorizedErrorMessage'
      }
      if (res.response.status === 409) {
        return 'staticCodes.codeInUseError'
      }
      return 'errMsg.genericErrorMessage'
    },
    getLocaleErrorMessage (errorMessage) {
      if (errorMessage.type) {
        errorMessage.text = this.$t(errorMessage.type)
      }
      return errorMessage
    }
  },
  watch: {
    $route () {
      this.user = this.$route.meta.user
    }
  }
}
</script>
