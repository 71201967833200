<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="container-fluid">
    <b-row>
      <b-col class="col-md-6 col-lg-4 col-xl-3">
        <!-- create new button -->
        <div class="pt-4 pb-2">
          <b-button
            class="btn-nc"
            tabindex="0"
            v-on:click="createDpiaEvaluation()"
            v-on:keyup.enter="createDpiaEvaluation()"
          >
            <i class="nc-add-blue mb-1 mr-1" />
            {{ $t('general.createNew' ) + ' ' + $t('dpia.dpiaEvaluation' )}}
          </b-button>
        </div>
        <!-- filter component -->
        <filter-component
          :filter="filter"
          @update-filter="updateFilter"
        />
      </b-col>
      <!-- search -->
      <b-col
        class="col-12 col-md-6 col-lg-8 col-xl-9"
        role="main"
      >
        <div class="container-fluid mt-4">
          <div class="d-inline">
            <b-row class="search-row">
              <search @update-search-text="updateSearchText" />
              <b-col
                md="3"
                sm="6"
              >
                <b-form-select
                  v-model="selectedSort"
                  :aria-label="$t('navigation.sortingoptions')"
                  :options="sortingOptions"
                />
              </b-col>
              <!-- grid & list view buttons -->
              <b-col>
                <b-button-group class="pl-4 float-right">
                  <b-button
                    class="btn-nc"
                    tabindex="0"
                    v-on:click="switchView()"
                    v-on:keyup.enter="switchView()"
                    :disabled="isGridView"
                    :aria-label="$t('general.gridView')"
                  >
                    <i v-bind:class="{'nc-tile-blue mb-1 mr-1': !isGridView, 'nc-tile-grey mb-1 mr-1': isGridView}" />
                  </b-button>
                  <b-button
                    class="btn-nc"
                    tabindex="0"
                    v-on:click="switchView()"
                    v-on:keyup.enter="switchView()"
                    :disabled="!isGridView"
                    :aria-label="$t('general.listView')"
                  >
                    <i v-bind:class="{'nc-list-blue mb-1 mr-1': isGridView, 'nc-list-grey mb-1 mr-1': !isGridView}" />
                  </b-button>
                </b-button-group>
              </b-col>
            </b-row>
            <!-- string number of search results shown -->
            <span
              class="d-inline-block text-dark"
              style="fontSize: .8rem"
            >{{ $t("general.displaying") + ' ' + this.totalEvaluations + ' ' + $t("dpia.dpiaEvaluations") }}</span>
          </div>
          <h2
            v-if="!evaluationsLoading && totalEvaluations === 0 && !evaluationsErrMsg"
            class="h2 text-muted text-center mt-2"
          >{{ $t("inputFeedbackMsg.searchNoMatchFound" )}}</h2>
          <b-alert
            v-if="!evaluationsLoading && evaluationsErrMsg"
            dismissible
            :show="this.evaluationsErrMsg !== undefined && this.evaluationsErrMsg !== null"
            variant="danger"
            @dismissed="this.evaluationsErrMsg = null"
          >{{ evaluationsErrMsg }}</b-alert>
          <div
            v-if="evaluationsLoading"
            class='w-100 position-fixed m-0'
            style="zIndex: 1; top: 35%; left: 55%"
          >
            <b-spinner
              v-if="evaluationsLoading"
              class="spinner"
              style='width: 5rem; height: 5rem;'
              variant="secondary"
            />
          </div>
          <div v-show="evaluationsNotCompleted.length != 0">
            <h1 class="dpia-h1">{{$t('dpia.evaluationsNotCompleted')}}</h1>
            <div class="d-flex flex-wrap w-100">
              <div
                v-for="evaluation in sortedEvaluationsNotCompleted"
                :key="evaluation.id"
                v-bind:class="{'card m-2 shadow-sm bg-white': isGridView, 'list m-2 shadow-sm bg-white': !isGridView}"
                style="min-height: 2rem!important"
                role="button"
                tabindex="0"
                @click="!evaluationsLoading ? editEvaluation(evaluation.id) : null"
                v-on:keyup.enter="!evaluationsLoading ? editEvaluation(evaluation.id) : null"
              >
                <h2 class="h2 text-wrap">{{ evaluation.name }}</h2>
              </div>
              <dpia-modal
                ref="DpiaModal"
                @update-dpia-evaluations="updateDpiaEvaluations"
              >
              </dpia-modal>
            </div>
          </div>
          <div v-show="evaluationsCompleted.length != 0">
            <h1 class="dpia-h1">{{$t('dpia.evaluationsCompleted')}}</h1>
            <div class="d-flex flex-wrap w-100">
              <div
                v-for="evaluation in sortedEvaluationsCompleted"
                :key="evaluation.id"
                v-bind:class="{'card m-2 shadow-sm bg-white': isGridView, 'list m-2 shadow-sm bg-white': !isGridView}"
                style="min-height: 2rem!important"
                role="button"
                tabindex="0"
                @click="!evaluationsLoading ? editEvaluation(evaluation.id) : null"
                v-on:keyup.enter="!evaluationsLoading ? editEvaluation(evaluation.id) : null"
              >
                <div v-if="isGridView">
                  <h2 class="h2 text-wrap">{{ evaluation.name }}</h2>
                  <div class="d-flex flex-row">
                    <div class="label">
                      {{$t('dpia.resultEvaluation')}}
                    </div>
                    <div
                      v-if="evaluation.conclusion===true"
                      class="info"
                    >
                      {{$t('dpia.dpiaNeeded')}}
                    </div>
                    <div
                      v-if="evaluation.conclusion===false"
                      class="info"
                    >
                      {{$t("dpia.dpiaNotNeeded")}}
                    </div>
                    <div
                      class="info"
                      v-if="evaluation.hasBeenOverridden"
                    >
                      {{$t("dpia.conclusionOverridden")}}
                    </div>
                  </div>
                </div>
                <div
                  v-if="!isGridView"
                  class="d-flex flex-row"
                >
                  <b-col
                    class="d-flex flex-row"
                    cols="7"
                  >
                    <h2 class="h2 text-wrap">{{ evaluation.name }}</h2>
                  </b-col>
                  <b-col
                    class="d-flex flex-row"
                    cols="5"
                  >
                    <div class=" vertical-line" />
                    <div class="label">
                      {{$t('dpia.resultEvaluation')}}
                    </div>
                    <div
                      v-if="evaluation.conclusion===true"
                      class="info"
                    >
                      {{$t('dpia.dpiaNeeded')}}
                    </div>
                    <div
                      v-if="evaluation.conclusion===false"
                      class="info"
                    >
                      {{$t("dpia.dpiaNotNeeded")}}
                    </div>
                    <div
                      class="info"
                      v-if="evaluation.hasBeenOverridden"
                    >
                      {{$t("dpia.conclusionOverridden")}}
                    </div>
                  </b-col>
                </div>
              </div>
              <dpia-modal
                ref="DpiaModal"
                @update-dpia-evaluations="updateDpiaEvaluations"
              >
              </dpia-modal>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import axios from 'axios'
import DpiaModal from '@/components/dpia/DpiaModal'
import Search from '@/components/Search'
import FilterComponent from '@/components/dpia/DpiaFilter'
import queryString from 'query-string'

export default {
  name: 'dpia',
  components: {
    DpiaModal,
    Search,
    FilterComponent
  },
  data: function () {
    return {
      evaluationsCompleted: [],
      evaluationsNotCompleted: [],
      searchText: '',
      filter: {},
      totalEvaluations: 0,
      isGridView: true,
      sortingOptions: [],
      selectedSort: null,
      evaluationsLoading: false,
      evaluationsErrMsg: null
    }
  },
  created: function () {
    this.getEvaluations()
    this.setSortingOptions()
    this.selectedSort = this.sortingOptions[0].value
  },
  computed: {
    sortedEvaluationsCompleted: {
      get: function () {
        return this.evaluationsCompleted.slice().sort(this.selectedSort)
      },
      set: function (selectedSort) {
        this.evaluationsCompleted.slice().sort(selectedSort)
      }
    },
    sortedEvaluationsNotCompleted: {
      get: function () {
        return this.evaluationsNotCompleted.slice().sort(this.selectedSort)
      },
      set: function (selectedSort) {
        this.evaluationsNotCompleted.slice().sort(selectedSort)
      }
    }
  },
  methods: {
    createDpiaEvaluation: function () {
      this.$refs.DpiaModal.toggle(null)
    },
    editEvaluation: function (evaluationId) {
      this.$refs.DpiaModal.toggle(evaluationId)
    },

    getEvaluations: async function () {
      this.evaluationsLoading = true
      axios
        .get('api/dpiaEvaluation/'
          , {
            params: {
              ...this.filter,
              textSearch: this.searchText
            },
            paramsSerializer: params => {
              return queryString.stringify(params, {
                arrayFormat: 'index',
                skipNull: true
              })
            }
          })
        .then(response => {
          if (response.status === 200 && response.data) {
            this.evaluationsNotCompleted = response.data.filter(evaluation => evaluation.conclusion === null)
            this.evaluationsCompleted = response.data.filter(evaluation => evaluation.conclusion === true || evaluation.conclusion === false)
          }
        })
        .catch(e => {
          console.error('CATCH: ', e)
          this.evaluationsErrMsg = this.$t('errMsg.getGenericErrorMessage')
        })
        .finally(() => {
          this.evaluationsLoading = false
          this.totalEvaluations = this.evaluationsCompleted.length + this.evaluationsNotCompleted.length
        })
    },
    async updateDpiaEvaluations () {
      await this.getEvaluations()
    },
    async updateFilter (filter) {
      this.filter = filter
      this.getEvaluations()
    },
    async updateSearchText (searchText) {
      this.searchText = searchText
      await this.getEvaluations()
    },
    switchView () {
      this.isGridView = !this.isGridView
    },
    setSortingOptions () {
      this.sortingOptions = [
        {
          value: (a, b) => a.name.localeCompare(b.name, 'no'),
          text: this.$t('sorting.byTitleAZ')
        },
        {
          value: (a, b) => new Date(b.createdDateTime) - new Date(a.createdDateTime),
          text: this.$t('sorting.byCreateDateNewest')
        },
        {
          value: (a, b) => new Date(a.createdDateTime) - new Date(b.createdDateTime),
          text: this.$t('sorting.byCreateDateOldest')
        },
        {
          value: (a, b) => new Date(b.modifiedDateTime) - new Date(a.modifiedDateTime),
          text: this.$t('sorting.byModifiedDateNewest')
        },
        {
          value: (a, b) => new Date(a.modifiedDateTime) - new Date(b.modifiedDateTime),
          text: this.$t('sorting.byModifiedDateOldest')
        }
      ]
    }
  },
  watch: {
    '$i18n.locale': {
      handler () {
        this.setSortingOptions()
      }
    }
  }
}
</script>
