<!-- eslint-disable vue/multi-word-component-names -->
<Doughnut
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :height="height"
    :width="width"
/>

<script>
import { Doughnut } from 'vue-chartjs/legacy'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  name: 'Donut',
  components: { Doughnut },
  mixins: [Doughnut],
  props: {
    chartData: {
      type: Object,
      required: true
    },
    chartOptions: {
      type: Object,
      default: () => {}
    },
    chartId: {
      type: String,
      default: 'Chart'
    },
    datasetIdKey: {
      type: String,
      default: 'chart'
    },
    width: {
      type: Number,
      default: 220
    },
    height: {
      type: Number,
      default: 220
    }
  }
}
</script>

<style>
</style>
