<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <DashboardDataWrapper :inputFilter="{ assignedToId: [this.$route.meta.user.id] }">
      <template v-slot:dashboardTop="{ statistics, activities, fetchActivitiesCallback }">
        <div class="container-fluid">
          <div class="d-flex justify-content-center mt-3">
            <div
              v-if="activities.length === 0"
              class="shadow-sm bg-white statisticsCard ml-1 mr-1 mb-1 mt-1"
            >
              <p class="h2 text-muted text-center">{{$t("dashboard.noActivities.goTo")}}
                <router-link :to="{
                  name: 'protocol'
                  }">{{$t("dashboard.noActivities.protocol")}}</router-link> {{$t("dashboard.noActivities.addActivities")}}
              </p>
            </div>
            <StatisticsVisualizer
              v-if="
              activities.length
              !==0"
              class="pb-5"
              :statistics="statistics"
              :filter="{selectedAssignedTo: [$route.meta.user], selectedAreas: []}"
              :title="$t('filter.yourStatus')"
            />
            <DashboardActivity
              @update-processing-activities="fetchActivitiesCallback()"
              :isAdminDashboard="false"
              :activities="activities"
              :sortActivityOn="'lastModified'"
              :cardTitle="$t('dashboard.lastModified')"
            />
            <DashboardActivity
              @update-processing-activities="fetchActivitiesCallback()"
              :isAdminDashboard="false"
              :activities="activities"
              :sortActivityOn="'nextDeadline'"
              :cardTitle="$t('dashboard.nextDeadline')"
            />
          </div>
        </div>
      </template>
      <template v-slot:board="{activities, fetchActivitiesCallback, isLoading, changeSort}">
        <Board
          :activities="activities"
          :isAdmin="false"
          v-bind:isLoading="isLoading"
          @update-processing-activities="fetchActivitiesCallback()"
          @change-sort="changeSort"
        ></Board>
      </template>
    </DashboardDataWrapper>
  </div>
</template>

<script>
import DashboardDataWrapper from '../components/dashboard/DashboardDataWrapper.vue'
import StatisticsVisualizer from '../components/dashboard/StatisticsVisualizer.vue'
import Board from '../components/dashboard/Board.vue'
import DashboardActivity from '../components/dashboard/DashboardActivity.vue'

export default {
  components: {
    DashboardDataWrapper,
    StatisticsVisualizer,
    Board,
    DashboardActivity
  },
  methods: {
    changeSort (newsort) {
      this.$emit('change-sort', newsort)
    }
  }
}
</script>
