<template>
    <div>
        <b-popover
            :placement="this.placement"
            :target="this.target"
            triggers="hover"
            :title="this.title">
            <div v-html="this.helptext">
            </div>
        </b-popover>
    </div>
</template>
<script>
export default {
  name: 'StaticCode-popup',
  data: function () {
    return {
    }
  },
  props: {
    title: {
      type: String,
      default: null
    },
    helptext: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: null
    },
    placement: {
      type: String,
      default: 'bottom'
    }
  }
}
</script>
