<template>
  <div class="shadow-sm bg-white statisticsCard m-1">
    <div class="title">
      <h2 class="h2 pr-2 mt-1 mb-1 text-center">{{title}}</h2>
    </div>

    <div v-if="this.isEmpty">
      <p
        style="line-height: 100px ; text-align: center"
        class="empty-value"
      >{{$t("dashboard.noActivitiesFound")}}</p>
    </div>

    <div style="width: 100%">
      <router-link
        style="text-decoration: none; color:black"
        :to="{ name: 'protocol', params:{ isApprovedStatusSelected: true, ...filter } }"
      >
        <div
          class="gridContainer"
          v-if="!this.isEmpty"
        >
          <span class="routerButton">
            <p class="textInButton">{{ $t("statisticsVisualizer.statusApproved")}}</p>
          </span>
          <div class="statisticsBar">
            <div class="whiteBar">
              <div
                class="completionStatistic completed-statisticsBar"
                v-bind:style="{width: statistics.completed/calculateTotalActivities*100 + '%'}"
                :class="{'fullStatisticsBar' : (statistics.completed/calculateTotalActivities*100 === 100) }"
              ></div>
            </div>
          </div>
          <p class="statisticsNumber">{{statistics.completed}}</p>

        </div>
      </router-link>

      <router-link
        style="text-decoration: none; color:black"
        :to="{ name: 'protocol', params:{ isWorkInProgressSelected: true, isReadyForReviewSelected: true, isNeedsFollowUpSelected: true, ...filter} }"
      >
        <div
          class="gridContainer"
          v-if="!this.isEmpty"
        >
          <span class="routerButton">
            <p class="textInButton">{{ $t("statisticsVisualizer.statusUnderProgress")}}</p>
          </span>
          <div class="statisticsBar">
            <div class="whiteBar">
              <div
                class="completionStatistic pending-statisticsBar"
                v-bind:style="{width: statistics.pending/calculateTotalActivities*100 + '%'}"
                :class="{'fullStatisticsBar' : (statistics.pending/calculateTotalActivities*100 === 100) }"
              ></div>
            </div>
          </div>
          <p class="statisticsNumber">{{statistics.pending}}</p>

        </div>
      </router-link>

      <router-link
        style="text-decoration: none; color:black"
        :to="{ name: 'protocol', params:{ isNotApprovedStatusSelected: true, ...filter } }"
      >
        <div
          class="gridContainer"
          v-if="!this.isEmpty"
        >
          <span class="routerButton">
            <p class="textInButton">{{ $t("statisticsVisualizer.statusNotApproved")}}</p>
          </span>
          <div class="statisticsBar">
            <div class="whiteBar">
              <div
                class="completionStatistic notApproved-statisticsBar"
                v-bind:style="{width: statistics.notApproved/calculateTotalActivities*100 + '%'}"
                :class="{'fullStatisticsBar' : (statistics.notApproved/calculateTotalActivities*100 === 100) }"
              ></div>
            </div>
          </div>
          <p class="statisticsNumber">{{statistics.notApproved}}</p>

        </div>
      </router-link>

      <router-link
        style="text-decoration: none; color:black"
        :to="{ name: 'protocol', params:{ isUnselectedStatusSelected: true, ...filter} }"
      >
        <div
          class="gridContainer"
          v-if="!this.isEmpty"
        >
          <span class="routerButton">
            <p class="textInButton">{{ $t("statisticsVisualizer.statusNotSet")}}</p>
          </span>
          <div class="statisticsBar">
            <div class="whiteBar">
              <div
                class="completionStatistic noStatus-statisticsBar"
                v-bind:style="{width: statistics.noStatus/calculateTotalActivities*100 + '%'}"
                :class="{'fullStatisticsBar' : (statistics.noStatus/calculateTotalActivities*100 === 100) }"
              ></div>
            </div>
          </div>
          <p class="statisticsNumber">{{statistics.noStatus}}</p>

        </div>
      </router-link>

    </div>

  </div>
</template>

<script>
export default {
  name: 'statusStatisticsVizualiser',

  props: {
    title: {
      type: String,
      default: function () {
        return this.$t('filter.yourStatus')
      }
    },
    statistics: {
      type: Object,
      default () {
        return {
          completed: 0,
          pending: 0,
          notApproved: 0,
          noStatus: 0
        }
      }
    },
    filter: {
      type: Object
    }
  },
  computed: {
    calculateTotalActivities: function () {
      return Object.values(this.statistics).reduce((a, b) => a + b, 0)
    },
    isEmpty: function () {
      return !(
        this.statistics.completed ||
        this.statistics.pending ||
        this.statistics.notApproved ||
        this.statistics.noStatus
      )
    }
  }
}
</script>
