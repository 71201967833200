<template>
  <div class="shadow-sm bg-white dashboardFilterBox">

    <!-- Ny BA -->
    <div class="d-flex justify-content-center">
      <b-button
        class="btn-nc btn-green"
        tabindex="0"
        v-on:click="createActivity()"
        v-on:keyup.enter="createActivity()">
        <i class="nc-add-white mb-1 mr-1" />
          {{ $t("general.createNew" ) + ' ' + $t("general.processingActivity" ).toLowerCase()}}
      </b-button>
    </div>

    <!-- Filtrering på område -->
    <div
      aria-label="Filter">
      <b-list-group-item class="d-flex">
        <fieldset class="fieldset-admin-dashboard">
          <multiselect
            v-if="!this.areasloading && !this.areasErrMsg && filterOptions.areas"
              v-model="filterOptions.selectedAreas"
              id="area-multiselect"
              class="area-multiselect"
              :options="filterOptions.areas"
              :multiple="true"
              label="name"
              track-by="id"
              :clear-on-select="false"
              :close-on-select="false"
              :max-height="300"
              :hide-selected="false"
              :placeholder="$t('filter.area')"
              :show-labels="false"
          >
          </multiselect>
          <b-spinner
            variant="secondary"
            class="spinner"
            v-else-if="this.areasloading && !this.areasErrMsg"
          />
          <p
            v-else-if="!this.areasloading && !filterOptions.areas && !this.areasErrMsg"
            class="text-muted text-center"
          >{{ $t("general.noDataAvailable" )}}</p>
          <b-alert
            variant="danger"
            dismissible
            :show="this.areasErrMsg !== undefined && this.areasErrMsg !== null && !this.areasloading"
            @dismissed="this.areasErrMsg = null"
          >{{ areasErrMsg }}</b-alert>
        </fieldset>
      </b-list-group-item>
    </div>

    <!-- Filtrering på tilordnet -->
    <div
      aria-label="Filter">
      <b-list-group-item class="d-flex">

        <fieldset class="fieldset-admin-dashboard">
          <multiselect
            v-if="!this.assignedToLoading && !this.assignedToErrMsg && filterOptions.assignedTo"
            v-model="filterOptions.selectedAssignedTo"
            id="assignedTo-multiselect"
            class="color-multiselect"
            :options="filterOptions.assignedTo"
            :multiple="true"
            label="name"
            track-by="id"
            :clear-on-select="false"
            :close-on-select="false"
            :max-height="300"
            :hide-selected="false"
            :placeholder="$t('filter.assignedTo')"
            :show-labels="false"
          >
          </multiselect>
          <b-spinner
            variant="secondary"
            class="spinner"
            v-else-if="this.assignedToLoading && !this.assignedToErrMsg"
          />
          <p
            v-else-if="!this.assignedToLoading && !filterOptions.assignedTo && !this.assignedToErrMsg"
            class="text-muted text-center"
          >{{ $t("general.noDataAvailable" )}}</p>
          <b-alert
            variant="danger"
            dismissible
            :show="this.assignedToErrMsg !== undefined && this.assignedToErrMsg !== null && !this.assignedToLoading"
            @dismissed="this.assignedToErrMsg = null"
          >{{ assignedToErrMsg }}</b-alert>
        </fieldset>
      </b-list-group-item>
    </div>

    <!-- Sortering -->
    <div class="d-flex justify-content-center">
      <div class="w-100 mt-3">
        <b-form-select
          v-model="selectedSort"
          :aria-label="$t('navigation.sortingoptions')"
          :options="sortingOptions"
          @change="$emit('change-sort', selectedSort)">
        </b-form-select>
      </div>
    </div>

    <!-- Modal for når man oppretter ny BA -->
    <activity-modal
      @update-processing-activities="emitResetRequest()"
      ref="activityModal"
      :statusOptions="this.statusOptions"
    ></activity-modal>

  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import ActivityModal from '../processingActivity/ActivityModal.vue'
import axios from 'axios'

export default {
  name: 'filter-component',
  components: {
    Multiselect,
    ActivityModal
  },
  props: {
    filter: {
      type: Object
    },
    callback: {
      type: Function
    }
  },
  computed: {
    statusOptions: function () {
      return [
        {
          key: 'ingenStatus',
          name: this.$t('activity.activityStatus.statusNotSet'),
          id: 0
        },
        {
          key: 'underArbeid',
          name: this.$t('activity.activityStatus.statusUnderProgress'),
          id: 3
        },
        {
          key: 'trengerOppfolging',
          name: this.$t('activity.activityStatus.statusNeedsFollowUp'),
          id: 5
        },
        {
          key: 'tilGodkjenning',
          name: this.$t('activity.activityStatus.statusReadyForReview'),
          id: 4
        },
        {
          key: 'ikkeGodkjent',
          name: this.$t('activity.activityStatus.statusNotApproved'),
          id: 2
        },
        {
          key: 'godkjent',
          name: this.$t('activity.activityStatus.statusApproved'),
          id: 1
        }
      ]
    }
  },
  data: function () {
    return {
      areasloading: false,
      areasErrMsg: null,
      assignedToLoading: false,
      assignedToErrMsg: null,
      myFilter: {},
      noAssignedUser: {
        id: 'noUserAssigned',
        name: this.$t('filter.noUserAssigned')
      },
      filterOptions: {
        areas: [],
        selectedAreas: [],
        assignedTo: [],
        selectedAssignedTo: []
      },
      sortingOptions: []
    }
  },
  created () {
    this.myFilter = this.filter
    this.getAreas()
    this.getUsers()
    this.filterOptions.selectedAreas = this.$route.meta.user.areas
    this.setSortingOptions()
    this.selectedSort = this.sortingOptions[0].value
  },
  methods: {
    onReset: function () {
      this.myFilter = {}
      this.filterOptions = {
        selectedAssignedTo: [],
        selectedAreas: [],
        assignedTo: this.filterOptions.assignedTo,
        areas: this.filterOptions.areas
      }
    },
    async getAreas () {
      this.areasloading = true
      axios
        .get('/api/areas')
        .then((res) => {
          if (res.status === 200) {
            this.filterOptions.areas = res.data
          }
        })
        .catch(() => {
          this.areasErrMsg = this.$t('errMsg.getGenericErrorMessage')
        })
        .finally(() => {
          this.areasloading = false
        })
    },
    async getUsers () {
      this.assignedToLoading = true
      axios
        .get('/api/Users')
        .then((res) => {
          if (res.status === 200) {
            this.filterOptions.assignedTo = res.data
            this.filterOptions.assignedTo.push(this.noAssignedUser)
          }
          this.assignedToLoading = false
        })
        .catch(() => {
          this.assignedToErrMsg = this.$t('errMsg.getGenericErrorMessage')
        })
        .finally(() => {
          this.assignedToLoading = false
        })
    },
    // Opprette ny BA
    emitResetRequest: function () {
      this.$emit('update-processing-activities')
      this.callback()
    },
    createActivity: function () {
      this.$refs.activityModal.toggle(null)
    },
    editActivity: function (activityId) {
      this.$refs.activityModal.toggle(activityId)
    },
    getActivityColor (statusCode) {
      if (statusCode === 0 || !statusCode) {
        return 'mr-2 mt-1 dot-grey'
      }
      if (statusCode === 1) {
        return 'mr-2 mt-1 dot-green'
      }
      if (statusCode === 2) {
        return 'mr-2 mt-1 dot-red'
      }
      if ((statusCode === 3) | 4) {
        return 'mr-2 mt-1 dot-yellow'
      }
      return 'mr-2 mt-1 dot-grey'
    },
    getStatusText (statusId) {
      return this.getStatusObject(statusId).name
    },
    getStatusObject (statusId) {
      if (!statusId) return this.statusOptions.find((obj) => obj.id === 0)
      if (statusId && (!statusId.id || !statusId.name)) {
        return this.statusOptions.find((obj) => obj.id === statusId)
      }
      return statusId
    },
    setSortingOptions () {
      this.sortingOptions = [
        {
          value: (a, b) =>
            new Date(a.assignedUser.deadline) -
            new Date(b.assignedUser.deadline),
          text: this.$t('sorting.byDeadline')
        },
        {
          value: (a, b) =>
            a.aboutProcessingActivity.title.localeCompare(
              b.aboutProcessingActivity.title, 'no'),
          text: this.$t('sorting.byTitleAZ')
        },
        {
          value: (a, b) =>
            new Date(b.createdDateTime) -
            new Date(a.createdDateTime),
          text: this.$t('sorting.byCreateDateNewest')
        },
        {
          value: (a, b) =>
            new Date(a.createdDateTime) -
            new Date(b.createdDateTime),
          text: this.$t('sorting.byCreateDateOldest')
        },
        {
          value: (a, b) =>
            new Date(b.modifiedDateTime) -
            new Date(a.modifiedDateTime),
          text: this.$t('sorting.byModifiedDateNewest')
        },
        {
          value: (a, b) =>
            new Date(a.modifiedDateTime) -
            new Date(b.modifiedDateTime),
          text: this.$t('sorting.byModifiedDateOldest')
        },
        {
          value: (a, b) =>
            b.routineAndBestPractices.highRisk -
            a.routineAndBestPractices.highRisk,
          text: this.$t('sorting.byRiskHighLow')
        },
        {
          value: (a, b) =>
            a.routineAndBestPractices.highRisk -
            b.routineAndBestPractices.highRisk,
          text: this.$t('sorting.byRiskLowHigh')
        }
      ]
    }
  },
  watch: {
    filterOptions: {
      handler () {
        this.myFilter = {
          areaIds: this.filterOptions.selectedAreas.length
            ? this.filterOptions.selectedAreas.map((area) => area.id)
            : null,
          assignedToId: this.filterOptions.selectedAssignedTo.length
            ? this.filterOptions.selectedAssignedTo.map(
              (assignedTo) => assignedTo.id
            )
            : null
        }
        this.$emit('update-filter-options', this.filterOptions)
        this.callback(this.myFilter)
      },
      deep: true
    },
    '$i18n.locale': {
      handler () {
        this.setSortingOptions()
      }
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "./../../styles/_variables.scss";
.nav.sidenav > .container > .card.filter-card {
  padding-left: 10px;
  padding-right: 10px;
}
.filter-row-multiselect {
  padding: 10px;
  margin: 0px;
  width: 500px;
}
.filter-subtitle {
  font-size: 80%;
}
.filter-card {
  margin-bottom: 1rem;
}
.area-multiselect .multiselect__tag,
.area-multiselect .multiselect__option--highlight,
.area-multiselect .multiselect__option--highlight::after {
  color: $black;
  background-color: $nc-blue-4;
  border-radius: 0;
}

.category-multiselect .multiselect__tag,
.category-multiselect .multiselect__option--highlight,
.category-multiselect .multiselect__option--highlight::after {
  color: $black;
  background-color: $nc-green-4;
  border-radius: 0;
}

.col-12.col {
  padding: 0px 5px;
}
</style>
