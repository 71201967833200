<template>
    <b-col md="4">
      <div v-show="data.modifiedDateTime" class="text-dark text-footer">
        <span>
          {{ $t( 'activity.lastModifiedByOn', {name: userModifiedData})}}
        </span>
        <span class="nowrap-text"> {{ $d(data.modifiedDateTime) }} </span>
      </div>
      <div v-show="data.createdDateTime" class="text-dark text-footer">
        <span>
          {{ $t( 'activity.createdByOn', {name: userCreatedData})}}
        </span>
        <span class="nowrap-text"> {{ $d(data.createdDateTime) }} </span>
      </div>
    </b-col>
  </template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },
    userCreatedData: {
      type: String,
      required: true
    },
    userModifiedData: {
      type: String,
      required: true
    }
  }
}
</script>
<style scoped lang="scss">
@import "../../styles/_variables.scss";
.nowrap-text {
    white-space: nowrap;
}
</style>
