<template>
  <b-navbar
    class="nav-bar"
    toggleable="md"
    type="dark"
    variant="info"
  >
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
    <b-collapse
      id="nav-collapse"
      is-nav
    >
      <b-navbar-nav>
        <b-nav-item
          class="nav-brand nav-bar-btn nav-bar-btn-hover"
          exact-active-class=""
          @click="setSettingsNavText('')"
          :to="{ name: 'protocol'}"
        >{{ $t("navigation.vismaProtocol") }}</b-nav-item>
        <b-nav-item
          class="nav-bar-btn nav-bar-btn-hover"
          @click="setSettingsNavText('')"
          v-if="isAuthenticated && user"
          :to="{ name: 'protocol'}"
          left
        >{{ $t("navigation.protocol") }}</b-nav-item>
        <b-nav-item
          class="nav-bar-btn nav-bar-btn-hover"
          @click="setSettingsNavText('')"
          v-if="isAuthenticated && user"
          :to="{ name: 'dashboard'}"
          left
        >{{ $t("navigation.dashboard") }}</b-nav-item>
        <b-nav-item
          class="nav-bar-btn nav-bar-btn-hover"
          @click="setSettingsNavText('')"
          v-if="isAuthenticated && user"
          :to="{ name: 'staticcode'}"
          left
        >{{ $t("navigation.staticCodes") }}</b-nav-item>
        <b-nav-item
          class="nav-bar-btn nav-bar-btn-hover"
          @click="setSettingsNavText('')"
          v-if="isAuthenticated && user && user.isAdmin"
          :to="{ name: 'areastatistics'}"
          left
        >{{ $t("navigation.areaStatistics") }}</b-nav-item>
        <b-nav-item
          class="nav-bar-btn nav-bar-btn-hover"
          @click="setSettingsNavText('')"
          v-if="isAuthenticated && user"
          :to="{ name: 'dpia'}"
          left
        >{{ $t("navigation.dpia") }}</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown
          v-if="isAuthenticated && user && user.isAdmin"
          class="nav-bar-btn nav-bar-btn-hover"
          :class="isOnSettings ? 'dropdown-selected' : '' "
          left
        ><template v-slot:button-content><b-icon icon="gear-fill" /> {{ $t(settingsNavText)}}</template>
          <div class="dropdown-item-usercontainer ">
            <b-dropdown-item
              exact-active-class=""
              @click="setSettingsNavText('navigation.admin')"
              :to="{ name: 'aboutprotocol'}"
              left
            >{{ $t("navigation.admin") }}</b-dropdown-item>
            <b-dropdown-divider v-if="isAuthenticated" />
            <b-dropdown-item
              exact-active-class=""
              @click="setSettingsNavText('navigation.settings')"
              :to="{ name: 'settings'}"
              left
            >{{ $t("navigation.settings") }}</b-dropdown-item>
          </div>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          class="nav-bar-btn nav-bar-btn-hover"
          :text="getLanguageText($i18n.locale)"
        >
          <b-dropdown-item
            v-for="(lang, i) in $i18n.availableLocales"
            :key="`Lang${i}`"
            :value="lang"
            @click.prevent="setLocale(lang)"
          >
            <i :class="'nc-flag-' + lang" />
            <!--<img :src="`/flags/${lang}.svg`" class="w-25">-->
            <span
              v-if="getLanguageText(lang) === getLanguageText($i18n.locale)"
              class="ml-1 font-weight-bold"
            >{{ getLanguageText(lang) }}</span>
            <span
              v-else
              class="ml-1"
            >{{ getLanguageText(lang) }}</span>
          </b-dropdown-item>
        </b-nav-item-dropdown>
        <b-nav-item-dropdown
          v-if="isAuthenticated && user"
          class="nav-bar-btn nav-bar-btn-hover"
          right
        >
          <template v-slot:button-content><i class="nc-user mr-1 ml-1" />{{ user.name }}</template>
          <b-dropdown-item v-if="isAuthenticated && user.name">
            <div class="dropdown-item-usercontainer">
              <p
                v-if="user.name"
                class="dropdown-item-usercontainer-text"
              >{{ user.name }}</p>
              <p
                v-if="user.email"
                class="dropdown-item-usercontainer-text"
              >{{ user.email }}</p>
            </div>
          </b-dropdown-item>
          <b-dropdown-divider v-if="isAuthenticated" />
          <b-dropdown-item
            v-if="isAuthenticated"
            @click.prevent="logout()"
          >{{ $t("navigation.signOut") }}</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>
<script>
export default {
  name: 'navigation-bar',
  data () {
    return {
      user: {
        name: '-',
        email: '-',
        isAdmin: false
      },
      isAuthenticated: false,
      settingsNavText: ''
    }
  },
  created () {
    this.isAuthenticated = this.$authService.isAuthenticatedWithMsal()
    this.user = this.$route.meta.user
  },
  methods: {
    setLocale (locale) {
      this.$root.$i18n.locale = locale
      this.$store.dispatch('language/setLanguage', locale)
    },
    getLanguageText (locale) {
      switch (locale) {
        case 'no':
          return 'Norsk'
        case 'en':
        default:
          return 'English'
      }
    },
    setSettingsNavText (text) {
      this.settingsNavText = text
    },
    logout () {
      this.$authService.logout()
    }
  },
  computed: {
    isOnSettings: function () {
      const settingPaths = ['settings', 'aboutprotocol']
      if (settingPaths.includes(this.$route.name)) {
        return true
      }
      return false
    }
  },
  watch: {
    $route () {
      this.user = this.$route.meta.user
      this.isAuthenticated = this.$authService.isAuthenticatedWithMsal()
    }
  }
}
</script>
<style scoped lang="scss">
@import "./../styles/_variables.scss";
.nav-bar {
  padding: 0 0 0 0 !important;
}
.nav-brand {
  font-size: 1.2rem !important;
  border-left: none;
}
.nav-bar-btn {
  margin: 0 !important;
  padding: 0.1rem !important;
  transition: 0.3s;
  border-left: 1px solid rgba($white, 0.1);
}
.nav-bar-btn-hover:hover {
  background-color: $nav-blue-2;
}
.dropdown-item-usercontainer {
  align-items: center;
  justify-content: center;
}
.dropdown-item-usercontainer-text {
  margin: 0 !important;
  font-size: 0.8rem;
  color: $nc-shade-dark;
}
</style>
