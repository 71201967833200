<template>
  <div>
    <single-select-input
      :id="id"
      :editMode="editMode"
      :emptyDescription="$t('general.emptydata')"
      :options="lawfulnessOfProcessingOptions"
      :placeholder="$t('activity.lawfulnessOfProcessing.lawfulnessOfProcessing')"
      :selectedOption="selectedLawfulnessOfProcessingType"
      @update-select="updateLawfulnessOfProcessingType" />
    <div v-if="selectedLawfulnessOfProcessingType">
      <div v-if="selectedLawfulnessOfProcessingType.id === 1">
        <heading-and-label :editMode="editMode" labelFor="about-legal-obligation" :level="3">
          {{$t('activity.lawfulnessOfProcessing.legalObligation')}}
        </heading-and-label>
        <multi-select-input
          id="about-legal-obligation"
          :editMode="editMode"
          :options="legalObligations"
          :emptyDescription="$t('general.emptydata')"
          :placeholder="$t('activity.lawfulnessOfProcessing.legalObligation')"
          :selectedOptions="selectedLegalObligations"
          @update-select="updateLegalObligations"></multi-select-input>

          <heading-and-label :editMode="editMode" labelFor="legal-obligation-description" :level="3">
            {{$t('activity.lawfulnessOfProcessing.description')}}
          </heading-and-label>
          <text-input
            id="legal-obligation-description"
            class="mb-2"
            :editMode="editMode"
            :emptyDescription="$t('general.emptydata')"
            :placeholder="$t('activity.lawfulnessOfProcessing.description')"
            :value="myLawfulnessOfProcessing.description"
            @update-text="updateDescription" />
      </div>
      <div v-if="[2, 3, 4, 5].includes(selectedLawfulnessOfProcessingType.id)" class="activity-box-inner">
        <b-form-group>
          <heading-and-label :editMode="editMode" labelFor="about-legal-obligation-description" :level="4">
            5.1 {{ $t('activity.lawfulnessOfProcessing.description') }}
          </heading-and-label>
          <text-input
            id="about-legal-obligation-description"
            class="mb-2"
            :editMode="editMode"
            :emptyDescription="$t('general.emptydata')"
            :placeholder="$t('activity.lawfulnessOfProcessing.description')"
            :value="myLawfulnessOfProcessing.description"
            @update-text="updateDescription" />

          <div v-if="[2, 3, 4].includes(selectedLawfulnessOfProcessingType.id)">
            <heading-and-label :editMode="editMode" labelFor="about-legal-obligation-file" :level="4">
              {{ $t('file.file')}}
            </heading-and-label>
            <file-input
              id="about-legal-obligation-file"
              :activityId="activityId"
              :attachment="myLawfulnessOfProcessing.attachmentInfo"
              :editMode="editMode"
              :emptyDescription="$t('general.emptydata')"
              @remove-attachment="removeAttachment"
              @update-attachment="updateAttachmentInfo" />
          </div>
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
import FileInput from '../common/FileInput'
import HeadingAndLabel from '../common/HeadingAndLabel'
import MultiSelectInput from '../common/MultiSelectInput'
import SingleSelectInput from '../common/SingleSelectInput'
import TextInput from '../common/TextInput'

export default {
  name: 'lawfulness-of-processing',
  components: {
    HeadingAndLabel,
    FileInput,
    MultiSelectInput,
    SingleSelectInput,
    TextInput
  },
  props: {
    id: {
      type: String,
      required: true
    },
    lawfulnessOfProcessing: {
      type: Object,
      required: true
    },
    legalObligations: {
      type: Array,
      default: () => []
    },
    activityId: {
      type: String
    },
    editMode: {
      type: Boolean
    }
  },
  data: function () {
    return {
      myLawfulnessOfProcessing: {},
      selectedLawfulnessOfProcessingType: null,
      selectedLegalObligations: [],
      lawfulnessOfProcessingOptions: [
        {
          id: 1,
          name: this.$t('activity.lawfulnessOfProcessing.legalObligation')
        },
        {
          id: 2,
          name: this.$t('activity.lawfulnessOfProcessing.agreement')
        },
        {
          id: 3,
          name: this.$t('activity.lawfulnessOfProcessing.consent')
        },
        {
          id: 4,
          name: this.$t('activity.lawfulnessOfProcessing.dataProcessingAgreement')
        },
        {
          id: 5,
          name: this.$t('activity.lawfulnessOfProcessing.other')
        }
      ]
    }
  },
  created: function () {
    this.setUp()
  },
  methods: {
    setUp () {
      this.myLawfulnessOfProcessing = this.lawfulnessOfProcessing
      if (this.lawfulnessOfProcessing.type) {
        const type = this.lawfulnessOfProcessingOptions.find(x => x.id === this.lawfulnessOfProcessing.type)
        this.updateLawfulnessOfProcessingType(type)
        if (type.id === 1) {
          this.updateLegalObligations(this.lawfulnessOfProcessing.legalObligations)
        }
      }
    },
    updateLawfulnessOfProcessingType (type) {
      if (type) {
        this.myLawfulnessOfProcessing.type = type.id
        this.selectedLawfulnessOfProcessingType = type
        if (type.id !== 1) {
          this.myLawfulnessOfProcessing.legalObligations = null
          this.myLawfulnessOfProcessing.legalObligationIds = null
        }
      } else {
        this.myLawfulnessOfProcessing.type = null
        this.selectedLawfulnessOfProcessingType = null
      }
    },
    updateLegalObligations (legalObligations) {
      this.myLawfulnessOfProcessing.legalObligationIds = legalObligations.map(obligation => obligation.id)
      this.selectedLegalObligations = legalObligations
    },
    updateDescription (description) {
      this.myLawfulnessOfProcessing.description = description
    },
    updateAttachmentInfo (attachmentInfo) {
      this.myLawfulnessOfProcessing.attachment = attachmentInfo
    },
    removeAttachment (attachmentId) {
      if (this.myLawfulnessOfProcessing.attachmentInfo.id === attachmentId) {
        this.myLawfulnessOfProcessing.attachmentInfo = null
      }
    }
  },
  watch: {
    myLawfulnessOfProcessing: {
      handler () {
        this.$emit('update-lawfulness-of-processing', this.myLawfulnessOfProcessing)
      },
      deep: true
    },
    lawfulnessOfProcessing: {
      handler () {
        this.setUp()
      }
    }
  }
}
</script>
