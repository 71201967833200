<template>

    <div
    ref="activityCard"
    :class="{ 'expanded-card': showMore, 'default-card-size': !showMore }">
    <div>
    <div
        class="flex-title"
        style="justify-content: space-between"
    >
        <h2 class="h2 wrap-text">{{ activity.aboutProcessingActivity.title }}</h2>
        <div v-if="activity.routineAndBestPractices.highRisk">
        <i class="nc-flag mr-2" />
        </div>
        <div v-on:click.stop @click="toggleShowMore()" v-if="isOverflowing" class="overflowContainer">
            <span class="nc-expand read-more"
                :class="{ 'expand': showMore }"
                :style="`--overflow: ${'visible'}`">
            </span>
            <div style="padding-top: 4px; font-size: 0.9rem">
               {{ showMore ? 'Vis mindre' : "Vis mer" }}
            </div>
        </div>
    </div>
    <div
        class="d-flex flex-row mt-2 mb-2"
        style="justify-content: space-between"
        v-if="tenantActivityCardSettings.assignedUserStatusAndDeadlineRow"
    >
        <b-avatar class="mr-2 badge-blue" style="width: 20px; height: 20px;"></b-avatar>
        <span class="mr-auto status-text">{{this.$parent.getAssignedUser(activity.assignedUser)}}</span>
        <b-icon :class="this.$parent.getActivityColor( activity.assignedUser && activity.assignedUser.statusCode)" name="icontDot"></b-icon>
        <span class="mr-auto status-text">{{activity.assignedUser && this.$parent.getStatusText(activity.assignedUser.statusCode)}}</span>
        <i class="nc-calender mr-2" style="width: 15px; height: 15px;" />
        <span class="mr-auto status-text">{{activity.assignedUser && this.$parent.getDeadline(activity.assignedUser.deadline)}}</span>
    </div>
    <div v-if="tenantActivityCardSettings.purpose && activity.aboutProcessingActivity.purpose">
    <hr class="hr-activity" />
    <div class="infotext">{{  $t('activity.aboutRecordAndProcessing.purpose') }}:
    <span class="grey">{{ activity.aboutProcessingActivity.purpose  }}</span>
    </div>
    </div>
    <div v-if="tenantActivityCardSettings.responsibleManager && activity.aboutProcessingActivity.responsibleManager">
    <hr class="hr-activity" />
    <div class="infotext">{{  $t('activity.aboutRecordAndProcessing.shortResponsibleManager') }}:
        <span class="grey">{{ activity.aboutProcessingActivity.responsibleManager }}</span>
    </div>
    </div>
    <div v-if="tenantActivityCardSettings.routinesAndApprovalComment && activity.routineAndBestPractices.routinesAndApprovalComment">
    <hr class="hr-activity" />
    <div class="infotext">{{  $t('activity.routinesAndApprovalTab.comment') }}:
    <span class="grey">{{ activity.routineAndBestPractices.routinesAndApprovalComment  }}</span>
    </div>
    </div>
    <b-list-group flush v-if="tenantActivityCardSettings.areas && activity.aboutProcessingActivity.areas">
        <hr class="hr-activity" />
    <b-list-group-item class="label-black">{{  $t('activity.aboutRecordAndProcessing.areas') }}:</b-list-group-item>
        <b-list-group-item class="text-truncate-container-3">
        <ul>
            <li
            v-for="area in getAreas()"
            :key="area.id"
            class="badge badge-area"
            style="max-width: 99%; word-wrap: break-word;"
            >
            {{ area.name }}
            </li>
        </ul>
        </b-list-group-item>
    </b-list-group>
    <div v-if="tenantActivityCardSettings.categoriesOfDataSubjects && activity.aboutProcessingActivity.categoriesOfDataSubjects">
        <hr class="hr-activity" />
    <b-list-group-item class="label-black ">{{  $t('activity.aboutRecordAndProcessing.categoriesOfDataSubjects') }}:</b-list-group-item>
    <b-list-group flush>
        <b-list-group-item class="">
        <ul>
            <li
            v-for="category in getCategoriesOfDataSubjects()"
            :key="category.id"
            class="badge badge-category"
            style="maxWidth: 99%; word-wrap: break-word;"
            >
            {{ category.name }}
            </li>
        </ul>
        </b-list-group-item>
    </b-list-group>
    </div>
    <div v-if="tenantActivityCardSettings.sharedResponsibility && activity.sharedResponsibilityBusinessOrganisations">
        <hr class="hr-activity" />
    <b-list-group-item class="label-black">{{ $t("activity.sharedResponsibility") }}:</b-list-group-item>
    <b-list-group-item class="value">
        <ul>
        <li
            v-for="(organisation, index) in getSharedResponsibilityBusinessOrganisations()"
            :key="index"
            class="badge badge-primary"
            style="maxWidth: 99%;"
        >
            {{ organisation }}
        </li>
        </ul>
    </b-list-group-item>
    </div>
    <div v-if="tenantActivityCardSettings.disclosuresOfData && activity.disclosureOfDataBusinessOrganisations">
        <hr class="hr-activity" />
    <b-list-group-item class="label-black ">{{ $t("activity.dataIsDisclosedTo") }}:</b-list-group-item>
    <b-list-group-item class="value">
        <ul>
        <li
            v-for="(organisation, index) in getDisclosureOfDataBusinessOrganisations()"
            :key="index"
            class="badge badge-primary"
        >
            {{ organisation }}
        </li>
        </ul>
        </b-list-group-item>
    </div>
    </div>
    </div>
</template>

<script>

export default {
  name: 'activityCard',
  mounted: function () { this.checkIfIsOverflowing() },
  data: function () {
    return {
      showMore: false,
      isOverflowing: false
    }
  },
  props: {
    activity: {},
    tenantActivityCardSettings: {}
  },
  methods: {
    toggleShowMore () {
      this.showMore = !this.showMore
    },
    checkIfIsOverflowing () {
      const element = this.$refs.activityCard
      this.isOverflowing = (element.offsetHeight < element.scrollHeight)
    },
    getAreas () {
      return [...this.activity.aboutProcessingActivity.areas].sort(this.sortAlphabetically)
    },
    getCategoriesOfDataSubjects () {
      return [...this.activity.aboutProcessingActivity.categoriesOfDataSubjects].sort(this.sortAlphabetically)
    },
    getSharedResponsibilityBusinessOrganisations () {
      return [...this.activity.sharedResponsibilityBusinessOrganisations].sort(this.sortAlphabetically)
    },
    getDisclosureOfDataBusinessOrganisations () {
      return [...this.activity.disclosureOfDataBusinessOrganisations].sort(this.sortAlphabetically)
    },
    sortAlphabetically (a, b) {
      if (Object.prototype.hasOwnProperty.call(a, 'name') &&
        Object.prototype.hasOwnProperty.call(b, 'name')
      ) {
        return a.name.localeCompare(b.name, 'no')
      } else if (typeof a === 'string' && typeof b === 'string') {
        return a.localeCompare(b, 'no')
      } else {
        return 0
      }
    }
  }
}
</script>
