<template>
  <div class="d-flex justify-content-center">
    <div class="d-flex flex-column shadow-sm bg-white pl-5 pr-5 pt-4 pb-4 m-5" role="main">
        <div class="d-inling mt-2 mb-2">
            <h1 class="">{{ $t("errMsg.popup.title") }}</h1>
        </div>
        <div class="d-flex justify-content-between mt-2 mb-2">
            <div class="mr-2">
                <img class="m-auto" style="width: 15rem" src="/images/Search.jpg" alt="">
            </div>
            <div class="ml-2 mt-5 mb-5">
                <h2 class="text-center">{{ $t("errMsg.popup.description") }}</h2>
                <p  class="text-center"><router-link class="" to="/">{{ $t('errMsg.pageNotFound.pageNotFoundGoBackToStartPage') }}<i class="nc-arrow-right"/></router-link></p>
            </div>
        </div>
    </div>
  </div>
</template>
<script>
export default {
}
</script>
