<template>
  <div
    class="shadow-sm bg-white statisticsCard ml-1 mr-1 mb-1 mt-1"
    v-if="hasData"
  >

    <div class="title">
      <h2 class="h2 mt-1 mb-1 text-center">{{$t(title)}}</h2>
    </div>

    <Donut
      :chartData="chartData"
      :chartOptions="chartOptions"
      ref="donut"
    />
  </div>

</template>

<script>
import Donut from './Donut.vue'

export default {
  components: { Donut },
  props: {
    title: {
      type: String,
      default: function () {
        return 'dashboard.allAreas'
      }
    },
    statistics: {
      type: Object,
      default () {
        return {
          completed: 0,
          pending: 0,
          notApproved: 0,
          noStatus: 0
        }
      }
    }
  },
  computed: {
    hasData: function () {
      const statusArray = [
        this.statistics.completed,
        this.statistics.pending,
        this.statistics.notApproved,
        this.statistics.noStatus
      ]
      if (JSON.stringify(statusArray) === JSON.stringify([0, 0, 0, 0])) {
        return false
      }
      return true
    },
    chartData: function () {
      const data = {
        labels: [
          this.$t('activity.activityStatus.statusApproved'),
          this.$t('activity.activityStatus.statusUnderProgress'),
          this.$t('activity.activityStatus.statusNotApproved'),
          this.$t('activity.activityStatus.statusNotSet')
        ],
        datasets: [
          {
            backgroundColor: ['#2bb73d', '#ffd400ed', '#e84949', '#9fa8a0'],
            data: [
              this.statistics.completed,
              this.statistics.pending,
              this.statistics.notApproved,
              this.statistics.noStatus
            ]
          }
        ]
      }
      return data
    }
  },
  data () {
    return {
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }
    }
  }
}
</script>
