var render, staticRenderFns
import script from "./Donut.vue?vue&type=script&lang=js&"
export * from "./Donut.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Donut.vue?vue&type=custom&index=0&blockType=Doughnut&%3Achart-options=chartOptions&%3Achart-data=chartData&%3Achart-id=chartId&%3Adataset-id-key=datasetIdKey&%3Aheight=height&%3Awidth=width"
if (typeof block0 === 'function') block0(component)

export default component.exports