<template>
  <div>
    <b-modal
      v-model="show"
      body-bg-variant="light"
      dialog-class="dpia-modal"
      footer-bg-variant="light"
      footer-border-variant="light"
      size="xl"
      @hidden="resetEvaluation"
      v-bind="{'no-close-on-backdrop':editMode, 'no-close-on-esc':editMode}"
    >
      <template
      v-slot:modal-header="{ close }"
      >
        <div>
          <h1
            v-if="evaluationId!==null"
            class="float-left"
          >
            {{evaluation.name}}
          </h1>
          <h1
            v-else
            class="float-left"
          >
            {{$t('dpia.newDpiaEvaluation')}}
          </h1>
        </div>
        <div class="ml-auto pl-3 btn-group">
          <b-button
            id="savebutton"
            v-if="!evaluationId && editMode && !isLoading && evaluation.conclusion==null && (evaluation.comment!=null || evaluation.answers.length>0)"
            class="activity-button-save btn-nc-primary"
            @click.once="onSaveClick(); close()"
          >
            <i class="nc-save mr-1" />
            {{ $t('general.save') }}
          </b-button>
          <b-button
            id="saveinprogressbutton"
            v-if="evaluationId && editMode && evaluation.conclusion==null && !isLoading"
            class="activity-button-save btn-nc-primary"
            @click.once="onSaveUpdateClick(); close()"
          >
            <i class="nc-save mr-1" />
            {{ $t('general.save') }}
          </b-button>
          <b-button
            id="savecompletedbutton"
            v-if="evaluationId && editMode && !isLoading && evaluation.conclusion!=null"
            class="activity-button-save btn-nc-primary"
            @click.once="onCompleteUpdateClick()"
          >
            <i class="nc-save mr-1" />
            {{ $t('general.save') }}
          </b-button>
          <b-button
            id="editconclusionbutton"
            v-if="!editMode && !isLoading && $route.meta.user.isAdmin"
            class="activity-button-save mr-1 btn-nc"
            @click="showAdminModal(evaluation)"
          >
            <i class="nc-edit-dark mr-1 mb-1" />
            {{ $t('dpia.editAdmin') }}
          </b-button>
          <b-button
            id="editanswersbutton"
            v-if="!editMode && !isLoading"
            class="activity-button-save mr-1 btn-nc"
            @click="onEditClick()"
          >
            <i class="nc-edit-dark mr-1 mb-1" />
            {{ $t('dpia.editAnswers') }}
          </b-button>
        </div>
        <div class="pl-1">
          <b-button
            v-if="editMode && !isLoading && (evaluation.comment!=null || evaluation.answers.length>0)"
            id="softclosebutton"
            class="activity-button-save mr-1 btn-nc"
            @click="focusSaveCloseBtn()"
          >
            <i class="nc-close mr-1" />
            {{ $t('general.close') }}
          </b-button>
          <b-popover
            v-if="editMode && !isLoading && (evaluation.comment!=null || evaluation.answers.length>0)"
            placement="bottom"
            :show.sync="closePopoverShow"
            target="softclosebutton"
            triggers="focus"
          >
            <template v-slot:title>{{ $t('dpia.close') }}</template>
            <p>{{ $t('dpia.closeInfo') }} </p>
            <b-button
              ref="cancelclose"
              v-if="!evaluationId"
              class="mr-1 btn-nc-primary"
              tabindex="2"
              @click="onSaveClick(); close(); closePopoverShow = false"
            >
              <i class="nc-save mr-1" />
              {{ $t('dpia.saveclose') }}
            </b-button>
            <b-button
              ref="cancelclose"
              v-else-if="evaluationId && evaluation.conclusion==null"
              class="mr-1 btn-nc-primary"
              tabindex="2"
              @click="onSaveUpdateClick(); closePopoverShow = false; close()"
            >
              <i class="nc-save mr-1" />
              {{ $t('dpia.saveclose') }}
            </b-button>
            <b-button
              ref="cancelclose"
              v-else-if="evaluationId && evaluation.conclusion!=null"
              class="mr-1 btn-nc-primary"
              tabindex="2"
              @click="onCompleteUpdateClick(); closePopoverShow = false; close()"
            >
              <i class="nc-save mr-1" />
              {{ $t('dpia.saveclose') }}
            </b-button>
            <b-button
              class="mr-1 btn-nc"
              tabindex="3"
              @click="close(); closePopoverShow = false"
            >
              <i class="nc-close mr-1" />
              {{ $t('general.close') }}
            </b-button>
          </b-popover>
          <b-button
            v-else
            aria-label="Close"
            class="activity-button-save btn-nc"
            type="button"
            @click="close()"
          >
            <i class="nc-close mr-1" />
            {{ $t('general.close') }}
          </b-button>
        </div>
      </template>
      <template>
        <b-alert
          v-if="errMsg"
          dismissible
          :show="this.errMsg !== undefined && this.errMsg !== null"
          variant="danger"
          @dismissed="disableAlert"
        >
          {{ errMsg }}
        </b-alert>
        <div
          v-if="isLoading"
          class="loadingOverlay d-flex justify-content-center"
        >
          <b-spinner
            class="spinner m-5"
            variant="secondary"
          >
            <span class="sr-only">Loading</span>
          </b-spinner>
        </div>
        <b-row class="m-0">
          <b-col class="activity-box">
            <div
              v-if="!editMode && evaluationId"
              class="mt-3 mb-3"
            >
              <div class="d-flex flex-row mb-2">
                <h2>{{ $t('dpia.statusEvaluation') }}</h2>
                <h2 class="ml-2" v-if="evaluation.conclusion!=null"><b>{{ $t('filter.evaluationCompleted') }}</b></h2>
                <h2 class="ml-2" v-else><b>{{ $t('filter.evaluationInProgress') }}</b></h2>
              </div>
              <div class="d-flex flex-row">
                <h2 v-if="evaluation.conclusion!=null">{{ $t('dpia.resultEvaluation') }}</h2>
                <h2 class="ml-2" v-if="evaluation.conclusion"><b>{{ $t('dpia.dpiaNeeded') }}</b></h2>
                <h2 class="ml-2" v-else-if="evaluation.conclusion === false"><b>{{ $t('dpia.dpiaNotNeeded') }}</b></h2>
                <h2 class="ml-2" v-if="evaluation.hasBeenOverridden"> {{ $t('dpia.conclusionOverridden') }}</h2>
              </div>
            </div>
            <div v-if="!editMode && !isLoading && evaluation.conclusion != null">
              <div>
                <p class="result" v-if="evaluation.conclusion && !evaluation.hasBeenOverridden" v-html="$t('dpia.resultTrue')"></p>
                <p class="result" v-else-if="!evaluation.conclusion && !evaluation.hasBeenOverridden" v-html="$t('dpia.resultFalse')"></p>
                <p class="result" v-else-if="!evaluation.conclusion && evaluation.hasBeenOverridden" v-html="$t('dpia.resultOverriddenTrue')"></p>
                <p class="result" v-else-if="evaluation.conclusion && evaluation.hasBeenOverridden" v-html="$t('dpia.resultOverriddenFalse')"></p>
              </div>
              <div class="activity-box-part mb-4">
                <h3>{{ $t('dpia.anyComments') }}</h3>
                <text-area-input-comment
                  id="dpia-comment"
                  class="mb-2"
                  :editMode="editMode"
                  :placeholder="$t('dpia.comment')"
                  :emptyDescription="$t('general.emptydata')"
                  :rows="3"
                  :value="evaluation.comment"
                  @update-text="updateComment"
                />
              </div>
              <b-button
                class="mb-2 btn-nc moreLess"
                v-b-toggle="`collapse-` + evaluation.toggleId"
                @click="evaluation.visible = !evaluation.visible"
              >
                {{ $t('dpia.questions') }}
              </b-button>
              <b-collapse
                :id="'collapse-' + evaluation.toggleId"
                class="mt-2"
                :visible="evaluation.visible"
              >
                <evaluation-questions
                  :evaluation="evaluation"
                  :sentFromExistingEvaluation="sentFromExistingEvaluation"
                  :editMode="editMode"
                  :sentFromActivity="sentFromActivity"
                  :activityId="activityId"
                  v-on:error="errMsg = $event"
                  @update-evaluation="updateEvaluation"
                />
              </b-collapse>
            </div>
            <div v-else>
              <evaluation-questions
                :evaluation="evaluation"
                :sentFromExistingEvaluation="sentFromExistingEvaluation"
                :editMode="editMode"
                :sentFromActivity="sentFromActivity"
                :activityId="activityId"
                v-on:error="errMsg = $event"
                @update-evaluation="updateEvaluation"
              />
            </div>
          </b-col>
        </b-row>
      </template>
      <template v-slot:modal-footer>
        <b-container>
          <b-row>
            <b-col
              class="complete-button"
              md="4"
              offset="4"
            >
              <b-button
                v-if="!evaluationId && !isLoading"
                :disabled="disableCompleteButton"
                class="btn-nc-primary"
                @click.once="onCompleteClick()"
              >
                <i class="nc-complete mr-1" />
                {{ $t('general.complete') }}
              </b-button>
              <b-button
                v-if="evaluationId && evaluation.conclusion == null && !isLoading"
                :disabled="disableCompleteButton"
                class="btn-nc-primary"
                @click.once="onCompleteUpdateClick()"
              >
                <i class="nc-complete mr-1" />
                {{ $t('general.complete') }}
              </b-button>
            </b-col>
            <created-and-changed-by
              :data="evaluation"
              :userModifiedData=getUsername(evaluation.modifiedBy)
              :userCreatedData=getUsername(evaluation.createdBy)
            />
          </b-row>
          <dpia-admin-page
            ref="DpiaAdminPage"
            @delete-dpia = onDeleteClick
            @changeconclusion-dpia = updateConclusionOverride
            @changestatus-dpia = onSaveUpdateClick
          >
          </dpia-admin-page>
        </b-container>
      </template>
    </b-modal>
  </div>
</template>
<script>
import axios from 'axios'
import EvaluationQuestions from '@/components/dpia/EvaluationQuestions'
import TextAreaInputComment from '../common/TextAreaInputComment'
import DpiaAdminPage from './DpiaAdminPage'
import CreatedAndChangedBy from '../common/CreatedAndChangedBy'

export default {
  name: 'dpia-modal',
  components: {
    EvaluationQuestions,
    TextAreaInputComment,
    DpiaAdminPage,
    CreatedAndChangedBy
  },
  data: function () {
    return {
      show: false,
      editMode: false,
      sentFromExistingEvaluation: false,
      evaluationId: null,
      tabIndex: 0,
      evaluation: {},
      errMsg: null,
      isLoading: false,
      deletePopoverShow: false,
      closePopoverShow: false,
      changePopoverShow: false,
      disableCompleteButton: true
    }
  },
  props: {
    sentFromActivity: {
      type: Boolean,
      default: false
    },
    activityId: {
      type: String
    }
  },
  methods: {
    toggle (evaluationId) {
      if (evaluationId) {
        this.evaluationId = evaluationId
        this.editMode = false
        this.isLoading = true
        axios.get('api/dpiaEvaluation/' + evaluationId)
          .then(response => {
            this.evaluation = response.data
            if (this.evaluation.processingActivityIds.length > 0) {
              this.sentFromExistingEvaluation = true
            }
          })
          .catch(e => {
            this.errMsg = this.$t('errMsg.getGenericErrorMessage') + '\n' + e.message
          })
          .finally(() => {
            this.show = !this.show
            this.isLoading = false
          })
      } else {
        this.resetEvaluation()
        this.show = !this.show
      }
    },
    updateConclusionOverride () {
      this.evaluation.conclusion = !this.evaluation.conclusion
      this.evaluation.hasBeenOverridden = !this.evaluation.hasBeenOverridden
      const vm = this
      axios.put('api/dpiaEvaluation/' + this.evaluationId, this.evaluation).then(response => {
        vm.evaluation = response.data
        this.updateEvaluations()
      }).catch(error => {
        this.errMsg = this.$t('errMsg.postGenericErrorMessage')
        if (error.response) {
          this.errMsg += ' ' + error.response.data.errorMessage
        } else { this.errMsg += ' ' + error.message }
      }).finally(() => {
        if (this.sentFromActivity) {
          this.$emit('update-dpia-info-in-activity')
        }
      })
    },
    onEditClick () {
      this.editMode = true
    },
    onSaveClick () {
      this.isLoading = true
      this.evaluation.conclusion = null
      axios.post('api/dpiaEvaluation/', this.evaluation).then(response => {
        this.updateEvaluations()
        this.evaluation = response.data
        this.evaluationId = response.data.id
        this.editMode = false
        this.show = false
      }).catch(error => {
        this.errMsg = this.$t('errMsg.postGenericErrorMessage')
        if (error.response) {
          this.errMsg += ' ' + error.response.data.errorMessage
        } else { this.errMsg += ' ' + error.message }
      }).finally(() => {
        this.isLoading = false
        this.deletePopoverShow = false
        this.closePopoverShow = false
        this.changePopoverShow = false
        if (this.sentFromActivity) {
          this.$emit('update-dpia-info-in-activity')
        }
      })
    },
    onSaveUpdateClick () {
      this.isLoading = true
      const vm = this
      this.evaluation.conclusion = null
      this.evaluation.hasBeenOverridden = false
      axios.put('api/dpiaEvaluation/' + this.evaluationId, this.evaluation).then(response => {
        vm.evaluation = response.data
        this.editMode = false
        this.updateEvaluations()
      }).catch(error => {
        this.errMsg = this.$t('errMsg.postGenericErrorMessage') + '\n' + error.message
        console.log(JSON.stringify(this.evaluation))
      }).finally(() => {
        this.isLoading = false
        this.deletePopoverShow = false
        this.closePopoverShow = false
        this.changePopoverShow = false
        if (this.sentFromActivity) {
          this.$emit('update-dpia-info-in-activity')
        }
      })
    },
    onCompleteClick () {
      this.isLoading = true
      this.createConclusion()
      axios.post('api/dpiaEvaluation/', this.evaluation).then(response => {
        this.updateEvaluations()
        this.evaluation = response.data
        this.evaluationId = response.data.id
        this.editMode = false
        this.show = true
      }).catch(error => {
        this.errMsg = this.$t('errMsg.postGenericErrorMessage')
        if (error.response) {
          this.errMsg += ' ' + error.response.data.errorMessage
        } else { this.errMsg += ' ' + error.message }
      })
        .finally(() => {
          this.editMode = false
          this.show = true
          this.isLoading = false
          this.deletePopoverShow = false
          this.closePopoverShow = false
          this.changePopoverShow = false
          this.sentFromExistingEvaluation = true
          if (this.sentFromActivity) {
            this.$emit('update-dpia-info-in-activity')
          }
        })
    },
    onCompleteUpdateClick () {
      this.isLoading = true
      const vm = this
      this.createConclusion()
      this.evaluation.hasBeenOverridden = false
      axios.put('api/dpiaEvaluation/' + this.evaluationId, this.evaluation).then(response => {
        vm.evaluation = response.data
        this.editMode = false
        this.updateEvaluations()
      }).catch(error => {
        this.errMsg = this.$t('errMsg.postGenericErrorMessage') + '\n' + error.message
        console.log(JSON.stringify(this.evaluation))
      }).finally(() => {
        this.editMode = false
        this.isLoading = false
        this.deletePopoverShow = false
        this.closePopoverShow = false
        this.changePopoverShow = false
        if (this.sentFromActivity) {
          this.$emit('update-dpia-info-in-activity')
        }
      })
    },
    onDeleteClick () {
      this.isLoading = true
      axios.delete('api/dpiaEvaluation/' + this.evaluationId).then(() => {
      }).then(() => {
        this.updateEvaluations()
        this.show = false
      }).catch(error => {
        this.errMsg = this.$t('errMsg.deleteGenericErrorMessage') + '\n' + error.message
      }).finally(() => {
        this.isLoading = false
        this.deletePopoverShow = false
        if (this.sentFromActivity) {
          this.$emit('update-dpia-info-in-activity')
        }
      })
    },
    updateComment (comment) {
      this.evaluation.comment = comment
    },
    resetEvaluation () {
      this.sentFromExistingEvaluation = false
      this.evaluationId = null
      this.evaluation = {
        name: null,
        conclusion: null,
        processingActivityIds: [],
        answers: []
      }
      this.errMsg = null
      this.editMode = true
    },
    disableAlert () {
      this.$emit('disable-alert')
    },
    createConclusion () {
      if (this.evaluation.answers.reduce((a, c) => c.answer ? ++a : a, 0) >= 2) {
        this.evaluation.conclusion = true
      } else {
        this.evaluation.conclusion = false
      }
    },
    updateEvaluation (evaluation) {
      this.evaluation = evaluation
      if (this.evaluation.answers.reduce((a, c) => c.answer != null ? ++a : a, 0) === 9) {
        this.disableCompleteButton = false
      } else {
        this.disableCompleteButton = true
      }
    },
    updateEvaluations () {
      this.$emit('update-dpia-evaluations')
    },
    getUsername (user) {
      if (!user) return ''
      return user.name
    },
    showAdminModal: function (evaluation) {
      this.$refs.DpiaAdminPage.show(evaluation)
    },
    focusSaveCloseBtn () {
      if (!this.$refs.cancelclose) {
        setTimeout(() => {
          this.$refs.cancelclose.focus()
        }, 50)
      } else this.$refs.cancelclose.focus()
    }
  }
}
</script>
<style lang="scss">
.dpia-modal {
  display: flex;
}
.activity-button-save {
  float: right;
}
.text-footer {
  font-size: 0.8rem;
  text-align: right;
}
.complete-button {
  text-align: center;
}
.result {
  font-size: 1.125rem;
}
</style>
