<template>
  <div >
    <div
      v-if="this.$route.meta.user.isAdmin"
      style="position: absolute ; right: 0"
      class="d-flex  shadow-sm bg-white"
    >
      <span class="mr-1 ml-1 mb-1"> {{$t('dashboard.adminView')}}
      </span>
      <b-form-checkbox
        switch
        size="lg"
        v-model="isAdminViewMode"
      >
      </b-form-checkbox>
    </div>
    <AdminDashboard v-if="isAdminViewMode"></AdminDashboard>
    <Dashboard v-if="!isAdminViewMode"></Dashboard>
  </div>
</template>

<script>
import AdminDashboard from './AdminDashboard.vue'
import Dashboard from './Dashboard.vue'

export default {
  components: { AdminDashboard, Dashboard },

  data: () => {
    return {
      isAdminViewMode: false
    }
  },
  created () {
    if (localStorage.isAdminViewMode) {
      if (this.$route.meta.user.isAdmin) {
        // neccessary, as local storage only stores strings
        if (localStorage.isAdminViewMode === 'true') {
          this.isAdminViewMode = true
        }
      }
    }
  },

  watch: {
    isAdminViewMode (enable) {
      // warning, this will store true/false as a string, not a boolean, as it is how localStorage works. Be carefull with if-checks, as "false" is truthy.
      localStorage.isAdminViewMode = enable
    }
  }
}
</script>

<style scoped lang="scss">
@import "../styles/_variables.scss";
.switch {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 24px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $nc-shade-dark;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 24px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: $navbar-selected;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(36px);
  -ms-transform: translateX(26px);
  transform: translateX(55px);
}

/*------ ADDED CSS ---------*/
.slider:after {
  content: "Adminvisning";
  color: white;
  display: block;
  position: absolute;
  transform: translate(-50%, -60%);
  top: 50%;
  left: 60%;
  font-size: 10px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider:after {
  content: "Adminvisning";
}

/*--------- END --------*/
</style>
