<template>
    <div>
        <b-button :id="this.target"
                class="activity-button-save mr-1 btn-nc-helptext"
                :aria-label="$t('helptext.text') + this.target">
                <i class="nc-help"/>
        </b-button>
        <b-popover
            :placement="this.placement"
            :target="this.target"
            triggers="focus"
            :title="this.title">
            <div v-html="this.helptext">
            </div>
        </b-popover>
    </div>
</template>
<script>
export default {
  name: 'helptext-popup',
  data: function () {
    return {
    }
  },
  props: {
    title: {
      type: String,
      default: null
    },
    helptext: {
      type: String,
      default: null
    },
    target: {
      type: String,
      default: null
    },
    placement: {
      type: String,
      default: 'bottom'
    }
  }
}
</script>
