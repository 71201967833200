<template>
    <div v-if="editMode">
      <div v-if="this.$i18n.locale === 'no'">
        <b-form-datepicker
            :aria-label="$t('datepicker.datepicker')"
            :placeholder="$t('datepicker.chooseDate')"
            :label-help="$t('datepicker.labelHelp')"
            :label-nav="$t('datepicker.labelNav')"
            :label-next-month="$t('datepicker.labelNextMonth')"
            :label-next-year="$t('datepicker.labelNextYear')"
            :label-no-date-selected="$t('datepicker.labelNoDateSelected')"
            :label-prev-year="$t('datepicker.labelPrevYear')"
            :label-prev-month="$t('datepicker.labelPrevMonth')"
            :label-current-month="$t('datepicker.labelCurrentMonth')"
            :label-today="$t('datepicker.labelToday')"
            :label-selected="$t('datepicker.labelSelected')"
            :label-calendar="$t('datepicker.labelCalendar')"
            v-model="date"
            :locale="'nb'"
            start-weekday="1"
            value-as-date
            @input="updateDatePickerInput"></b-form-datepicker>
      </div>
      <div v-else>
        <b-form-datepicker
            :aria-label="$t('datepicker.datepicker')"
            v-model="date"
            :locale="this.$i18n.locale"
            start-weekday="1"
            value-as-date
            @input="updateDatePickerInput"></b-form-datepicker>
      </div>
    </div>
    <div v-else>
        <p v-if="date && !editMode" class="date-picker-view font-weight-bold">{{ $d(date) }}</p>
    </div>
</template>

<script>
export default {
  name: 'date-picker-input',
  props: {
    value: {
      type: Date
    },
    editMode: {
      type: Boolean
    }
  },
  data: function () {
    return {
      date: null
    }
  },
  created () {
    this.date = this.value
  },
  methods: {
    updateDatePickerInput () {
      this.$emit('update-date', this.date)
    }
  },
  watch: {
    value: {
      immediate: true,
      handler (value) {
        this.date = value
      }
    }
  }
}
</script>
