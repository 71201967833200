import axios from 'axios'

const getUser = async () => {
  try {
    const res = await axios.get('/api/users/current')
    if (res.status === 200) {
      return res.data
    }
  } catch (err) {
    console.error(err)
    return null
  }
  return null
}
const roles = {
  admin: 'admin',
  user: 'user'
}

export const localApi = {
  getUser,
  roles
}
