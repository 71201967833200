<template>
    <div :class="rowClass + ' shadow-sm bg-white pl-4 pr-4 pt-3 ml-4 mr-4 mb-4 mt-1'" style="minHeight: 14rem">
      <b-alert variant="danger" dismissible :show="errMsg.text !== undefined && errMsg.text !== null" @dismissed="disableAlert">
        {{errMsg.text}}
        <ul>
          <li v-for="item in errMsg.activities" :key="item.id">
            <a v-bind:href="item.url" >{{item.title}} </a>
          </li>
        </ul>
      </b-alert>
      <div class="d-flex justify-content-between mt-1 mb-1">
        <div class="d-flex justify-content-start">
          <h2 :class="titleClass + 'h2 pr-2 mt-1 mb-1 text-left'">{{ this.title !== null ? this.title : ' - ' }}</h2>
        </div>
      </div>
      <div v-if="data.length !== 0" style="minHeight: 7.5rem">
        <div v-for="d in this.getData" :key="d.id" style="display: inline-block">
          <b-badge :id="d.name + d.id.slice(0,4)" :class="badgeClass + ' mt-1 mb-1 p-1 text-wrap'" style="maxWidth: 99%;"> {{ d.name }} <i class="nc-edit-dark" v-if="!onlyReadMode" @click="editMode=!editMode, createMode=false, selectedStaticCode=d.id, codeName=d.name, codeDescription = (d.hasOwnProperty('description') && d.description !== null) ? d.description : '', type = (d.hasOwnProperty('type') && d.type !== null) ? d.type : '' " /> </b-badge>
          <StaticCodePopup v-if="d.hasOwnProperty('description') && d.description !== null && d.description !== ''" :target="d.name + d.id.slice(0,4)" :helptext="d.description"/>
        </div>
      </div>
      <h3 v-else-if="data.length === 0 && !loading" class="text-muted text-center">{{ $t("general.noDataAvailable" )}}</h3>
        <div class="d-flex justify-content-end">
          <b-button class="btn-nc btn-static-codes" v-on:keyup.enter="createMode=true, editMode=false, displayAllData=true" @click.prevent="createMode = true, displayAllData= true" v-if="!createMode &&!editMode && !loading && !onlyReadMode" :id="this.title !== null ? 'edit-button-'+ this.title.split(' ').join('') : 'edit-button'"><i class="nc-add-blue mr-1 mb-1" />{{ $t("general.createNew" )}}</b-button>
        </div>
      <b-spinner variant="secondary" class="spinner" v-if="loading"/>
      <div v-if="!onlyReadMode && createMode && !editMode">
          <div class="form-group row">
              <label for="codename" class="h3 col-md-5 col-lg-3 col-form-label">{{ $t("user.name") }}</label>
              <b-input ref="inputcodename" @keypress.enter="create" type="text" :placeholder="placeholder ? placeholder : title" v-model="codeName" class="input-sm col-md-7 mt-1 mb-1" autofocus="autofocus" :aria-label="this.title" />
              <label for="codedescription" class="h3 col-md-5 col-lg-3 col-form-label">{{ $t("admin.description") }}</label>
              <b-form-textarea id="codedescription"
                  class="col-md-7 col-lg-9 col-form-label"
                  ref="inputcodedescription"
                  v-model="codeDescription"
                  rows="3"
                  max-rows="6">
              </b-form-textarea>
            </div>
          <p class="text-danger mt-1 mb-1" v-if="!loading && invalidInputErrMsg">{{ invalidInputErrMsg }}</p>
      </div>
      <div v-else-if="!onlyReadMode && editMode && !createMode">
          <div class="form-group row">
              <label for="codename" class="h3 col-md-5 col-lg-3 col-form-label">{{ $t("user.name") }}</label>
              <b-input ref="inputcodename" @keypress.enter="create" type="text" :codeName = 'selectedStaticCode.name' v-model="codeName" class="input-sm col-md-7 mt-1 mb-1" autofocus="autofocus" :aria-label="this.title" />
              <label for="codedescription" class="h3 col-md-5 col-lg-3 col-form-label">{{ $t("admin.description") }}</label>
              <b-form-textarea id="codedescription"
                  class="col-md-7 col-lg-9 col-form-label"
                  ref="inputcodedescription"
                  :codeDescription = selectedStaticCode.description
                  v-model="codeDescription"
                  rows="3"
                  max-rows="6">
              </b-form-textarea>
            </div>
          <p class="text-danger mt-1 mb-1" v-if="!loading && invalidInputErrMsg">{{ invalidInputErrMsg }}</p>
      </div>
      <div v-if="editMode && !createMode" class="form-group d-flex">
        <b-button @click="update(selectedStaticCode)" class="btn-nc-primary m-1 ml-auto" :disabled="!isValidInput"> <i class="nc-save mr-2 mb-1" />{{ $t("general.save") }} </b-button>
        <b-button @click="editMode = false, createMode = false, displayAllData= false, codeName = '', codeDescription = ''" class="mb-1 mt-1 btn-nc"><i class="nc-close mr-2 mb-1" />{{ $t("general.cancel") }}</b-button>
        <b-button @click="remove(selectedStaticCode), editMode = false, displayAllData= false, codeName = ''" class="mb-1 mt-1 btn-nc"><i class="nc-delete mr-2 mb-1" />{{ $t("general.delete") }}</b-button>
      </div>
      <div v-if="!editMode && createMode" class="form-group d-flex">
        <b-button @click="create" class="btn-nc-primary m-1 ml-auto" :disabled="!isValidInput"> <i class="nc-save mr-2 mb-1" />{{ $t("general.save") }} </b-button>
        <b-button @click="editMode = false, createMode = false, displayAllData= false, codeName = ''" class="mb-1 mt-1 btn-nc"><i class="nc-close mr-2 mb-1" />{{ $t("general.cancel") }}</b-button>
      </div>
      <div class="d-flex justify-content-between m-0 mt-1">
        <div></div>
        <div v-if="data.length > 15 && !editMode" class="d-flex justify-content-start text-readmore">
          <p tabindex="0" v-on:keyup.enter="displayAllData = true" class="" v-if="data.length > 15 && !loading && !displayAllData"  @click.prevent="displayAllData = true">{{ $t("general.seeMore" )}} <i class="nc-arrow-down mb-2 ml-1"/></p>
          <p tabindex="0" v-on:keyup.enter="displayAllData = false" class="" v-else-if="data.length > 15 && !loading && displayAllData"  @click.prevent="displayAllData = false">{{ $t("general.seeLess" )}} <i class="nc-arrow-up mb-1 ml-1"/></p>
        </div>
        <div v-if="data.length !== 0" class="d-flex justify-content-end">
          <p class="text-muted" style="fontSize: .8rem">{{ $t("general.displaying")+ ' ' + getData.length +' / '+ data.length }}</p>
        </div>
      </div>
    </div>
</template>
<script>
import StaticCodePopup from '../staticCodes/StaticCodePopover'
export default {
  name: 'static-codes-row',
  components: {
    StaticCodePopup
  },
  data: function () {
    return {
      data: [],
      editMode: false,
      createMode: false,
      selectedStaticCode: null,
      codeName: '',
      codeDescription: '',
      loading: false,
      displayAllData: false,
      errMsg: null,
      invalidInputErrMsg: null,
      onlyReadMode: true
    }
  },
  props: {
    title: {
      type: String
    },
    placeholder: {
      type: String,
      default: null
    },
    inData: {
      type: Array,
      required: true
    },
    rowClass: {
      type: String,
      default: ''
    },
    badgeClass: {
      type: String,
      default: 'badge-primary'
    },
    titleClass: {
      type: String,
      default: ''
    },
    inLoading: {
      type: Boolean,
      default: false
    },
    inErrMsg: {
      type: Object,
      default: null
    },
    inOnlyReadMode: {
      type: Boolean,
      default: true
    }
  },
  created () {
    this.data = this.inData
    this.loading = this.inLoading
    this.errMsg = this.inErrMsg
    this.onlyReadMode = this.inOnlyReadMode
  },
  computed: {
    getData () {
      return this.displayAllData ? this.data : this.data.slice(0, 15)
    },
    isValidInput () {
      return ((this.codeName.length > 0 && this.codeName.length <= 100) && this.codeDescription.length <= 350)
    }
  },
  methods: {
    create () {
      this.invalidInputErrMsg = null
      if (!this.onlyReadMode) {
        if (this.isValidInput) {
          this.$emit('create', this.codeName, this.codeDescription, this.type)
          this.codeName = ''
          this.codeDescription = ''
          this.editMode = false
          this.createMode = false
          this.displayAllData = true
          this.selectedStaticCode = null
        } else {
          this.invalidInputErrMsg = this.$t('inputFeedbackMsg.genericlInputFeedbackError')
        }
      }
    },
    update () {
      this.invalidInputErrMsg = null
      if (!this.onlyReadMode) {
        if (this.isValidInput) {
          this.$emit('update', this.selectedStaticCode, this.codeName, this.codeDescription, this.type)
          this.codeName = ''
          this.codeDescription = ''
          this.editMode = false
          this.createMode = false
          this.displayAllData = true
          this.selectedStaticCode = ''
        } else {
          this.invalidInputErrMsg = this.$t('inputFeedbackMsg.genericlInputFeedbackError')
        }
      }
    },
    remove (id) {
      if (!this.onlyReadMode) {
        this.$emit('remove', id)
      }
      this.selectedStaticCode = null
    },
    disableAlert () {
      this.$emit('disableAlert')
    }
  },
  watch: {
    inData () {
      this.data = this.inData
    },
    inLoading () {
      this.loading = this.inLoading
    },
    inErrMsg () {
      this.errMsg = this.inErrMsg
    },
    inOnlyReadMode () {
      this.onlyReadMode = this.inOnlyReadMode
    }
  }
}
</script>
