<template>
  <div class="d-flex justify-content-center">
    <b-modal
      v-model="show"
      size="xl"
      hide-footer
      centered
      body-bg-variant="light"
      content-class="shadow"
    >
      <template #modal-title>
        <div>
          <h2>{{ $t('dashboard.warning') }}</h2>
        </div>
      </template>
      <!-- Ved flytting av en enkelt BA -->
      <div v-if="!isMultiselect">
        <div class="d-flex justify-content-center">
          <h1>{{ $t('dashboard.approvalConfirmation') }} <b>{{ activity.aboutProcessingActivity.title }}</b>?</h1>
        </div>
        <div class="d-flex justify-content-center mt-5 mb-2">
          <b-button
            v-b-modal.modal-close_visit
            size="lg"
            class="btn btn-success px-5 mr-5"
            @click="$emit('confirm-approval-activity', activity, 1, 1); show=false"
          >{{ $t('general.yes') }}</b-button>
          <b-button
            v-b-modal.modal-close_visit
            size="lg"
            class="btn btn-danger px-5 ml-5"
            @click="$emit('reject-approval-activities'); show=false"
          >{{ $t('general.no') }}</b-button>
        </div>
      </div>
      <!-- Ved flytting av flere BA -->
      <div v-if="isMultiselect">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div>
            <h1> {{$t("approvalWarningModal.tryingToApprove")}} <b> {{selectedActivities.length }}</b> {{ selectedActivities.length > 1 ? $t("approvalWarningModal.activities") : $t("approvalWarningModal.activity")}}.</h1>
          </div>
          <div class="mb-2">
            <h1> {{$t("approvalWarningModal.areYouSure")}} </h1>
          </div>
        </div>
        <div
          class="row"
          v-for="(activities, index) in chunkedActivities"
          :key="index"
        >
          <board-activity-card
            class="col-3"
            v-for="activity in activities"
            :key="activity.id"
            :statusCode="activity.assignedUser.statusCode"
            :title="activity.aboutProcessingActivity.title"
            :isInApprovalWarningModal="true"
          ></board-activity-card>
        </div>
        <div class="d-flex justify-content-center mt-5 mb-2">
          <b-button
            v-b-modal.modal-close_visit
            size="lg"
            class="btn btn-success px-5 mr-5"
            @click="$emit('confirm-approval-multiselect', selectedActivities, 1); show=false"
          >{{ $t('general.yes') }}</b-button>
          <b-button
            v-b-modal.modal-close_visit
            size="lg"
            class="btn btn-danger px-5 ml-5"
            @click="$emit('reject-approval-activities'); show=false"
          >{{ $t('general.no') }}</b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import BoardActivityCard from './BoardActivityCard.vue'
export default {
  components: { BoardActivityCard },
  name: 'approval-warning-modal',
  data: function () {
    return {
      show: false,
      isMultiselect: false,
      selectedActivities: [],
      activity: { aboutProcessingActivity: {} }
    }
  },
  computed: {
    // Brukt for å kunne lage grid av de ulike BAene
    chunkedActivities () {
      const chunkedActivities = []
      for (let i = 0; i < this.selectedActivities.length; i += 4) {
        chunkedActivities.push(this.selectedActivities.slice(i, i + 4))
      }
      return chunkedActivities
    }
  },
  methods: {
    confirmApproval (activity) {
      this.isMultiselect = false
      this.activity = activity
      this.show = true
    },
    confirmArrayApproval (selectedActivities) {
      this.isMultiselect = true
      this.selectedActivities = selectedActivities
      this.show = true
    }
  }
}
</script>
