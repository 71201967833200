<template>
    <div v-if="isLoading" class="loadingOverlay d-flex justify-content-center">
      <b-spinner variant="secondary" class="spinner m-5">
        <span class="sr-only">Loading</span>
      </b-spinner>
    </div>
</template>
<script>
export default {
  name: 'loading-overlay',
  props: {
    isLoading: {
      type: Boolean
    }
  }
}
</script>
