<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <b-col sm="6" md="4">
    <b-input-group>
      <b-form-input maxLength="100" debounce="500" v-model="text" :aria-label="$t('navigation.search')" :placeholder="$t('navigation.search')"></b-form-input>
      <b-alert dismissible @dismissed="() => disableAlert('invalidEmailErrMsg')" :show="this.invalidSearchLength" class="searchInputAlert alert alert-primary w-100" role="alert">
        <p id="searchInputText">{{ $t("inputFeedbackMsg.invalidSearchInputLength") }}</p>
      </b-alert>
    </b-input-group>
  </b-col>
</template>
<script>
export default {
  name: 'search',
  props: {
    searchMinLimit: {
      type: Number,
      default: 3
    },
    searchMaxLimit: {
      type: Number,
      default: 100
    }
  },
  data: function () {
    return {
      text: '',
      invalidSearchLength: false
    }
  },
  watch: {
    text: {
      handler () {
        if (this.text.length > 99) {
          this.invalidSearchLength = true
        } else {
          this.invalidSearchLength = false
        }
        if (this.text.length === 0 || (this.text.length >= this.searchMinLimit && this.text.length <= this.searchMaxLimit)) {
          this.$emit('update-search-text', this.text)
        }
      }
    }
  },
  methods: {
    disableAlert () {
      this.invalidSearchLength = false
    }
  }
}
</script>
<style>
#searchInputText {
  font-size: 0.8rem;
  margin: 0;
  max-width: calc(100% - 1.5rem);
}

.searchInputAlert {
  margin-top: 0.5rem !important;
  padding: 0.5rem 1rem !important;
}

.searchInputAlert > button {
  padding: 0.4rem 1rem !important;
}
</style>
