<template>
  <div
    class="shadow-sm bg-white pl-5 pr-5 pb-4 pt-4 mt-4"
    role="region"
    aria-label="Filter"
  >
  <b-row>
    <b-col class="col-sm-3">
        <h2 class="h2 filter-header">{{ $t('filter.filter') }}</h2>
      </b-col>
      <b-col class="col-sm-9">
        <div class="float-right">
          <b-button v-on:click="onReset()" class="btn-nc">{{ $t('filter.reset') }}</b-button>
        </div>
      </b-col>
    </b-row>
    <fieldset class="mt-4 mb-4">
      <legend class="h3">{{$t('dpia.filter.evaluationStatus')}}</legend>
      <b-form-checkbox
        id="checkbox-evaluationNotCompleted"
        size="sm"
        v-model="filterOptions.isDpiaEvaluationNotCompleted"
        name="checkbox-evaluationNotCompleted"
      >
        {{ $t('dpia.filter.evaluationNotCompleted') }}
      </b-form-checkbox>
      <b-form-checkbox
        id="checkbox-evaluationCompleted"
        size="sm"
        v-model="filterOptions.isDpiaEvaluationCompleted"
        name="checkbox-evaluationCompleted"
      >
        {{ $t('dpia.filter.evaluationCompleted') }}
      </b-form-checkbox>
    </fieldset>
    <fieldset class="mt-4 mb-4">
      <legend class="h3">{{$t('dpia.filter.evaluationResult')}}</legend>
        <b-form-checkbox
        id="checkbox-dpiaNeeded"
        size="sm"
        v-model="filterOptions.isDpiaNeeded"
        name="checkbox-dpiaNeeded"
      >
        {{ $t('dpia.filter.dpiaNeeded') }}
      </b-form-checkbox>
      <b-form-checkbox
        id="checkbox-dpiaNotNeeded"
        size="sm"
        v-model="filterOptions.isDpiaNotNeeded"
        name="checkbox-dpiaNotNeeded"
      >
        {{ $t('dpia.filter.dpiaNotNeeded') }}
      </b-form-checkbox>
    </fieldset>
      <fieldset class="mt-4 mb-4">
      <legend class="h3">{{$t('dpia.filter.conclusionOverriding')}}</legend>
      <b-form-checkbox
        id="checkbox-conclusionOverridden"
        size="sm"
        v-model="filterOptions.isDpiaConclusionOverridden"
        name="checkbox-conclusionOverridden"
      >
        {{ $t('dpia.filter.conclusionIsOverridden') }}
      </b-form-checkbox>
      <b-form-checkbox
        id="checkbox-conclusionNotOverridden"
        size="sm"
        v-model="filterOptions.isDpiaConclusionNotOverridden"
        name="checkbox-conclusionNotOverridden"
      >
        {{ $t('dpia.filter.conclusionIsNotOverridden') }}
      </b-form-checkbox>
    </fieldset>
  </div>
</template>
<script>
export default {
  name: 'filter-component',
  props: {
    filter: {
      type: Object
    }
  },
  data: function () {
    return {
      myFilter: {},
      filterOptions: {
        isDpiaNeeded: false,
        isDpiaNotNeeded: false,
        isDpiaEvaluationCompleted: false,
        isDpiaEvaluationNotCompleted: false,
        isDpiaConclusionNotOverridden: false,
        isDpiaConclusionOverridden: false
      }
    }
  },
  created () {
    this.myFilter = this.filter
  },
  methods: {
    onReset: function () {
      this.myFilter = {}
      this.filterOptions = {
        isDpiaNeeded: false,
        isDpiaNotNeeded: false,
        isDpiaEvaluationCompleted: false,
        isDpiaEvaluationNotCompleted: false,
        isDpiaConclusionNotOverridden: false,
        isDpiaConclusionOverridden: false
      }
    }
  },
  watch: {
    filterOptions: {
      handler () {
        this.myFilter = {
          isDpiaNeeded: this.filterOptions.isDpiaNeeded,
          isDpiaNotNeeded: this.filterOptions.isDpiaNotNeeded,
          isDpiaEvaluationCompleted: this.filterOptions.isDpiaEvaluationCompleted,
          isDpiaEvaluationNotCompleted: this.filterOptions.isDpiaEvaluationNotCompleted,
          isDpiaConclusionNotOverridden: this.filterOptions.isDpiaConclusionNotOverridden,
          isDpiaConclusionOverridden: this.filterOptions.isDpiaConclusionOverridden
        }
        this.$emit('update-filter', this.myFilter)
      },
      deep: true
    }
  }
}
</script>
