<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <b-container class="mt-4" role="main">
    <tenant-activity-card-settings/>
      <div class="shadow-sm bg-white pl-4 pr-4 pt-1 ml-4 mr-4 mb-4 mt-1">
        <div class="bg-white">
          <div class="d-flex justify-content-between mt-1">
            <div class="justify-content-start">
              <h2 class="h2 pr-2 mt-1 mb-1 text-left">{{ $t("user.users") }}</h2>
              <p class="helptext">{{ $t("user.userHelpText") }}</p>
            </div>
            <b-alert variant="danger" dismissible :show="errMsg !== undefined && errMsg !== null" @dismissed="disableAlert">{{ errMsg }}</b-alert>
            <div class="justify-content-end">
              <b-button class="btn-nc mb-1" v-on:keyup.enter="isAddEnabled = true" @click.prevent="isAddEnabled = true" v-if="!isAddEnabled"
                        id="add-button">
                <i class="nc-add-blue mb-1 mr-1" />
                {{ $t("general.add" )}}
              </b-button>
            </div>
          </div>
          <div v-if="isAddEnabled">
            <div>
              <p class="helptext">{{ $t("user.addHelpText") }}</p>
            </div>
            <div class="form-group row">
              <label for="name" class="h3 col-md-5 col-lg-3 col-form-label">{{ $t("user.name") }}</label>
              <b-form-input id="name" type="text" class="col-md-7 col-lg-9 col-form-label" :placeholder="$t('user.name')" v-model="name" />
              <b-alert class="col-md-12 col-lg-12 col-form-label mt-1" variant="danger" dismissible :show="invalidNameErrMsg !== undefined && invalidNameErrMsg !== null" @dismissed="() => disableAlert('invalidNameErrMsg')">{{ invalidNameErrMsg }}</b-alert>
            </div>
            <div class="form-group row">
              <label for="email" class="h3 col-md-5 col-lg-3 col-form-label">{{ $t("user.email") }}</label>
              <b-form-input id="email" type="text" class="col-md-7 col-lg-9 col-form-label" :placeholder="$t('user.email')" v-model="email" />
              <b-alert class="col-md-12 col-lg-12 col-form-label mt-1" variant="danger" dismissible :show="invalidEmailErrMsg !== undefined && invalidEmailErrMsg !== null" @dismissed="() => disableAlert('invalidEmailErrMsg')">{{ invalidEmailErrMsg }}</b-alert>
            </div>
            <div class="form-group row">
              <label for="role" class="h3 col-md-5 col-lg-3 col-form-label">{{ $t("user.role") }}</label>
              <b-form-radio-group v-model="role">
                <b-form-radio value="user">{{ $t("user.roleUser") }}</b-form-radio>
                <b-form-radio value="admin">{{ $t("user.roleAdmin") }}</b-form-radio>
              </b-form-radio-group>
            </div>
            <div class="form-group row">
              <b-button @click="addUser" class="m-1 btn-nc-primary ml-auto"><i class="nc-save mr-2 mb-1" />{{ $t("general.save") }}</b-button>
              <b-button @click="clearAddForm" class="m-1 btn-nc"><i class="nc-close mr-2 mb-1" />{{ $t("general.cancel") }}</b-button>
            </div>
          </div>
        </div>
        <b-row>
          <b-table :items="users" :fields="fields" :busy="isLoading">
            <template v-slot:table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <template v-slot:cell(role)="data">
              <b-form-group>
                <b-form-radio-group v-model="data.item.role">
                  <b-form-radio value="user" @change="updateUser(data.item, 'user')">{{ $t("user.roleUser") }}</b-form-radio>
                  <b-form-radio value="admin" @change="updateUser(data.item, 'admin')">{{ $t("user.roleAdmin") }}</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </template>
            <template v-slot:cell(id)="data">
              <b-button class="btn-nc" data-toggle="modal" data-target="#conf-dialog" @click="showModal = true, selectedUser = data" :aria-label="$t('general.delete')">
                <i class="nc-delete mr-1"/>
                {{ $t("general.delete") }}
              </b-button>
            </template>
          </b-table>
        </b-row>
      </div>
      <b-modal v-if="selectedUser !== null" id="conf-dialog" v-model="showModal" @close="showModal = false" :title="$t('userManagement.delete')"  hide-footer>
        <b-container>
          <div class="ml-auto pl-3 mb-2">
            {{ $t("userManagement.deleteConfirmation") }}
          </div>
          <div class="ml-auto pl-3"> {{ $t("user.name") }}: <b>{{selectedUser.item.name}}</b></div>
          <div class="ml-auto pl-3"> {{ $t("user.email") }}: <b>{{selectedUser.item.email}}</b></div>
          <div class="d-flex mt-2">
            <b-button class="btn-nc m-1 ml-auto" @click=" showModal=!showModal, selectedUser = null">
              <i class="nc-close mr-1"/>
              {{ $t("general.cancel") }}
            </b-button>
            <b-button class="btn-nc mb-1 mt-1" @click=" showModal=!showModal, remove(selectedUser.item.id)">
              <i class="nc-delete-shade-dark mr-1 mb-1"/>
              {{ $t("general.remove") }}
            </b-button>
          </div>
        </b-container>
      </b-modal>
    </b-container>
  </div>
</template>
<script>
import axios from 'axios'
import TenantActivityCardSettings from '../components/TenantActivityCardSettings.vue'
export default {
  components: {
    TenantActivityCardSettings
  },
  data: function () {
    return {
      fields: [],
      users: [],
      isLoading: true,
      name: '',
      email: '',
      role: 'user',
      isAddEnabled: false,
      errMsg: null,
      invalidEmailErrMsg: null,
      invalidNameErrMsg: null,
      showModal: false,
      selectedUser: null
    }
  },
  mounted () {
    this.setFields()
    this.getUsers()
  },
  computed: {
    isValidEmail () {
      const emailRegex = /^(([\w-]+\.)+[\w-]+|([a-zA-Z]{1}|[\w-]{2,}))@((([0-1]?[0-9]{1,2}|2{1}[0-5]{2})\.([0-1]?[0-9]{1,2}|2{1}[0-5]{2})\.([0-1]?[0-9]{1,2}|2{1}[0-5]{2})\.([0-1]?[0-9]{1,2}|2{1}[0-5]{2})){1}|([a-zA-Z0-9_-]+[\w-]+\.)+[a-zA-Z]{2,4})$/gm
      return this.email.toLowerCase().match(emailRegex)
    },
    isValidName () {
      const nameRegex = /^([a-zæøå\s'`+\-ßàáâãäçèéêëìíîïðñòóôõöùúûüýÿþžšœ¿µ]*)$/gm
      return (this.name.length > 0) ? this.name.toLowerCase().match(nameRegex) : false
    }
  },
  watch: {
    '$i18n.locale': {
      handler () {
        this.setFields()
      }
    }
  },
  methods: {
    setFields () {
      this.fields = [{
        key: 'name',
        sortable: true,
        label: this.$t('user.name')
      }, {
        key: 'email',
        label: this.$t('user.email')
      }, {
        key: 'role',
        label: this.$t('user.role')
      }, {
        key: 'id',
        label: ''
      }]
    },
    getUsers () {
      axios.get('/api/users').then(response => {
        this.users = response.data
      }).catch(() => {
        this.errored = true
      }).finally(() => {
        this.isLoading = false
      })
    },
    clearAddForm () {
      this.name = ''
      this.email = ''
      this.role = ''
      this.isAddEnabled = false
    },
    addUser () {
      this.invalidEmailErrMsg = null
      this.invalidNameErrMsg = null
      if (this.isValidEmail && this.isValidName) {
        this.isAddEnabled = false
        this.isLoading = true
        axios.post('/api/users', {
          name: this.name,
          email: this.email,
          role: this.role
        }).then(() => {
          this.getUsers()
        }).catch(res => {
          this.errMsg = 'Could not create user ' + res.message
        }
        ).finally(() => {
          this.clearAddForm()
          this.isLoading = false
        })
      } else {
        if (!this.isValidEmail) this.invalidEmailErrMsg = this.$t('inputFeedbackMsg.emailInputFeedbackError')
        if (!this.isValidName) this.invalidNameErrMsg = this.$t('inputFeedbackMsg.invalidNameErrMsg')
      }
    },
    updateUser (user, role) {
      this.isLoading = true
      axios.put('/api/users/' + user.id, {
        name: user.name,
        email: user.email,
        role,
        oid: user.oid
      }).then(() => {
      }).finally(() => {
        this.getUsers()
      })
    },
    remove (id) {
      this.isLoading = true
      axios.delete('api/users/' + id).then(async () => {
        await this.getUsers()
      }).catch(res => {
        if (res.message === 'Request failed with status code 409') {
          this.errMsg = this.$t('userManagement.deleteInUse')
        } else {
          this.errMsg = this.$t('errMsg.deleteGenericErrorMessage') + res.message
        }
      }
      ).finally(() => {
        this.isLoading = false
      })
    },
    disableAlert (errorMsgType) {
      this.$emit('disable-alert')
      if (errorMsgType === 'invalidNameErrMsg') this.invalidNameErrMsg = null
      if (errorMsgType === 'invalidEmailErrMsg') this.invalidEmailErrMsg = null
    }
  }
}
</script>
