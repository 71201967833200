import * as Msal from '@azure/msal-browser'

export const applicationConfig = {
  clientid: process.env.VUE_APP_CLIENT_ID,
  authorityBase: 'https://login.microsoftonline.com/',
  user_read_scope: ['user.read', 'email', process.env.VUE_APP_AUTH_API_Scope],
  graphendpoint: 'https://graph.microsoft.com/v1.0/me'
}

export const loginObj = {
  scopes: applicationConfig.user_read_scope
}

/**
 * @returns new Msal instance
 * @param {String} tenant
 * @param {String} redirectUri
 * @param {String} logoutRedirectUri
 */
export const msalApp = (tenant = null, redirectUri = null, logoutRedirectUri = null) => {
  const myMSALObj = new Msal.PublicClientApplication({
    auth: {
      clientId: applicationConfig.clientid,
      authority: applicationConfig.authorityBase + tenant,
      redirectUri,
      postLogoutRedirectUri: logoutRedirectUri
    },
    cache: {
      cacheLocation: 'localStorage'
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message) => {
          if (process.env.VUE_APP_AUTH_LOGGING) {
            console.log(message)
          }
        },
        piiLoggingEnabled: false
      },
      windowHashTimeout: 60000,
      iframeHashTimeout: 6000,
      loadFrameTimeout: 0
    }
  }
  )
  myMSALObj.handleRedirectPromise().then(() => {
  }).catch((er2) => {
    console.error(JSON.stringify(er2))
  })
  return myMSALObj
}
