<template>
  <b-container>
    <div class="d-flex  justify-content-center">
      <b-spinner
        variant="secondary"
        style="width: 4rem; height: 4rem;"
        class="
        spinner
        mt-5"
        v-if="!isCalculated"
      />
    </div>
    <div
      class="d-flex justify-content-center flex-wrap"
      v-if="isCalculated"
    >
      <div>
        <TotalAreaDonut
          :statistics="totalStatistics[1]"
          :title="totalStatistics[0]"
        />
      </div>

        <div
          v-for="(statistics, areaName, index) in sortNumOfActivitiesofAreas"
          :key="index"
        >
          <statistic-visualizer
            :title=areaName
            :statistics=statistics[0]
            :filter="{selectedAreas: [statistics[1]], selectedAssignedTo: [] }"
          />
        </div>

      <!-- To stk tomme div slik at alle boksene sentreres, og at elementer venstrestilles selvom raden ikke er full -->
      <div
        style="min-width: 360px;"
        class="ml-1 mr-1 mb-1 mt-1"
      ></div>
      <div
        style="min-width: 360px;"
        class="ml-1 mr-1 mb-1 mt-1"
      ></div>
    </div>
  </b-container>
</template>

<script>
import StatisticVisualizer from './StatisticsVisualizer.vue'
import TotalAreaDonut from './TotalAreaDonut.vue'

export default {
  components: {
    StatisticVisualizer,
    TotalAreaDonut
  },
  data: function () {
    return {
      areaStatistics: {},
      totalStatistics: {},
      isCalculated: false
    }
  },
  props: {
    activities: { type: Array },
    areasProp: { type: Array }
  },
  computed: {
    sortNumOfActivitiesofAreas: function () {
      const areas = Object.keys(this.areaStatistics).map((key) => {
        return [key, this.areaStatistics[key]]
      })

      const areaStatistics = Object.assign(
        {},
        ...areas.map((area) => ({ [area[0]]: area[1] }))
      )
      return areaStatistics
    }
  },
  methods: {
    calculateStatistics (areas) {
      this.isCalculated = false
      const numOfStatuses = 6

      // totalStatistics vil være på formatet {'Alle områder', [0,0,0,0,0,0]}
      // Brukes til Donut
      let totalStatistics = this.initiateTotalArray(numOfStatuses)

      // areaStatistics vil være på formatet {'Område1' : [ [0,0,0,0,0,0], {description: xx, id: xx, name: xx} ], 'Område2' : [STATUS-ARRAY, AREA-OBJECT], ...}
      // Brukes til Statistic Visualizer
      let areaStatistics = this.initiateAreaArray(areas, numOfStatuses)

      ;[totalStatistics, areaStatistics] = this.countStatusesOfAllActivities(
        totalStatistics,
        areaStatistics
      )

      this.totalStatistics =
        this.getTotalArrayWithStatisticObject(totalStatistics)
      this.areaStatistics =
        this.getAreaArrayWithStatisticObjects(areaStatistics)

      this.isCalculated = true
    },
    initiateTotalArray (numOfStatuses) {
      const totalStatistics = {}
      this.areasProp.length === 0
        ? (totalStatistics[0] = 'dashboard.allAreas')
        : (totalStatistics[0] = 'dashboard.yourAreas')
      totalStatistics[1] = new Array(numOfStatuses).fill(0)
      return totalStatistics
    },
    initiateAreaArray (areas, numOfStatuses) {
      const areaStatistics = {}
      for (const area of areas) {
        areaStatistics[area.name] = [new Array(numOfStatuses).fill(0), area]
      }
      return areaStatistics
    },
    countStatusesOfAllActivities: function (totalArray, areaArray) {
      for (const activity of this.activities) {
        const statusCode = activity.assignedUser.statusCode
        totalArray[1] = this.countStatus(totalArray[1], statusCode)

        for (const area of activity.aboutProcessingActivity.areas) {
          if (this.checkIsYourArea(area.name, areaArray)) {
            areaArray[area.name][0] = this.countStatus(
              areaArray[area.name][0],
              statusCode
            )
          }
        }
      }
      return [totalArray, areaArray]
    },
    checkIsYourArea: function (areaName, yourAreas) {
      return areaName in yourAreas
    },
    countStatus: function (statusArray, statusCode) {
      statusArray[statusCode]++
      return statusArray
    },
    getAreaArrayWithStatisticObjects: function (areaArray) {
      for (const area in areaArray) {
        areaArray[area][0] = this.getStatisticObject(areaArray[area][0])
      }
      return areaArray
    },
    getTotalArrayWithStatisticObject: function (totalArray) {
      totalArray[1] = this.getStatisticObject(totalArray[1])
      return totalArray
    },
    getStatisticObject: function (statusArray) {
      const object = {
        noStatus: statusArray[0],
        completed: statusArray[1],
        notApproved: statusArray[2],
        pending: statusArray[3] + statusArray[4] + statusArray[5]
      }
      return object
    }
  },
  watch: {
    areasProp: function () {
      if (this.areasProp) {
        this.calculateStatistics(this.areasProp)
      }
    },
    activities: function () {
      if (this.areasProp) {
        this.calculateStatistics(this.areasProp)
      }
    }
  }
}
</script>

<style>
</style>
