<template>
  <b-row class="m-0">
    <b-col class="activity-box">
      <b-form-group class="activity-box-part">
        <heading-and-label
          :editMode="editMode"
          labelFor="routine-guidelines"
          :level="3"
        >
          16. {{ $t('activity.routinesAndApprovalTab.relevantGuidelinesForProcessingActivities') }}
        </heading-and-label>
        <text-area-input
          id="routine-guidelines"
          class="mb-2"
          :editMode="editMode"
          :emptyDescription="$t('general.emptydata')"
          :placeholder="$t('activity.routinesAndApprovalTab.relevantGuidelinesForProcessingActivities')"
          :rows="5"
          :value="myRoutineAndBestPractices.relevantGuidelinesForProcessingActivities"
          @update-text="updateRelevantGuidelinesForProcessingActivities"
        />
      </b-form-group>
      <b-form-group class="activity-box-part">
        <heading-and-label
          labelFor="dpia-evaluation"
          :level="3"
        >
          <HelptextPopup
            target="Q17"
            :helptext="$t('helptext.q17')"
          />
          17. {{ $t('activity.routinesAndApprovalTab.dpiaEvaluationInformation') }}
        </heading-and-label>
        <heading-and-label
          labelFor="dpia-status"
          :level="3"
        >
          {{ $t('activity.routinesAndApprovalTab.dpiaStatus')}}
          <b-button
            :activityId="this.activityId"
            class="activity-button-save mr-1 btn-nc mt-3"
            v-if="(this.isApproved || this.routineAndBestPractices.dpiaEvaluationInformation.dpiaEvaluationStatus === 1 || this.routineAndBestPractices.dpiaEvaluationInformation.dpiaEvaluationStatus === 2) && !this.editMode"
            @click="editDpiaEvaluation(activityId)"
            id="goToEvaluationButton"
          >
            <i class="nc-arrow-right mr-1" />
            {{ $t('activity.routinesAndApprovalTab.goToEvaluation')}}
          </b-button>
        </heading-and-label>
        <div class="font-weight-bold">
          <p>
            {{ displayDpiaEvaluationStatus() }}
          </p>
        </div>
        <div v-if="this.myRoutineAndBestPractices.dpiaEvaluationInformation.conclusion != null ">
          <heading-and-label
            :editMode="editMode"
            labelFor="dpia-conclusion"
            :level="3"
          >
            {{ $t('activity.routinesAndApprovalTab.dpiaConclusion')}}
          </heading-and-label>
          <div class="d-flex flew-row">
            <p>
              <span class="font-weight-bold">
                {{ displayDpiaEvaluationConclusion() }}
              </span>
              <span v-if="myRoutineAndBestPractices.dpiaEvaluationInformation.hasBeenOverridden">
                {{ $t('dpia.conclusionOverridden')}}
              </span>
            </p>
          </div>
        </div>
        <dpia-modal
          ref="DpiaModal"
          :sentFromActivity="true"
          :activityId="activityId"
          @update-dpia-info-in-activity="updateDpiaInfo"
        >
        </dpia-modal>
      </b-form-group>
      <b-form-group class="activity-box-part">
        <HelptextPopup
          target="Q18"
          :helptext="$t('helptext.q18')"
        />
        <heading-and-label
          :editMode="editMode"
          labelFor="routine-guidelines"
          :level="3"
        >
          18. {{ $t('activity.routinesAndApprovalTab.highRisk') }}
        </heading-and-label>
        <div class="mb-2 d-flex flex-row">
          <div v-if="!editMode">
            <div v-if="myRoutineAndBestPractices.highRisk">
              <i class="nc-flag mr-2" />
            </div>
          </div>
          <yes-no-input
            :editMode="editMode"
            :value.sync="isHighRisk"
            :emptyDescription="$t('general.emptydata')"
            @update-yes-no="updateHighRisk"
          />
        </div>
      </b-form-group>
    </b-col>
    <b-col class="activity-box">
      <b-form-group class="activity-box-part">
        <heading-and-label
          :editMode="editMode"
          labelFor="routine-risk"
          :level="3"
        >
          19. {{ $t('activity.routinesAndApprovalTab.riskAndVulnerabilityAnalysis') }}
        </heading-and-label>
        <text-area-input
          id="routine-risk"
          class="mb-2"
          :editMode="editMode"
          :emptyDescription="$t('general.emptydata')"
          :placeholder="$t('activity.routinesAndApprovalTab.riskAndVulnerabilityAnalysis')"
          :rows="5"
          :value="myRoutineAndBestPractices.riskAndVulnerabilityAnalysis"
          @update-text="updateRiskAndVulnerabilityAnalysis"
        />
      </b-form-group>
      <b-form-group class="activity-box-part">
        <loading-overlay :isLoading="isLoadingCodes" />
        <h3>20. {{ $t('activity.routinesAndApprovalTab.approval') }}</h3>
        <p
          v-if="!this.isApproved"
          class="font-weight-bold pl-4 mt-4"
        >
          {{ $t('activity.routinesAndApprovalTab.isNotApproved')}}
        </p>
        <p
          v-if="this.isApproved"
          class="font-weight-bold pl-4 mt-4"
        >
          {{ $t('activity.routinesAndApprovalTab.lastApprovedByOn', {name: this.myAssignedTo.approvedBy.name, date: new Date(this.myAssignedTo.approvedTime).toISOString().slice(0, 10).replace("T", " ") }) }}
        </p>
      </b-form-group>
      <b-form-group class="activity-box-part">
        <heading-and-label
          :editMode="editMode"
          labelFor="comment"
          :level="3"
        >
          21. {{ $t('activity.routinesAndApprovalTab.comment') }}
        </heading-and-label>
        <text-area-input
          id="routinesAndApprovalComment"
          class="mb-2"
          :editMode="editMode"
          :emptyDescription="$t('general.emptydata')"
          :placeholder="$t('activity.routinesAndApprovalTab.comment')"
          :rows="5"
          :value="myRoutineAndBestPractices.routinesAndApprovalComment"
          @update-text="updateRoutinesAndApprovalComment"
        />
      </b-form-group>
    </b-col>
  </b-row>
</template>
<script>
import axios from 'axios'
import HeadingAndLabel from '../common/HeadingAndLabel'
import LoadingOverlay from '../common/LoadingOverlay'
import TextAreaInput from '../common/TextAreaInput'
import YesNoInput from '../common/YesNoInput'
import DpiaModal from '@/components/dpia/DpiaModal'
import HelptextPopup from '../processingActivity/HelptextPopup'

export default {
  name: 'routine-and-best-practices',
  components: {
    HeadingAndLabel,
    LoadingOverlay,
    TextAreaInput,
    YesNoInput,
    DpiaModal,
    HelptextPopup
  },
  data: function () {
    return {
      myRoutineAndBestPractices: {},
      selectedUsers: [],
      userOptions: [],
      isApproved: false,
      isLoadingCodes: false,
      isHighRisk: null
    }
  },
  props: {
    routineAndBestPractices: {
      type: Object,
      required: true
    },
    assignedUser: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean
    },
    activityId: {
      type: String
    }
  },
  created () {
    this.getOptions()
    this.setUp()
  },
  methods: {
    setUp () {
      this.myRoutineAndBestPractices = this.routineAndBestPractices
      this.myAssignedTo = this.assignedUser
      this.isHighRisk =
        this.routineAndBestPractices.highRisk != null
          ? this.routineAndBestPractices.highRisk
          : null
      this.updateIsApproved(this.myAssignedTo.statusCode)
      this.myRoutineAndBestPractices.dpiaEvaluationInformation = this
        .routineAndBestPractices.dpiaEvaluationInformation
        ? this.routineAndBestPractices.dpiaEvaluationInformation
        : {}
    },
    async getOptions () {
      this.isLoadingCodes = true
      axios
        .get('/api/users/admin')
        .then((response) => {
          this.userOptions = response.data
        })
        .catch((error) => {
          this.$emit(
            'error',
            this.$t('errMsg.getUsersToBeApprovedErrorMessage') +
              '\n' +
              error.message
          )
        })
        .finally(() => {
          this.isLoadingCodes = false
        })
    },
    updateRelevantGuidelinesForProcessingActivities (
      relevantGuidelinesForProcessingActivities
    ) {
      this.myRoutineAndBestPractices.relevantGuidelinesForProcessingActivities =
        relevantGuidelinesForProcessingActivities
    },
    updateRiskAndVulnerabilityAnalysis (riskAndVulnerabilityAnalysis) {
      this.myRoutineAndBestPractices.riskAndVulnerabilityAnalysis =
        riskAndVulnerabilityAnalysis
    },
    updateRoutinesAndApprovalComment (comment) {
      this.myRoutineAndBestPractices.routinesAndApprovalComment = comment
    },
    updateDpiaInfo () {
      axios
        .get('/api/processingActivities/' + this.activityId)
        .then((response) => {
          this.myRoutineAndBestPractices = response.data.routineAndBestPractices
        })
        .catch((e) => {
          this.errMsg =
            this.$t('errMsg.getGenericErrorMessage') + '\n' + e.message
        })
    },
    updateHighRisk (highRisk) {
      this.isHighRisk = highRisk
      this.myRoutineAndBestPractices.highRisk = highRisk
    },
    updateIsApproved (statusCode) {
      if (statusCode === 1) {
        this.isApproved = true
      } else this.isApproved = false
    },
    displayDpiaEvaluationStatus () {
      const status =
        this.myRoutineAndBestPractices.dpiaEvaluationInformation
          .dpiaEvaluationStatus
      if (status === 2) {
        return this.$t('activity.routinesAndApprovalTab.dpiaCompleted')
      } else if (status === 1) {
        return this.$t('activity.routinesAndApprovalTab.dpiaInProgress')
      } else {
        return this.$t('activity.routinesAndApprovalTab.dpiaNotStarted')
      }
    },
    displayDpiaEvaluationConclusion () {
      const conclusion =
        this.myRoutineAndBestPractices.dpiaEvaluationInformation.conclusion
      return conclusion
        ? this.$t('activity.routinesAndApprovalTab.dpiaNeeded')
        : this.$t('activity.routinesAndApprovalTab.dpiaNotNeeded')
    },
    editDpiaEvaluation (activityId) {
      axios
        .get('api/processingActivities/' + activityId)
        .then((response) => {
          this.evaluationId =
            response.data.routineAndBestPractices.dpiaEvaluationInformation.dpiaEvaluationId
        })
        .catch((e) => {
          this.errMsg =
            this.$t('errMsg.getGenericErrorMessage') + '\n' + e.message
        })
        .finally(() => {
          this.$refs.DpiaModal.toggle(this.evaluationId)
        })
    }
  },
  watch: {
    myRoutineAndBestPractices: {
      handler () {
        this.$emit(
          'update-routine-and-best-practices',
          this.myRoutineAndBestPractices
        )
      },
      deep: true
    },
    routineAndBestPractices: {
      handler () {
        this.setUp()
      }
    },
    editMode: {
      handler (newVal) {
        if (newVal) {
          this.updateIsApproved(false)
        }
      }
    }
  }
}
</script>
<style scoped lang="scss">
@import "../../styles/_variables.scss";

.nc-background {
  background-color: $background-color-2;
}
</style>
