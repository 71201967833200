import { render, staticRenderFns } from "./DashboardDataWrapper.vue?vue&type=template&id=69dbf8c9&"
import script from "./DashboardDataWrapper.vue?vue&type=script&lang=js&"
export * from "./DashboardDataWrapper.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports