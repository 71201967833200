<template>
  <b-container
    class="mt-4"
    role="main"
  >
    <!-- hardkodet bredde, potensiell risiko -->
    <div class="shadow-sm bg-white pl-4 pr-4 pt-1 mb-4 mt-1 yourAreasWidth">
      <div class="d-flex justify-content-between mt-1 mb-1">
        <h2 class="h2 pr-2 mt-1 mb-1 text-left">{{ $t("dashboard.yourAreas") }}</h2>
        <b-list-group-item>
          <b-button
            v-if="!editMode"
            class="btn-nc mr-2"
            @click="resetAreas()"
          >
            {{$t("dashboard.resetAreas")}}
            </b-button>
          <b-button
            v-if="!editMode"
            class="btn-nc"
            @click="editMode = true"
          >
            <i class="nc-edit-blue" />
            {{ $t("general.edit" )}}
          </b-button>
          <b-button
            v-if="editMode"
            class="m-1 btn-nc-primary ml-auto"
            @click="onSave()"
            @click.once="editMode = false"
          >
            <i class="nc-save mr-2 mb-1" />
            {{ $t('general.save') }}
          </b-button>
          <b-button
            v-if="editMode"
            class="m-1 btn-nc"
            @click="editMode = false ; onCancel()"
          >
            <i class="nc-close mr-2 mb-1" />
            {{ $t("general.cancel") }}
          </b-button>
        </b-list-group-item>
      </div>
      <div class="d-flex">
        <change-areas
          @change-areas="changeAreas"
          :editMode="editMode"
          :user="user"
          :selectedAreasProp="areas"
          :isLoading="isLoading"
          ref="changeAreas"
        />
      </div>
    </div>

  </b-container>

</template>

<script>
import ChangeAreas from './ChangeAreas.vue'
import axios from 'axios'
export default {
  components: {
    ChangeAreas
  },
  data: function () {
    return {
      editMode: false,
      areas: this.areasProp,
      user: {
        id: this.$route.meta.user.id,
        name: this.$route.meta.user.name,
        email: this.$route.meta.user.email,
        role: this.$route.meta.user.role,
        oid: this.$route.meta.user.oid
      }
    }
  },
  props: {
    filter: { type: Object },
    callback: { type: Function },
    areasProp: { type: Array },
    isLoading: { type: Boolean }
  },
  mounted: function () {},
  methods: {
    async onSave () {
      axios
        .put('/api/users/' + this.user.id, {
          name: this.user.name,
          email: this.user.email,
          role: this.user.role,
          oid: this.user.oid,
          areas: this.areas
        })
        .then((res) => {
          if (res.status === 200) {
            this.callback({
              areaIds: this.areas.map((area) => area.id)
            })
          }
        })
        .finally(() => {
          this.$emit('on-save', this.areas)
          this.$emit('on-reset')
        })
        .catch(() => {
          this.areasErrMsg = this.$t('errMsg.getGenericErrorMessage')
        })
    },
    onCancel () {
      this.$emit('on-cancel')
    },
    changeAreas (areas) {
      this.areas = areas
    },
    resetAreas () {
      if (this.areas.length > 0) {
        this.areas = []
        this.onSave()
      }
    }
  },
  watch: {
    areasProp: function () {
      this.$refs.changeAreas.updateAreas(this.areasProp)
    }
  }
}
</script>

<style>
.yourAreasWidth{
  width: 1100px
}
@media only screen and (max-width: 1200px) {
  .yourAreasWidth {
    width: 725px
  }
}
@media only screen and (max-width: 990px) {
  .yourAreasWidth {
    width: 360px
  }
}
</style>
