import Vue from 'vue'
import VueLocalStorage from 'vue-localstorage'

Vue.use(VueLocalStorage)

export default {
  namespaced: true,
  state: {
    user: Vue.localStorage.get('user')
  },
  mutations: {
    SET_USER (state, user) {
      Vue.localStorage.set('user', user, (user && user.exp) ? user.exp : 0)
      state.user = user
    },
    REMOVE_USER () {
      localStorage.clear()
    }
  },
  actions: {
    setUser ({ commit }, user) {
      if (typeof user === 'string') {
        commit('SET_USER', user)
      }
    },
    removeUser ({ commit }) {
      commit('REMOVE_USER')
    }
  }
}
