<template>
  <div class="d-flex justify-content-center">
    <b-modal
      v-model="show"
      size="xl"
      hide-footer
      centered
      body-bg-variant="light"
      content-class="shadow"
      >
      <template #modal-title>
        <div>
          <h2>{{ $t('dashboard.warning') }}</h2>
        </div>
      </template>
      <div class="d-flex justify-content-center" style="text-align: center">
        <h1>{{message}}<b>{{messageEmphasis}}</b>.</h1>
      </div>
      <div class="d-flex justify-content-center mt-5 mb-2">
        <b-button v-b-modal.modal-close_visit size="lg" class="btn  px-5 ml-5" @click="show=false">{{ $t('general.close') }}</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: 'messageModal',
  data: function () {
    return {
      show: false,
      message: '',
      messageEmphasis: ''
    }
  },
  methods: {
    showMessage (message, messageEmphasis) {
      this.show = true
      this.message = message
      this.messageEmphasis = messageEmphasis
    }
  }
}
</script>
