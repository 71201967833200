<template>
  <div
    class="shadow-sm bg-white pl-5 pr-5 pb-4 pt-4 mt-4"
    role="region"
    aria-label="Filter"
  >
    <b-row>
      <b-col class="col-sm-3">
        <h2 class="h2 filter-header">{{ $t('filter.filter') }}</h2>
      </b-col>
      <b-col class="col-sm-9">
        <div class="float-right">
          <b-button
            v-on:click="onReset()"
            class="btn-nc"
          >{{ $t('filter.reset') }}</b-button>
        </div>
      </b-col>
    </b-row>
    <!-- Area & category of data subjects -->
    <fieldset class="mt-4 mb-4">
      <label
        class="form-label"
        for="area-multiselect"
      >{{ $t('filter.area') }}</label>
      <multi-select-input
        v-if="!this.areasloading && !this.areasErrMsg && filterOptions.areas"
        id="area-multiselect"
        class="area-multiselect"
        :options="filterOptions.areas"
        :selectedOptions="filterOptions.selectedAreas"
        :emptyDescription="$t('general.emptydata')"
        :placeholder="$t('filter.area')"
        :editMode="true"
        @update-select="updateAreas"
       />
      <b-spinner
        variant="secondary"
        class="spinner"
        v-else-if="this.areasloading && !this.areasErrMsg"
      />
      <p
        v-else-if="!this.areasloading && !filterOptions.areas && !this.areasErrMsg"
        class="text-muted text-center"
      >{{ $t("general.noDataAvailable") }}</p>
      <b-alert
        variant="danger"
        dismissible
        :show="this.areasErrMsg !== undefined && this.areasErrMsg !== null && !this.areasloading"
        @dismissed="this.areasErrMsg = null"
      >{{ areasErrMsg }}</b-alert>
    </fieldset>
    <fieldset class="mt-4 mb-4">
      <label
        class="form-label"
        for="category-multiselect"
      >{{ $t('filter.categoryOfDataSubjects') }}</label>
      <multi-select-input
        v-if="!this.categoriesLoading && filterOptions.categoriesOfDataSubjects"
        id="category-multiselect"
        class="category-multiselect"
        :options="filterOptions.categoriesOfDataSubjects"
        :selectedOptions="filterOptions.selectedCategoriesOfDataSubjects"
        :emptyDescription="$t('general.emptydata')"
        :placeholder="$t('filter.categoryOfDataSubjects')"
        :editMode="true"
        @update-select="updateCategoriesOfDataSubjects"
       />
      <b-spinner
        variant="secondary"
        class="spinner"
        v-else-if="this.categoriesLoading && !this.categoriesErrMsg"
      />
      <p
        v-else-if="!this.categoriesLoading && !filterOptions.categoriesOfDataSubjects && !this.categoriesErrMsg"
        class="text-muted text-center"
      >{{ $t("general.noDataAvailable") }}</p>
      <b-alert
        variant="danger"
        dismissible
        :show="categoriesErrMsg !== undefined && categoriesErrMsg !== null && !this.categoriesLoading"
        @dismissed="this.categoriesErrMsg = null"
      >
        {{ categoriesErrMsg }}
      </b-alert>
    </fieldset>
    <!-- Assigned to -->
    <fieldset class="mt-4 mb-4">
      <label
        class="form-label"
        for="assignedTo-multiselect"
      >{{ $t('filter.assignedTo') }}</label>
      <multi-select-input
        v-if="!this.assignedToLoading && !this.assignedToErrMsg && filterOptions.assignedTo"
        id="assignedTo-multiselect"
        class="bm-2 color-multiselect"
        :options="filterOptions.assignedTo"
        :selectedOptions="filterOptions.selectedAssignedTo"
        :emptyDescription="$t('general.emptydata')"
        :placeholder="$t('filter.assignedTo')"
        :editMode="true"
        @update-select="updateAssignedTo"
       />
      <b-spinner
        variant="secondary"
        class="spinner"
        v-else-if="this.assignedToLoading && !this.assignedToErrMsg"
      />
      <p
        v-else-if="!this.assignedToLoading && !filterOptions.assignedTo && !this.assignedToErrMsg"
        class="text-muted text-center"
      >{{ $t("general.noDataAvailable") }}</p>
      <b-alert
        variant="danger"
        dismissible
        :show="this.assignedToErrMsg !== undefined && this.assignedToErrMsg !== null && !this.assignedToLoading"
        @dismissed="this.assignedToErrMsg = null"
      >{{ assignedToErrMsg }}</b-alert>
    </fieldset>
    <!-- Accordion Status -->
    <fieldset class="fieldset-accordion-style">
      <legend>
        <label
          class="h3 accordion-heading"
          for="toggle1"
        >
          {{ $t('filter.status') }}
          <i :class="{arrowTurned: expandedFilters.status, arrowNotTurned: !expandedFilters.status}" class="nc-arrow-blue filterArrow" />
        </label>
      </legend>
      <input
        type="checkbox"
        id="toggle1"
        checked=""
        hidden=""
        @click="expandedFilters.status  = !expandedFilters.status"
      />
      <div id="content1">
        <b-form-checkbox
          id="checkbox-approved"
          size="sm"
          v-model="filterOptions.approved"
          name="checkbox-approved"
        >
          {{ $t('filter.approved') }}
        </b-form-checkbox>
        <b-form-checkbox
          id="checkbox-notApproved"
          size="sm"
          v-model="filterOptions.notApproved"
          name="checkbox-notApproved"
        >
          {{ $t('filter.notApproved') }}
        </b-form-checkbox>
        <b-form-checkbox
          id="checkbox-workInProgress"
          size="sm"
          v-model="filterOptions.workInProgress"
          name="checkbox-workInProgress"
        >
          {{ $t('filter.workInProgress') }}
        </b-form-checkbox>
        <b-form-checkbox
          id="checkbox-readyForReview"
          size="sm"
          v-model="filterOptions.readyForReview"
          name="checkbox-readyForReview"
        >
          {{ $t('filter.readyForReview') }}
        </b-form-checkbox>
        <b-form-checkbox
          id="checkbox-needsFollowUp"
          size="sm"
          v-model="filterOptions.needsFollowUp"
          name="checkbox-needsFollowUp"
        >
          {{ $t('filter.needsFollowUp') }}
        </b-form-checkbox>
        <b-form-checkbox
          id="checkbox-unselected"
          size="sm"
          v-model="filterOptions.unselected"
          name="checkbox-unselected"
        >
          {{ $t('filter.unselected') }}
        </b-form-checkbox>
      </div>
    </fieldset>
    <!-- Accordion Dataprocessor -->
    <fieldset class="fieldset-accordion-style">
      <legend>
        <label
          class="h3 accordion-heading"
          for="toggle2"
        >
          {{ $t('filter.dataProcessor') }}
          <i :class="{arrowTurned: expandedFilters.dataProcessors, arrowNotTurned: !expandedFilters.dataProcessors}" class="nc-arrow-blue filterArrow" />
        </label>
      </legend>
      <input
        type="checkbox"
        id="toggle2"
        checked=""
        hidden=""
        @click="expandedFilters.dataProcessors  = !expandedFilters.dataProcessors"
      />
      <div id="content2">
        <b-form-checkbox
          id="checkbox-dataProcessorInvolved"
          size="sm"
          v-model="filterOptions.dataProcessorInvolved"
          name="checkbox-dataProcessorInvolved"
        >
          {{ $t('filter.dataProcessorInvolved') }}
        </b-form-checkbox>
        <b-form-checkbox
          id="checkbox-noDataProcessorInvolved"
          size="sm"
          v-model="filterOptions.noDataProcessorInvolved"
          name="checkbox-noDataProcessorInvolved"
        >
          {{ $t('filter.noDataProcessorInvolved') }}
        </b-form-checkbox>
      </div>
    </fieldset>
    <!-- Accordion EU/EEA -->
    <fieldset class="fieldset-accordion-style">
      <legend>
        <label
          class="h3 accordion-heading"
          for="toggle3"
        >
          {{ $t('filter.EUEEA') }}
          <i :class="{arrowTurned: expandedFilters.EUEEA, arrowNotTurned: !expandedFilters.EUEEA}" class="nc-arrow-blue filterArrow" />
        </label>
      </legend>
      <input
        type="checkbox"
        id="toggle3"
        checked=""
        hidden=""
        @click="expandedFilters.EUEEA  = !expandedFilters.EUEEA"
      />
      <div id="content3">
        <b-form-checkbox
          id="checkbox-internationalDataProcessor"
          size="sm"
          v-model="filterOptions.internationalDataProcessor"
          name="checkbox-internationalDataProcessor"
        >
          {{ $t('filter.internationalDataProcessor') }}
        </b-form-checkbox>
        <b-form-checkbox
          id="checkbox-noInternationalDataProcessor"
          size="sm"
          v-model="filterOptions.noInternationalDataProcessor"
          name="checkbox-noInternationalDataProcessor"
        >
          {{ $t('filter.noInternationalDataProcessor') }}
        </b-form-checkbox>
      </div>
    </fieldset>
    <!-- Accordion Internal register -->
    <fieldset class="fieldset-accordion-style">
      <legend>
        <label
          class="h3 accordion-heading"
          for="toggle4"
        >
          {{ $t('filter.internalRegister') }}
          <i :class="{arrowTurned: expandedFilters.register, arrowNotTurned: !expandedFilters.register}" class="nc-arrow-blue filterArrow" />
        </label>
      </legend>
      <input
        type="checkbox"
        id="toggle4"
        checked=""
        hidden=""
        @click="expandedFilters.register  = !expandedFilters.register"
      />
      <div id="content4">
        <b-form-checkbox
          id="checkbox-internalRegisterIsUsed"
          size="sm"
          v-model="filterOptions.internalRegister"
          name="checkbox-internalRegister"
        >
          {{ $t('filter.internalRegisterIsUsed') }}
        </b-form-checkbox>
        <b-form-checkbox
          id="checkbox-noInternalRegister"
          size="sm"
          v-model="filterOptions.noInternalRegister"
          name="checkbox-noInternalRegister"
        >
          {{ $t('filter.noInternalRegister') }}
        </b-form-checkbox>
      </div>
    </fieldset>
    <!-- Accordion Disclosure -->
    <fieldset class="fieldset-accordion-style">
      <legend>
        <label
          class="h3 accordion-heading"
          for="toggle5"
        >
          {{ $t('filter.disclosure') }}
          <i :class="{arrowTurned: expandedFilters.delivery, arrowNotTurned: !expandedFilters.delivery}" class="nc-arrow-blue filterArrow" />
        </label>
      </legend>
      <input
        type="checkbox"
        id="toggle5"
        checked=""
        hidden=""
        @click="expandedFilters.delivery  = !expandedFilters.delivery"
      />
      <div id="content5">
        <b-form-checkbox
          id="checkbox-providedToOthers"
          size="sm"
          v-model="filterOptions.providedToOthers"
          name="checkbox-providedToOthers"
        >
          {{ $t('filter.providedToOthers') }}
        </b-form-checkbox>
        <b-form-checkbox
          id="checkbox-notProvidedToOthers"
          size="sm"
          v-model="filterOptions.notProvidedToOthers"
          name="checkbox-notProvidedToOthers"
        >
          {{ $t('filter.notProvidedToOthers') }}
        </b-form-checkbox>
      </div>
    </fieldset>
    <!-- Accordion Purpose of sharing data -->
    <fieldset class="fieldset-accordion-style">
      <legend>
        <label
          class="h3 accordion-heading"
          for="toggle6"
        >
          {{ $t('filter.purposeOfSharingData') }}
          <i :class="{arrowTurned: expandedFilters.purposeOfSharingData, arrowNotTurned: !expandedFilters.purposeOfSharingData}" class="nc-arrow-blue filterArrow" />
        </label>
      </legend>
      <input
        type="checkbox"
        id="toggle6"
        checked=""
        hidden=""
        @click="expandedFilters.purposeOfSharingData  = !expandedFilters.purposeOfSharingData"
      />
      <div id="content6">
        <b-form-checkbox
          id="checkbox-legalObligation"
          size="sm"
          v-model="filterOptions.legalObligation"
          name="checkbox-legalObligation"
        >
          {{ $t('filter.legalObligation') }}
        </b-form-checkbox>
        <b-form-checkbox
          id="checkbox-reasonForTransfer"
          size="sm"
          v-model="filterOptions.reasonForTransfer"
          name="checkbox-reasonForTransfer"
        >
          {{ $t('filter.reasonForTransfer') }}
        </b-form-checkbox>
        <b-form-checkbox
          id="checkbox-consent"
          size="sm"
          v-model="filterOptions.consent"
          name="checkbox-consent"
        >
          {{ $t('filter.consent') }}
        </b-form-checkbox>
      </div>
    </fieldset>
    <!-- Accordion Status of DPIA -->
    <fieldset class="fieldset-accordion-style">
      <legend>
        <label
          class="h3 accordion-heading"
          for="toggle7"
        >
          {{ $t('filter.dpiaEvaluationStatus') }}
          <i :class="{arrowTurned: expandedFilters.dpiaEvaluationStatus, arrowNotTurned: !expandedFilters.dpiaEvaluationStatus}" class="nc-arrow-blue filterArrow" />
        </label>
      </legend>
      <input
        type="checkbox"
        id="toggle7"
        checked=""
        hidden=""
        @click="expandedFilters.dpiaEvaluationStatus  = !expandedFilters.dpiaEvaluationStatus"
      />
      <div id="content7">
        <b-form-checkbox
          id="checkbox-evaluationNotStarted"
          size="sm"
          v-model="filterOptions.evaluationNotStarted"
          name="checkbox-evaluationNotStarted"
        >
          {{ $t('filter.evaluationNotStarted') }}
        </b-form-checkbox>
        <b-form-checkbox
          id="checkbox-evaluationInProgress"
          size="sm"
          v-model="filterOptions.evaluationInProgress"
          name="checkbox-evaluationInProgress"
        >
          {{ $t('filter.evaluationInProgress') }}
        </b-form-checkbox>
        <b-form-checkbox
          id="checkbox-evaluationCompleted"
          size="sm"
          v-model="filterOptions.evaluationCompleted"
          name="checkbox-evaluationCompleted"
        >
          {{ $t('filter.evaluationCompleted') }}
        </b-form-checkbox>
      </div>
    </fieldset>
    <!-- Accordion DPIA evaluation-->
    <fieldset class="fieldset-accordion-style">
      <div>
        <legend>
          <label
            class="h3 accordion-heading"
            for="toggle8"
          >
            {{ $t('filter.evaluationResult') }}
            <i :class="{arrowTurned: expandedFilters.evaluationResult, arrowNotTurned: !expandedFilters.evaluationResult}" class="nc-arrow-blue filterArrow" />
          </label>
        </legend>
        <input
          type="checkbox"
          id="toggle8"
          checked=""
          hidden=""
          @click="expandedFilters.evaluationResult  = !expandedFilters.evaluationResult"
        />
        <div id="content8">
          <b-form-checkbox
            id="checkbox-dpiaNeeded"
            size="sm"
            v-model="filterOptions.dpiaNeeded"
            name="checkbox-dpiaNeeded"
          >
            {{ $t('filter.dpiaNeeded') }}
          </b-form-checkbox>
          <b-form-checkbox
            id="checkbox-dpiaNotNeeded"
            size="sm"
            v-model="filterOptions.dpiaNotNeeded"
            name="checkbox-dpiaNotNeeded"
          >
            {{ $t('filter.dpiaNotNeeded') }}
          </b-form-checkbox>
        </div>
      </div>
    </fieldset>
  </div>
</template>
<script>
import axios from 'axios'
import MultiSelectInput from './common/MultiSelectInput.vue'
export default {
  name: 'filter-component',
  components: {
    MultiSelectInput
  },
  props: {
    filter: {
      type: Object
    },
    setFilterFromStatusBoard: {
      type: Object
    }
  },
  data: function () {
    return {
      expandedFilters: {
        status: false,
        dataProcessors: false,
        EUEEA: false,
        register: false,
        delivery: false,
        purposeOfSharingData: false,
        dpiaEvaluationStatus: false,
        evaluationResult: false
      },
      areasloading: false,
      areasErrMsg: null,
      categoriesLoading: false,
      categoriesErrMsg: null,
      assignedToLoading: false,
      assignedToErrMsg: null,
      myFilter: {},
      noAssignedUser: {
        id: 'noUserAssigned',
        name: this.$t('filter.noUserAssigned')
      },
      filterOptions: {
        dataProcessorInvolved: false,
        noDataProcessorInvolved: false,
        internationalDataProcessor: false,
        noInternationalDataProcessor: false,
        internalRegister: false,
        noInternalRegister: false,
        providedToOthers: false,
        notProvidedToOthers: false,
        approved: false,
        notApproved: false,
        readyForReview: false,
        workInProgress: false,
        needsFollowUp: false,
        unselected: false,
        legalObligation: false,
        reasonForTransfer: false,
        consent: false,
        evaluationNotStarted: false,
        evaluationInProgress: false,
        evaluationCompleted: false,
        dpiaNeeded: false,
        dpiaNotNeeded: false,
        areas: [],
        categoriesOfDataSubjects: [],
        selectedCategoriesOfDataSubjects: [],
        selectedAreas: [],
        selectedAssignedTo: [],
        assignedTo: []
      }
    }
  },
  created () {
    this.markFilterComponent()
    this.myFilter = this.filter
    this.getAreas()
    this.getCategories()
    this.getUsers()
  },
  methods: {
    onReset: function () {
      this.myFilter = {}
      this.filterOptions = {
        dataProcessorInvolved: false,
        noDataProcessorInvolved: false,
        internationalDataProcessor: false,
        noInternationalDataProcessor: false,
        internalRegister: false,
        noInternalRegister: false,
        providedToOthers: false,
        notProvidedToOthers: false,
        approved: false,
        notApproved: false,
        readyForReview: false,
        workInProgress: false,
        needsFollowUp: false,
        unselected: false,
        legalObligation: false,
        reasonForTransfer: false,
        consent: false,
        evaluationNotStarted: false,
        evaluationInProgress: false,
        evaluationCompleted: false,
        dpiaNeeded: false,
        dpiaNotNeeded: false,
        selectedAssignedTo: [],
        selectedCategoriesOfDataSubjects: [],
        selectedAreas: [],
        assignedTo: this.filterOptions.assignedTo,
        areas: this.filterOptions.areas, // This is needed to remain current options
        categoriesOfDataSubjects: this.filterOptions.categoriesOfDataSubjects // This is needed to remain current options
      }
    },
    async getAreas () {
      this.areasloading = true
      axios
        .get('/api/areas')
        .then((res) => {
          if (res.status === 200) {
            this.filterOptions.areas = res.data
          }
        })
        .catch(() => {
          this.areasErrMsg = this.$t('errMsg.getGenericErrorMessage')
        })
        .finally(() => {
          this.areasloading = false
        })
    },
    async getCategories () {
      this.categoriesLoading = true
      axios
        .get('/api/categoriesOfDataSubjects')
        .then((res) => {
          if (res.status === 200) {
            this.filterOptions.categoriesOfDataSubjects = res.data
          }
          this.categoriesLoading = false
        })
        .catch(() => {
          this.categoriesErrMsg = this.$t('errMsg.getGenericErrorMessage')
        })
        .finally(() => {
          this.categoriesLoading = false
        })
    },
    async getUsers () {
      this.assignedToLoading = true
      axios
        .get('/api/Users')
        .then((res) => {
          if (res.status === 200) {
            this.filterOptions.assignedTo = res.data
            this.filterOptions.assignedTo.push(this.noAssignedUser)
          }
          this.assignedToLoading = false
        })
        .catch(() => {
          this.assignedToErrMsg = this.$t('errMsg.getGenericErrorMessage')
        })
        .finally(() => {
          this.assignedToLoading = false
        })
    },
    markFilterComponent () {
      if (Object.keys(this.setFilterFromStatusBoard).length > 0) {
        this.filterOptions.selectedAreas = this.setFilterFromStatusBoard.selectedAreas
        this.filterOptions.selectedAssignedTo = this.setFilterFromStatusBoard.selectedAssignedTo
        switch (Object.keys(this.setFilterFromStatusBoard)[0]) {
          case 'isApprovedStatusSelected':
            this.filterOptions.approved = true
            break
          case 'isWorkInProgressSelected':
            this.filterOptions.workInProgress = true
            this.filterOptions.readyForReview = true
            this.filterOptions.needsFollowUp = true
            break
          case 'isNotApprovedStatusSelected':
            this.filterOptions.notApproved = true
            break
          case 'isUnselectedStatusSelected':
            this.filterOptions.unselected = true
            break
          default:
            break
        }
      }
    },
    updateAreas (areas) {
      console.log(areas)
      this.filterOptions.selectedAreas = areas
    },
    updateCategoriesOfDataSubjects (subjects) {
      this.filterOptions.selectedCategoriesOfDataSubjects = subjects
    },
    updateAssignedTo (assignedTo) {
      this.filterOptions.selectedAssignedTo = assignedTo
    }
  },
  watch: {
    filterOptions: {
      handler () {
        this.myFilter = {
          isDataProcessorInvolvedSelected: this.filterOptions.dataProcessorInvolved,
          isNoDataProcessorInvolvedSelected:
            this.filterOptions.noDataProcessorInvolved,
          isDataProcessorWithinEuEeaSelected:
            this.filterOptions.internationalDataProcessor,
          isDataProcessorOutsideEuEeaSelected:
            this.filterOptions.noInternationalDataProcessor,
          isInternalRegisterIsUsedSelected: this.filterOptions.internalRegister,
          isInternalRegisterIsNotUsedSelected:
            this.filterOptions.noInternalRegister,
          isDataIsProvidedToOthersSelected: this.filterOptions.providedToOthers,
          isDataIsNotProvidedToOthersSelected:
            this.filterOptions.notProvidedToOthers,
          isApprovedStatusSelected: this.filterOptions.approved,
          isNotApprovedStatusSelected: this.filterOptions.notApproved,
          isWorkInProgressStatusSelected: this.filterOptions.workInProgress,
          isNeedsFollowUpStatusSelected: this.filterOptions.needsFollowUp,
          isReadyForReviewStatusSelected: this.filterOptions.readyForReview,
          isUnselectedStatusSelected: this.filterOptions.unselected,
          isReadyForReviewSelected: this.filterOptions.readyForReview,
          isWorkInProgressSelected: this.filterOptions.workInProgress,
          isNeedsFollowUpSelected: this.filterOptions.needsFollowUp,
          IsUnselectedStatusSelected: this.filterOptions.unselected,
          isLegalObligationSelected: this.filterOptions.legalObligation,
          isReasonForTransferSelected: this.filterOptions.reasonForTransfer,
          isConsentSelected: this.filterOptions.consent,
          isNotStartedSelected: this.filterOptions.evaluationNotStarted,
          isInProgressSelected: this.filterOptions.evaluationInProgress,
          isCompletedSelected: this.filterOptions.evaluationCompleted,
          isDpiaNeeded: this.filterOptions.dpiaNeeded,
          isDpiaNotNeeded: this.filterOptions.dpiaNotNeeded,
          areaIds: this.filterOptions.selectedAreas.length
            ? this.filterOptions.selectedAreas.map((area) => area.id)
            : null,
          categoryIds: this.filterOptions.selectedCategoriesOfDataSubjects
            .length
            ? this.filterOptions.selectedCategoriesOfDataSubjects.map(
              (category) => category.id
            )
            : null,
          assignedToId: this.filterOptions.selectedAssignedTo.length
            ? this.filterOptions.selectedAssignedTo.map(
              (assignedTo) => assignedTo.id
            )
            : null
        }

        this.$emit('update-filter', this.myFilter)
      },
      deep: true
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import "./../styles/_variables.scss";

.nav.sidenav>.container>.card.filter-card {
  padding-left: 10px;
  padding-right: 10px;
}

.filter-row-multiselect {
  padding: 10px;
  margin: 0px;
  width: 500px;
}

.filter-subtitle {
  font-size: 80%;
}

.filter-card {
  margin-bottom: 1rem;
}

.area-multiselect .multiselect__tag,
.area-multiselect .multiselect__option--highlight,
.area-multiselect .multiselect__option--highlight::after {
  color: $black;
  background-color: $nc-blue-4;
  border-radius: 0;
}

.category-multiselect .multiselect__tag,
.category-multiselect .multiselect__option--highlight,
.category-multiselect .multiselect__option--highlight::after {
  color: $black;
  background-color: $nc-green-4;
  border-radius: 0;
}

.col-12.col {
  padding: 0px 5px;
}

.filterArrow {
  transition: 0.25s;
}

.arrowTurned {
  rotate: 180deg;
}

.arrowNotTurned {
  rotate: 0deg;
}
</style>
