<template>
  <!-- Legg til i neste div:    v-on:click.ctrl="updateSelected" -->
  <div
    class="d-flex flex-wrap"
    :class="isInApprovalWarningModal ? '' : 'w-100'"
  >
    <div
      class="m-2 shadow-sm"
      :class="isMultiselectMode ? [isChecked ?  [getActivityCardColor(statusCode, isInApprovalWarningModal), 'checked-card'] : [getActivityCardColor(statusCode,isInApprovalWarningModal), 'not-checked-card']] : getActivityCardColor(statusCode, isInApprovalWarningModal)"
      @click="isMultiselectMode ? null : editActivity(activityId)"
    >
      <h2 class="h2 text-wrap text-left board-activity-title">{{ truncateTitle(title) }}</h2>
      <hr
        class="hr-activity"
        v-if="!isInApprovalWarningModal"
      />
      <div class="board-card-container">

        <div class="d-flex flex-column align-items-start">
          <div v-if="!isInApprovalWarningModal" class="d-flex flex-row">
            <b-avatar
              class="mr-1 badge-primary2"
              style="width: 15px; height: 15px"
            ></b-avatar>
            <p class="board-card-text mb-1">{{assignedUser != null ? assignedUser : $t("general.shortNoAssignedUser")}}</p>
          </div>
          <div class="d-flex justify-content-between w-100">
            <div
              class="d-flex flex-row"
              v-if="isDashboardActivity"
            >
              <b-icon
                :class="this.$parent.getActivityColor(statusCode)"
                name="icontDot"
              ></b-icon>
              <p class="mr-auto status-text">{{this.$parent.getStatusText(statusCode)}}</p>
            </div>
            <div
              class="d-flex flex-row"
              v-if="!isInApprovalWarningModal"
            >
              <i
                class="mr-1 nc-calender"
                name="iconCalender"
                style="width: 15px; height: 15px"
              />
              <p class="board-card-text pb-1 mb-0">{{ deadlineDate }}</p>
            </div>
            <div v-if="!isDashboardActivity && isMultiselectMode">
              <input
                class="checkBoxStyle"
                type="checkbox"
                id="checkbox"
                v-model="isChecked"
                @click="updateSelected"
              >
            </div>
          </div>
        </div>

        <div v-if="areas">
          <div class="d-flex flex-wrap">
            <div
              v-for="area in getAreas"
              v-bind:key="area.id"
            >
              <div
                class="badge badge-area"
                style="font-size: 13px"
              >
                {{ area.name }}
              </div>
            </div>
            <button
              class="badge badge-secondary"
              v-if="currentAreas.length > 3 && !displayAllAreas"
              @click.stop="displayAllAreas = true"
            >{{ $t('general.seeMore') }}</button>
            <button
              class="badge badge-secondary"
              v-if="currentAreas.length > 3 && displayAllAreas"
              @click.stop="displayAllAreas = false"
            >{{ $t('general.seeLess') }}</button>
          </div>
        </div>

        <div v-if="dataSubjects">
          <div class="d-flex flex-wrap">
            <div
              v-for="dataSubject in getDataSubjects"
              v-bind:key="dataSubject.id"
            >
              <div
                class="badge badge-category"
                style="font-size: 13px"
              >
                {{ dataSubject.name }}
              </div>
            </div>

            <button
              class="badge badge-secondary"
              v-if="currentDataSubjects.length > 3 && !displayAllDataSubjects"
              @click.stop="displayAllDataSubjects = true"
            >{{ $t('general.seeMore') }}</button>
            <button
              class="badge badge-secondary"
              v-if="currentDataSubjects.length > 3 && displayAllDataSubjects"
              @click.stop="displayAllDataSubjects = false"
            >{{ $t('general.seeLess') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sortByNameAlphabetically } from '../../shared/sorting'

export default {
  data: function () {
    return {
      currentDataSubjects: this.dataSubjects,
      displayAllDataSubjects: false,
      currentAreas: this.areas,
      displayAllAreas: false,
      isChecked: false
    }
  },
  props: {
    title: {
      type: String
    },
    assignedUser: {
      type: [String, null]
    },
    deadlineDate: {
      type: String
    },
    statusCode: {
      type: Number
    },
    activityId: {
      type: String
    },
    areas: {
      type: Array
    },
    dataSubjects: {
      type: Array
    },
    isAdmin: {
      type: Boolean
    },
    isDashboardActivity: {
      type: Boolean,
      default: false
    },
    isInApprovalWarningModal: {
      type: Boolean,
      default: false
    },
    isMultiselectMode: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.currentDataSubjects = this.dataSubjects
      ? [...this.dataSubjects].sort(sortByNameAlphabetically)
      : this.dataSubjects
    this.currentAreas = this.areas
      ? [...this.areas].sort(sortByNameAlphabetically)
      : this.areas
  },
  computed: {
    getDataSubjects () {
      return this.displayAllDataSubjects
        ? this.currentDataSubjects
        : this.currentDataSubjects.slice(0, 3)
    },
    getAreas () {
      return this.displayAllAreas
        ? this.currentAreas
        : this.currentAreas.slice(0, 3)
    }
  },
  methods: {
    editActivity: function (activityId) {
      this.$parent.editActivity(activityId)
    },
    getActivityCardColor (statusCode, isInApprovalWarningModal) {
      if (statusCode === 0 || !statusCode) {
        return isInApprovalWarningModal
          ? 'approval-warning-card-grey'
          : 'board-card-grey'
      }
      if (statusCode === 1) {
        return isInApprovalWarningModal
          ? 'approval-warning-card-green'
          : 'board-card-green'
      }
      if (statusCode === 2) {
        return isInApprovalWarningModal
          ? 'approval-warning-card-red'
          : 'board-card-red'
      }
      if ((statusCode === 3) | 4) {
        return isInApprovalWarningModal
          ? 'approval-warning-card-yellow'
          : 'board-card-yellow'
      }
      return isInApprovalWarningModal
        ? 'approval-warning-card-grey'
        : 'board-card-grey'
    },
    truncateTitle (title) {
      return title.length > 200 ? title.substring(0, 200) + '...' : title
    },
    updateSelected () {
      this.$emit('update-selected', this.statusCode, this.activityId)
    },
    setIsCheckedToFalse () {
      this.isChecked = false
    }
  },
  watch: {
    dataSubjects: {
      handler () {
        this.currentDataSubjects = [...this.dataSubjects].sort(sortByNameAlphabetically)
        this.displayAllDataSubjects = false
        this.currentAreas = [...this.areas].sort(sortByNameAlphabetically)
        this.displayAllAreas = false
      }
    }
  }
}
</script>
<style>
.checkBoxStyle {
  transform: scale(1.2);
}
</style>
