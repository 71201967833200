<template>
  <!-- The intention of this component is to provide the data of the activities, such that sub components does not have to do their own fetches, and update the subcomponents dynamically-->
  <div>
    <div class="d-flex justify-content-center">
      <div>
        <slot
          name="filter"
          v-bind:fetchActivitiesCallback="getProcessingActivities"
          v-bind:update-filter-options="updateFilterOptions"
          v-bind:changeSortCallback="changeSort">
        </slot>
      </div>

      <div>
        <slot
          name="dashboardTop"
          v-bind:statistics="this.statusStatistics"
          v-bind:activities="this.activities"
          v-bind:fetchActivitiesCallback="getProcessingActivities">
        </slot>
      </div>
    </div>

    <slot
      v-bind:fetchActivitiesCallback="getProcessingActivities"
      name="board"
      v-bind:changeSort="this.changeSort"
      v-bind:activities="this.sortedActivities"
      v-bind:isLoading="this.activitiesLoading">
    </slot>

    <slot
      name="statistics"
      v-bind:statistics="this.statusStatistics">
    </slot>
    <slot
      name="areas"
      v-bind:activities="this.activities">
    </slot>
  </div>
</template>

<script>
import axios from 'axios'
import queryString from 'query-string'

export default {
  name: 'dashboardDataWrapper',
  data: function () {
    return {
      activities: [],
      filter: this.inputFilter,
      activitiesLoading: false,
      filterOptions: {},
      sortingOptions: [],
      selectedSort: null

    }
  },
  created: function () {
    this.getProcessingActivities()
    this.setSortingOptions()
    this.selectedSort = this.sortingOptions[0].value
  },

  props: ['inputFilter'],
  methods: {
    getProcessingActivities: async function (customFilter) {
      this.activitiesLoading = true
      if (customFilter) {
        this.filter = customFilter
      }
      axios
        .get('api/processingActivities', {
          params: {
            ...this.filter
          },
          paramsSerializer: (params) => {
            return queryString.stringify(params, {
              arrayFormat: 'index',
              skipNull: true
            })
          }
        })
        .then((response) => {
          if (response.status === 200 && response.data) {
            this.activities = response.data
          }
        })
        .catch((e) => {
          console.error('CATCH: ', e)
        })
        .finally(() => {
          this.activitiesLoading = false
        })
    },
    updateFilterOptions (filter) {
      this.filterOptions = filter
      this.$emit('update-filter-options', this.filterOptions)
    },
    sortActivities: function (filter) {
      // ADVARSEL! Veldig hacky metode, oppdater så snart en annen løsning viser seg
      return filter.toString().includes('deadline')
        ? this.activities
          .slice()
          .filter((a) => a.assignedUser.deadline !== '0001-01-01T00:00:00')
          .sort(filter)
          .concat(
            this.activities
              .slice()
              .filter((a) => a.assignedUser.deadline === '0001-01-01T00:00:00')
          )
        : this.activities
          .slice()
          .sort(filter)
    },
    setSortingOptions () {
      this.sortingOptions = [
        {
          value: (a, b) =>
            new Date(a.assignedUser.deadline) -
            new Date(b.assignedUser.deadline),
          text: this.$t('sorting.byDeadline')
        },
        {
          value: (a, b) =>
            a.aboutProcessingActivity.title.localeCompare(
              b.aboutProcessingActivity.title, 'no'),
          text: this.$t('sorting.byTitleAZ')
        },
        {
          value: (a, b) =>
            new Date(b.createdDateTime) -
            new Date(a.createdDateTime),
          text: this.$t('sorting.byCreateDateNewest')
        },
        {
          value: (a, b) =>
            new Date(a.createdDateTime) -
            new Date(b.createdDateTime),
          text: this.$t('sorting.byCreateDateOldest')
        },
        {
          value: (a, b) =>
            new Date(b.modifiedDateTime) -
            new Date(a.modifiedDateTime),
          text: this.$t('sorting.byModifiedDateNewest')
        },
        {
          value: (a, b) =>
            new Date(a.modifiedDateTime) -
            new Date(b.modifiedDateTime),
          text: this.$t('sorting.byModifiedDateOldest')
        },
        {
          value: (a, b) =>
            b.routineAndBestPractices.highRisk -
            a.routineAndBestPractices.highRisk,
          text: this.$t('sorting.byRiskHighLow')
        },
        {
          value: (a, b) =>
            a.routineAndBestPractices.highRisk -
            b.routineAndBestPractices.highRisk,
          text: this.$t('sorting.byRiskLowHigh')
        }
      ]
    },
    changeSort: function (newSort) {
      this.selectedSort = newSort
    }
  },
  computed: {
    statusStatistics: function () {
      const numOfStatuses = 6
      const status = new Array(numOfStatuses).fill(0)
      if (this.activities === undefined) {
        return []
      }
      for (const activity of this.activities) {
        const statusCode = activity.assignedUser.statusCode
        status[statusCode]++
      }
      const statistics = {
        noStatus: status[0],
        completed: status[1],
        notApproved: status[2],
        pending: status[3] + status[4] + status[5] // todo, consider making these different categories
      }
      return statistics
    },
    sortedActivities: {
      get: function () {
        return this.sortActivities(this.selectedSort)
      },
      set: function (selectedSort) {
        return this.sortActivities(selectedSort)
      }
    }
  },
  watch: {
    '$i18n.locale': {
      handler () {
        this.setSortingOptions()
      }
    }
  }
}
</script>

<style>
</style>
