<template>
  <heading v-if="!editMode" :level="level">
    <slot />
  </heading>
  <label v-else :for="labelFor">
    <slot />
  </label>
</template>

<script>
import Heading from './Heading'

export default {
  name: 'heading-and-label',
  components: {
    Heading
  },
  props: {
    level: {
      type: Number,
      required: true
    },
    labelFor: {
      type: String
    },
    editMode: {
      type: Boolean
    }
  }
}
</script>
