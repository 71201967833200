import { render, staticRenderFns } from "./RoutineAndBestPractices.vue?vue&type=template&id=a73269a2&scoped=true&"
import script from "./RoutineAndBestPractices.vue?vue&type=script&lang=js&"
export * from "./RoutineAndBestPractices.vue?vue&type=script&lang=js&"
import style0 from "./RoutineAndBestPractices.vue?vue&type=style&index=0&id=a73269a2&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a73269a2",
  null
  
)

export default component.exports