<template>
  <div id="app">
    <navigation-bar></navigation-bar>
    <router-view/>
  </div>
</template>
<script>
import navigationBar from './components/Navigation.vue'

export default {
  components: {
    navigationBar
  }
}
</script>
