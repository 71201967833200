<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="d-flex flex-column">
    <div class="d-flex flex-row">
      <div
        v-if="!isAdmin"
        style="margin: 15px 0px 0px 40px;"
      >
        <b-button
          class="btn-nc btn-green"
          tabindex="0"
          v-on:click="createActivity()"
          v-on:keyup.enter="createActivity()"
        ><i class="nc-add-white mb-1 mr-1" />
          {{ $t("general.createNew" ) + ' ' + $t("general.processingActivity" ).toLowerCase()}}
        </b-button>
      </div>
      <div
        v-if="!isAdmin"
        style="margin: 15px 0px 0px 10px;"
      >
        <b-form-select
          v-model="selectedSort"
          :aria-label="$t('navigation.sortingoptions')"
          :options="sortingOptions"
          @change="$emit('change-sort', selectedSort)"
        >
        </b-form-select>
      </div>
      <div style="margin: 15px 0px 0px 10px;">
        <button
          class="multiselect-button"
          type="button "
          @click="setMultiselectMode"
        >
          {{isMultiselectMode ? $t("multiselectButton.cancel") : $t("multiselectButton.selectMore")}}
        </button>
      </div>
    </div>

    <div>
      <div class="d-flex justify-content-center position-sticky">
        <div class="table shadow mb-5 bg-white rounded">
          <div class="d-flex">
            <div
              v-for="statusKey in statusOptions"
              :key="statusKey.id"
              :id="'tableColum-'+statusKey.id"
              class="w-100 column"
              @drop="event => isMultiselectMode ? dropMultipleActivities(statusKey.id, event) : drop( statusKey.id, event)"
              @dragover="allowDrop"
              @dragenter="event => enteredDropzone(statusKey.id, event)"
              @dragleave="event => leftDropzone(statusKey.id, event)"
            >
              <div :class="isAdmin ? 'admin-board-table-header' : 'board-table-header'">
                {{statusKey.name.toUpperCase()}}
              </div>

              <div
                class="td"
                v-for="activity in allStatuses[statusKey.key]"
                :key="activity.id"
                draggable
                @dragstart="event => onDragging(statusKey.id, event)"
                :id="activity.id"
              >
                <board-activity-card
                  ref="activityCard"
                  @update-processing-activities="emitResetRequest()"
                  :assignedUser="activity.assignedUser.assignedTo.name"
                  :title="activity.aboutProcessingActivity.title"
                  :deadlineDate="getDeadline(activity.assignedUser.deadline)"
                  :statusCode="activity.assignedUser.statusCode"
                  :activityId="activity.id"
                  :isAdmin="isAdmin"
                  :isMultiselectMode="isMultiselectMode"
                  style="max-width: 14vw"
                  @update-selected="updateSelected"
                />
              </div>
            </div>
          </div>
          <div
            class="
                  h2
                  w-100
                  text-muted
                  text-center
                  mt-2"
            v-if="this.activities.length === 0 && !this.isLoading"
          >{{$t("dashboard.noActivitiesFound")}}
          </div>
          <div
            class="w-100 d-flex justify-content-center mt-2"
            v-if="this.activities.length === 0 && this.isLoading"
          >
            <b-spinner
              class="spinner"
              style="width: 5rem; height: 5rem;"
              variant="secondary"
            />
          </div>
        </div>
        <activity-modal
          v-if="!isMultiselectMode"
          @update-processing-activities="emitResetRequest()"
          ref="activityModal"
          :statusOptions="this.statusOptions"
        ></activity-modal>
      </div>

      <approval-warning-modal
        ref="approvalWarningModal"
        @confirm-approval-activity="putRequest"
        @reject-approval-activities="rejectApprovalActivities"
        @confirm-approval-multiselect="confirmApprovalMultiselect"
      ></approval-warning-modal>
      <message-modal ref="messageModal">
      </message-modal>
    </div>

    <!-- Snackbar -->
    <div class="d-flex justify-content-center">
      <b-alert
          v-if="snackbarColour === 'success'"
          class="snackbar"
          variant="success"
          :show="snackbarTimerEnd"
          @dismissed="snackbarTimerEnd=0"
          @dismiss-count-down="restartSnackbar">
        <p v-html="snackbarmsg"></p>
      </b-alert>

      <b-alert
        v-if="snackbarColour === 'warning'"
        class="snackbar"
        variant="warning"
        :show="snackbarTimerEnd"
        @dismissed="snackbarTimerEnd=0"
        @dismiss-count-down="restartSnackbar">
        <p v-html="snackbarmsg"></p>
      </b-alert>
    </div>
  </div>

</template>

<style scoped lang="scss">
@import "../../styles/_variables.scss";
.table {
  margin: 5px 40px 40px 40px;
}
.td {
  padding: 12px;
}

.column {
  border-right: 1px solid $nc-shade-brighter;
}

.show-front {
  z-index: 2;
}

.multiselect-button {
  background-color: $nav-blue-1;
  color: $white;
  position: fixed;
  right: 10px;
  bottom: 16px;
  max-width: 150px;
  z-index: 1;
  text-align: center;
  font-size: 1.2rem;
  border-radius: 45px;
  border: none;
  padding: 10px;
  box-shadow: 2px 10px 15px rgba(0, 0, 0, 0.5);

  &:hover {
    background-color: $nav-blue-2;
    color: $white;
  }
}

.snackbar {
  position: fixed;
  text-align: center;
  bottom: 10px;
  width: fit-content;
  z-index: 1;
  height: 50px;
}

</style>

<script>
import BoardActivityCard from './BoardActivityCard.vue'
import ActivityModal from '../processingActivity/ActivityModal.vue'
import axios from 'axios'
import ApprovalWarningModal from './ApprovalWarningModal.vue'
import MessageModal from '../common/MessageModal.vue'

export default {
  name: 'board',
  components: {
    BoardActivityCard,
    ActivityModal,
    ApprovalWarningModal,
    MessageModal
  },
  data: function () {
    return {
      snackbarTimerStart: 3,
      snackbarTimerEnd: 0,
      snackbarmsg: null,
      snackbarColour: null,

      selectedSort: null,
      approvalWarningActivity: null,
      acceptedStatusCode: 1,
      deniedStatusCode: 2,
      isMultiselectMode: false,
      selectedActivities: {},
      // neccessary as entering and leaving children objects in drag and drop triggers a enter/leave operation
      dragAndDropHoverTracker: new Array(6).fill(0),
      sortingOptions: []
    }
  },

  created: function () {
    this.setSortingOptions()
    this.selectedSort = this.sortingOptions[0].value
  },

  props: {
    activities: { type: Array },
    callback: { type: Function },
    isLoading: { type: Boolean },
    isAdmin: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    statusOptions: function () {
      return [
        {
          key: 'ingenStatus',
          name: this.$t('activity.activityStatus.statusNotSet'),
          id: 0
        },
        {
          key: 'underArbeid',
          name: this.$t('activity.activityStatus.statusUnderProgress'),
          id: 3
        },
        {
          key: 'trengerOppfolging',
          name: this.$t('activity.activityStatus.statusNeedsFollowUp'),
          id: 5
        },
        {
          key: 'tilGodkjenning',
          name: this.$t('activity.activityStatus.statusReadyForReview'),
          id: 4
        },
        {
          key: 'ikkeGodkjent',
          name: this.$t('activity.activityStatus.statusNotApproved'),
          id: 2
        },
        {
          key: 'godkjent',
          name: this.$t('activity.activityStatus.statusApproved'),
          id: 1
        }
      ]
    },
    allStatuses: function () {
      const statusObject = {
        ingenStatus: [],
        underArbeid: [],
        tilGodkjenning: [],
        trengerOppfolging: [],
        ikkeGodkjent: [],
        godkjent: []
      }

      const activityStatuses = [
        'ingenStatus',
        'godkjent',
        'ikkeGodkjent',
        'underArbeid',
        'tilGodkjenning',
        'trengerOppfolging'
      ]
      this.activities.forEach((activity) => {
        for (let i = 0; i < activityStatuses.length; i++) {
          if (activity.assignedUser.statusCode === i) {
            // assignes a temporary name
            if (activity.assignedUser.assignedTo == null) {
              activity.assignedUser.assignedTo = {
                name: null
              }
            }

            statusObject[activityStatuses[i]].push(activity)
          }
        }
      })
      return statusObject
    }
  },
  methods: {
    restartSnackbar (snackbarTimerEnd) {
      this.snackbarTimerEnd = snackbarTimerEnd
    },
    showAlert () {
      this.snackbarTimerEnd = this.snackbarTimerStart
    },
    emitResetRequest: function () {
      this.$emit('update-processing-activities')
    },
    getDeadline (date) {
      if (date === '0001-01-01T00:00:00') {
        return this.$t('general.shortNoDeadline')
      }
      return date.replace('T00:00:00', '')
    },
    // methods for drag-and-drop
    onDragging (status, ev) {
      ev.dataTransfer.setData('id', ev.target.id)
      ev.dataTransfer.setData('status', status)
      const user = this.$route.meta.user
      if (!user.isAdmin) {
        this.enableRestrictedColumnStyling(true, [
          this.acceptedStatusCode,
          this.deniedStatusCode
        ])
      }
    },
    allowDrop (ev) {
      ev.preventDefault()
    },
    rejectApprovalActivities () {
      this.activateSnackbar('warning', `<b>${this.$t('dashboard.snackbarMessageFailure')}</b>`)
    },
    confirmApprovalMultiselect (activitiesArray, statusCode) {
      for (const activity of activitiesArray) {
        this.putRequest(activity, statusCode, 0)
      }
      this.activateSnackbar('success', `<b>${activitiesArray.length}</b>
                                        ${this.$t('dashboard.snackbarMessageSuccessMultiple')}
                                        <b>${this.getStatusText(statusCode)}</b>`)
    },
    dropMultipleActivities (statusCode, ev) {
      ev.preventDefault()
      let activitiesSelected = []

      // neccessary to loop over for updating the stored activity without performing an extra GET request
      for (const activityId in this.selectedActivities) {
        for (let i = 0; i < this.activities.length; i++) {
          if (this.activities[i].id === activityId) {
            activitiesSelected.push(this.activities[i])
            break
          }
        }
      }

      activitiesSelected = activitiesSelected.filter(a => a.assignedUser.statusCode !== statusCode)
      if (activitiesSelected.length === 0) {
        this.activateSnackbar('warning', `<b>${this.$t('dashboard.snackbarMessageFailure')}</b>`)
      }

      const user = this.$route.meta.user
      this.dragAndDropHoverTracker = new Array(6).fill(0)
      this.resetColumnDragStyling()

      for (const activity of activitiesSelected) {
        if (statusCode !== activity.assignedUser.statusCode) {
          if (user.isAdmin) {
            if (statusCode === this.acceptedStatusCode) {
              this.$refs.approvalWarningModal.confirmArrayApproval(activitiesSelected)
            } else {
              this.putRequest(activity, statusCode, activitiesSelected.length)
            }
          } else {
            if (this.allowDropActionOrPopupWarning(statusCode)) {
              this.putRequest(activity, statusCode, activitiesSelected.length)
            }
          }
        }
      }
      this.setMultiselectMode()
    },
    drop (statusCode, ev) {
      try {
        ev.preventDefault()
        const data = ev.dataTransfer.getData('id')
        let selectedActivity = null

        // neccessary to loop over for updating the stored activity without performing an extra GET request
        for (let i = 0; i < this.activities.length; i++) {
          if (this.activities[i].id === data) {
            selectedActivity = this.activities[i]
            break
          }
        }

        const user = this.$route.meta.user
        this.dragAndDropHoverTracker = new Array(6).fill(0)
        this.resetColumnDragStyling()
        if (statusCode !== selectedActivity.assignedUser.statusCode) {
          if (user.isAdmin) {
            if (statusCode === this.acceptedStatusCode) {
              this.$refs.approvalWarningModal.confirmApproval(selectedActivity)
            } else {
              this.putRequest(selectedActivity, statusCode, 1)
            }
          } else {
            this.enableRestrictedColumnStyling(false, [this.acceptedStatusCode, this.deniedStatusCode])
            if (this.allowDropActionOrPopupWarning(statusCode)) {
              this.putRequest(selectedActivity, statusCode, 1)
            }
          }
        } else {
          this.activateSnackbar('warning', `<b>${this.$t('dashboard.snackbarMessageFailure')}</b>`)
        }
      } catch (e) {
        this.activateSnackbar('warning', `<b>${this.$t('dashboard.snackbarMessageError')}</b>`)
      }
    },

    activateSnackbar (snackbarColour, snackbarmsg) {
      this.snackbarColour = snackbarColour
      this.snackbarmsg = snackbarmsg
      this.showAlert()
    },
    allowDropActionOrPopupWarning (statusCode) {
      if (statusCode === this.acceptedStatusCode) {
        this.$refs.messageModal.showMessage(
          this.$t('dashboard.lackActivityRightsMsg'),
          this.$t('activity.activityStatus.statusApproved')
        )
        return false
      } else if (statusCode === this.deniedStatusCode) {
        this.$refs.messageModal.showMessage(
          this.$t('dashboard.lackActivityRightsMsg'),
          this.$t('activity.activityStatus.statusNotApproved')
        )
        return false
      }
      return true
    },

    putRequest (activity, status, requestAmount) {
      const fallbackStatus = activity.assignedUser.statusCode
      const fallbackDate = activity.modifiedDateTime
      activity.assignedUser.statusCode = status
      activity.modifiedDateTime = new Date()
      axios
        .put('api/processingActivities/UpdateStatus/' + activity.id, {
          statusCode: status
        })
        .catch(() => {
          // Assume that the status change goes through, as it improves performance
          activity.assignedUser.statusCode = fallbackStatus
          activity.modifiedDateTime = fallbackDate
        })

      if (requestAmount === 1) {
        this.activateSnackbar('success', `<b>${activity.aboutProcessingActivity.title}</b>
                                          ${this.$t('dashboard.snackbarMessageSuccess')}
                                          <b>${this.getStatusText(status)}</b>`)
      } else if (requestAmount > 1) {
        this.activateSnackbar('success', `<b>${requestAmount}</b>
                                          ${this.$t('dashboard.snackbarMessageSuccessMultiple')}
                                          <b>${this.getStatusText(status)}</b>`)
      }
    },
    enableRestrictedColumnStyling (enable, statusCodeColumnIds) {
      for (const id of statusCodeColumnIds) {
        if (enable) {
          document.getElementById('tableColum-' + id).style.backgroundColor =
            '#ebebeb'
        } else {
          document.getElementById('tableColum-' + id).style.backgroundColor = ''
        }
      }
    },

    // drag and drop styling
    enableLockedColumnStyle () {
      document.getElementById('tableColum-1').style.backgroundColor = '#ebebeb'
      document.getElementById('tableColum-2').style.backgroundColor = '#ebebeb'
    },
    resetColumnDragStyling () {
      for (const status of this.statusOptions) {
        document.getElementById(
          'tableColum-' + status.id
        ).style.backgroundColor = ''
      }
    },
    checkIsValidUpdate (columnStatusId) {
      if (this.$route.meta.user.isAdmin) return true
      else {
        if (
          columnStatusId === this.acceptedStatusCode ||
          columnStatusId === this.deniedStatusCode
        ) {
          return false
        }
      }
      return true
    },
    enteredDropzone (columnStatusId, event) {
      ++this.dragAndDropHoverTracker[columnStatusId]
      const draggedActivityStatusCode = parseInt(
        event.dataTransfer.getData('status')
      )
      if (this.checkIsValidUpdate(columnStatusId)) {
        this.updateSelectedColumnStyling(
          columnStatusId,
          draggedActivityStatusCode
        )
      }
    },
    leftDropzone (columnStatusId, event) {
      --this.dragAndDropHoverTracker[columnStatusId]
      const draggedActivityStatusCode = parseInt(
        event.dataTransfer.getData('status')
      )
      if (this.checkIsValidUpdate(columnStatusId)) {
        this.updateSelectedColumnStyling(
          columnStatusId,
          draggedActivityStatusCode
        )
      }
    },

    updateSelectedColumnStyling (columnStatusId, activityStatusId) {
      if (columnStatusId === activityStatusId) {
        // no styling for dragging an element to the column it was already in
        return
      }
      if (this.dragAndDropHoverTracker[columnStatusId] > 0) {
        document.getElementById(
          'tableColum-' + columnStatusId
        ).style.backgroundColor = '#dce5cf'
      } else {
        document.getElementById(
          'tableColum-' + columnStatusId
        ).style.backgroundColor = ''
      }
    },

    // Metoder for activity modalen:
    createActivity: function () {
      this.$refs.activityModal.toggle(null)
    },
    editActivity: function (activityId) {
      this.$refs.activityModal.toggle(activityId)
    },
    getActivityColor (statusCode) {
      if (statusCode === 0 || !statusCode) {
        return 'mr-2 mt-1 dot-grey'
      }
      if (statusCode === 1) {
        return 'mr-2 mt-1 dot-green'
      }
      if (statusCode === 2) {
        return 'mr-2 mt-1 dot-red'
      }
      if ((statusCode === 3) | 4) {
        return 'mr-2 mt-1 dot-yellow'
      }
      return 'mr-2 mt-1 dot-grey'
    },
    getStatusText (statusId) {
      return this.getStatusObject(statusId).name
    },
    getStatusObject (statusId) {
      if (!statusId) return this.statusOptions.find((obj) => obj.id === 0)
      if (statusId && (!statusId.id || !statusId.name)) {
        return this.statusOptions.find((obj) => obj.id === statusId)
      }
      return statusId
    },
    updateSelected (statuscode, activityId) {
      if (activityId in this.selectedActivities) {
        delete this.selectedActivities[activityId]
      } else {
        this.selectedActivities[activityId] = statuscode
      }
    },
    setMultiselectMode () {
      this.isMultiselectMode = !this.isMultiselectMode
      if (this.isMultiselectMode) {
        if (!this.$route.meta.user.isAdmin) {
          this.enableRestrictedColumnStyling(true, [
            this.acceptedStatusCode,
            this.deniedStatusCode
          ])
        }
      } else {
        this.enableRestrictedColumnStyling(false, [
          this.acceptedStatusCode,
          this.deniedStatusCode
        ])
        for (let i = 0; i < this.activities.length; i++) {
          if (this.$refs.activityCard[i]) {
            this.$refs.activityCard[i].setIsCheckedToFalse()
          }
        }
        this.selectedActivities = {}
      }
    },

    setSortingOptions () {
      this.sortingOptions = [
        {
          value: (a, b) =>
            new Date(a.assignedUser.deadline) -
            new Date(b.assignedUser.deadline),
          text: this.$t('sorting.byDeadline')
        },
        {
          value: (a, b) =>
            a.aboutProcessingActivity.title.localeCompare(
              b.aboutProcessingActivity.title, 'no'
            ),
          text: this.$t('sorting.byTitleAZ')
        },
        {
          value: (a, b) =>
            new Date(b.createdDateTime) - new Date(a.createdDateTime),
          text: this.$t('sorting.byCreateDateNewest')
        },
        {
          value: (a, b) =>
            new Date(a.createdDateTime) - new Date(b.createdDateTime),
          text: this.$t('sorting.byCreateDateOldest')
        },
        {
          value: (a, b) =>
            new Date(b.modifiedDateTime) - new Date(a.modifiedDateTime),
          text: this.$t('sorting.byModifiedDateNewest')
        },
        {
          value: (a, b) =>
            new Date(a.modifiedDateTime) - new Date(b.modifiedDateTime),
          text: this.$t('sorting.byModifiedDateOldest')
        },
        {
          value: (a, b) =>
            b.routineAndBestPractices.highRisk -
            a.routineAndBestPractices.highRisk,
          text: this.$t('sorting.byRiskHighLow')
        },
        {
          value: (a, b) =>
            a.routineAndBestPractices.highRisk -
            b.routineAndBestPractices.highRisk,
          text: this.$t('sorting.byRiskLowHigh')
        }
      ]
    }
  },

  watch: {
    boardFilter: {
      handler () {
        this.filter = this.boardFilter
        this.$emit('update-filter', this.boardFilter)
      },
      deep: true
    },

    '$i18n.locale': {
      handler () {
        this.setSortingOptions()
      }
    }
  }
}
</script>

<style scoped>
</style>
