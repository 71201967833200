<template>
  <div>
    <div
      class="activity-box-part"
      v-if="!sentFromExistingEvaluation"
    >
      <div v-if="!sentFromActivity">
        <loading-overlay :isLoading="isLoadingActivities" />
        <heading-and-label
          :editMode="editMode"
          labelFor="choosing-activity-for-dpia"
          :level="3"
        >
          <span class="required">* </span> {{ $t('dpia.chooseActivity') }}
        </heading-and-label>
        <b-alert
          dismissible
          :show="approvedActivityWithoutDpiaOptions.length === 0"
          variant="danger"
          @dismissed="disableAlert"
        >{{ $t('dpia.noAvailableActivities') }}</b-alert>
        <multiselect
          id="choosing-activity-for-dpia"
          :editMode="editMode"
          :emptyDescription="$t('general.emptydata')"
          :options="approvedActivityWithoutDpiaOptions"
          :placeholder="$t('dpia.selectActivityFromList')"
          :allow-empty="true"
          :close-on-select="true"
          :clear-on-select="false"
          v-model="selectedActivity"
          track-by="id"
          label="aboutProcessingActivity"
          :custom-label="customLabel"
          @input="updateSelectedActivityForEvaluation"
        />
      </div>
      <div v-else>
        <div>{{ $t('dpia.chosenActivity') }}: <span class="multiline m-0 font-weight-bold">{{ evaluation.name }}</span></div>
      </div>
    </div>
    <div v-if="activityChosen || sentFromExistingEvaluation || sentFromActivity">
      <div class="activity-box-part">
        <heading-and-label
          :editMode="editMode"
          labelFor="dpia-comment-scoringOfPeople"
          :level="3"
        >
          <span class="required">* </span>1. {{ $t('dpia.evaluationQuestions.scoringOfPeople') }}

        </heading-and-label>
        <HelptextPopup
          target="DpiaQ1"
          :helptext="$t('dpia.evaluationQuestions.scoringOfPeopleHelptext')"
        />
        <yes-no-input
          :editMode="editMode"
          :value.sync="scoringOfPeopleAnswer"
          :emptyDescription="$t('general.emptydata')"
          @update-yes-no="updateScoringOfPeopleAnswer"
        />
        <text-area-input-comment
          id="dpia-comment-scoringOfPeople"
          class="mb-2"
          :editMode="editMode"
          :placeholder="$t('dpia.comment')"
          :rows="2"
          :value="scoringOfPeopleComment"
          @update-text="updateScoringOfPeopleComment"
          label="$t('dpia.comment')"
        />
      </div>
      <div class="activity-box-part">
        <heading-and-label
          :editMode="editMode"
          labelFor="dpia-comment-automaticDecisions"
          :level="3"
        >
          <span class="required">* </span>2. {{ $t('dpia.evaluationQuestions.automaticDecisions') }}
        </heading-and-label>
        <HelptextPopup
          target="DpiaQ2"
          :helptext="$t('dpia.evaluationQuestions.automaticDecisionsHelptext')"
        />
        <yes-no-input
          :editMode="editMode"
          :value.sync="automaticDecisionsAnswer"
          :emptyDescription="$t('general.emptydata')"
          @update-yes-no="updateAutomaticDecisionsAnswer"
        />
        <text-area-input-comment
          id="dpia-comment-automaticDecisions"
          class="mb-2"
          :editMode="editMode"
          :placeholder="$t('dpia.comment')"
          :rows="2"
          :value="automaticDecisionsComment"
          @update-text="updateAutomaticDecisionsComment"
        />
      </div>
      <div class="activity-box-part">
        <heading-and-label
          :editMode="editMode"
          labelFor="dpia-comment-largeScaleMonitoring"
          :level="3"
        ><span class="required">* </span>3. {{ $t('dpia.evaluationQuestions.largeScaleMonitoring') }}</heading-and-label>
        <HelptextPopup
          target="DpiaQ3"
          :helptext="$t('dpia.evaluationQuestions.largeScaleMonitoringHelptext')"
        />
        <yes-no-input
          :editMode="editMode"
          :value.sync="largeScaleMonitoringAnswer"
          :emptyDescription="$t('general.emptydata')"
          @update-yes-no="updateLargeScaleMonitoringAnswer"
        />
        <text-area-input-comment
          id="dpia-comment-largeScaleMonitoring"
          class="mb-2"
          :editMode="editMode"
          :placeholder="$t('dpia.comment')"
          :rows="2"
          :value="largeScaleMonitoringComment"
          @update-text="updateLargeScaleMonitoringComment"
        />
      </div>
      <div class="activity-box-part">
        <heading-and-label
          :editMode="editMode"
          labelFor="dpia-comment-sensitiveInformation"
          :level="3"
        ><span class="required">* </span>4. {{ $t('dpia.evaluationQuestions.sensitiveInformation') }}</heading-and-label>
        <HelptextPopup
          target="DpiaQ4"
          :helptext="$t('dpia.evaluationQuestions.sensitiveInformationHelptext')"
        />
        <yes-no-input
          :editMode="editMode"
          :value.sync="sensitiveInformationAnswer"
          :emptyDescription="$t('general.emptydata')"
          @update-yes-no="updateSensitiveInformationAnswer"
        />
        <text-area-input-comment
          id="dpia-comment-sensitiveInformation"
          class="mb-2"
          :editMode="editMode"
          :placeholder="$t('dpia.comment')"
          :rows="2"
          :value="sensitiveInformationComment"
          @update-text="updateSensitiveInformationComment"
        />
      </div>
      <div class="activity-box-part">
        <heading-and-label
          :editMode="editMode"
          labelFor="dpia-comment-largeScaleProcessing"
          :level="3"
        ><span class="required">* </span>5. {{ $t('dpia.evaluationQuestions.largeScaleProcessing') }}</heading-and-label>
        <HelptextPopup
          target="DpiaQ5"
          :helptext="$t('dpia.evaluationQuestions.largeScaleProcessingHelptext')"
        />
        <yes-no-input
          :editMode="editMode"
          :value.sync="largeScaleProcessingAnswer"
          :emptyDescription="$t('general.emptydata')"
          @update-yes-no="updateLargeScaleProcessingAnswer"
        />
        <text-area-input-comment
          id="dpia-comment-largeScaleProcessing"
          class="mb-2"
          :editMode="editMode"
          :placeholder="$t('dpia.comment')"
          :rows="2"
          :value="largeScaleProcessingComment"
          @update-text="updateLargeScaleProcessingComment"
        />
      </div>
      <div class="activity-box-part">
        <heading-and-label
          :editMode="editMode"
          labelFor="dpia-comment-matchingDatasets"
          :level="3"
        ><span class="required">* </span>6. {{ $t('dpia.evaluationQuestions.matchingDatasets') }}</heading-and-label>
        <HelptextPopup
          target="DpiaQ6"
          :helptext="$t('dpia.evaluationQuestions.matchingDatasetsHelptext')"
        />
        <yes-no-input
          :editMode="editMode"
          :value.sync="matchingDatasetsAnswer"
          :emptyDescription="$t('general.emptydata')"
          @update-yes-no="updateMatchingDatasetsAnswer"
        />
        <text-area-input-comment
          id="dpia-comment-matchingDatasets"
          class="mb-2"
          :editMode="editMode"
          :placeholder="$t('dpia.comment')"
          :rows="2"
          :value="matchingDatasetsComment"
          @update-text="updateMatchingDatasetsComment"
        />
      </div>
      <div class="activity-box-part">
        <heading-and-label
          :editMode="editMode"
          labelFor="dpia-comment-vulnerableDataSubjects"
          :level="3"
        ><span class="required">* </span>7. {{ $t('dpia.evaluationQuestions.vulnerableDataSubjects') }}</heading-and-label>
        <HelptextPopup
          target="DpiaQ7"
          :helptext="$t('dpia.evaluationQuestions.vulnerableDataSubjectsHelptext')"
        />
        <yes-no-input
          :editMode="editMode"
          :value.sync="vulnerableDataSubjectsAnswer"
          :emptyDescription="$t('general.emptydata')"
          @update-yes-no="updateVulnerableDataSubjectsAnswer"
        />
        <text-area-input-comment
          id="dpia-comment-vulnerableDataSubjects"
          class="mb-2"
          :editMode="editMode"
          :placeholder="$t('dpia.comment')"
          :rows="2"
          :value="vulnerableDataSubjectsComment"
          @update-text="updateVulnerableDataSubjectsComment"
        />
      </div>
      <div class="activity-box-part">
        <heading-and-label
          :editMode="editMode"
          labelFor="dpia-comment-innovation"
          :level="3"
        ><span class="required">* </span>8. {{ $t('dpia.evaluationQuestions.innovation') }}</heading-and-label>
        <HelptextPopup
          target="DpiaQ8"
          :helptext="$t('dpia.evaluationQuestions.innovationHelptext')"
        />
        <yes-no-input
          :editMode="editMode"
          :value.sync="innovationAnswer"
          :emptyDescription="$t('general.emptydata')"
          @update-yes-no="updateInnovationAnswer"
        />
        <text-area-input-comment
          id="dpia-comment-innovation"
          class="mb-2"
          :editMode="editMode"
          :placeholder="$t('dpia.comment')"
          :rows="2"
          :value="innovationComment"
          @update-text="updateInnovationComment"
        />
      </div>
      <div class="activity-box-part">
        <heading-and-label
          :editMode="editMode"
          labelFor="dpia-comment-preventionOfUse"
          :level="3"
        ><span class="required">* </span>9. {{ $t('dpia.evaluationQuestions.preventionOfUse') }}</heading-and-label>
        <HelptextPopup
          target="DpiaQ9"
          :helptext="$t('dpia.evaluationQuestions.preventionOfUseHelptext')"
        />
        <yes-no-input
          :editMode="editMode"
          :value.sync="preventionOfUseAnswer"
          :emptyDescription="$t('general.emptydata')"
          @update-yes-no="updatePreventionOfUseAnswer"
        />
        <text-area-input-comment
          id="dpia-comment-preventionOfUse"
          class="mb-2"
          :editMode="editMode"
          :placeholder="$t('dpia.comment')"
          :rows="2"
          :value="preventionOfUseComment"
          @update-text="updatePreventionOfUseComment"
        />
      </div>
      <div
        class="activity-box-part"
        v-if="myEvaluation.conclusion == null || editMode"
      >
        <heading-and-label
          :editMode="editMode"
          labelFor="dpia-general-comment"
          :level="3"
        >{{ $t('dpia.anyComments') }}</heading-and-label>
        <text-area-input-comment
          id="dpia-general-comment"
          class="mb-2"
          :editMode="editMode"
          :placeholder="$t('dpia.comment')"
          :emptyDescription="$t('general.emptydata')"
          :rows="3"
          :value="myEvaluation.comment"
          @update-text="updateComment"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Multiselect from 'vue-multiselect'
import YesNoInput from '../common/YesNoInput'
import HelptextPopup from '../processingActivity/HelptextPopup'
import queryString from 'query-string'
import LoadingOverlay from '../common/LoadingOverlay'
import HeadingAndLabel from '../common/HeadingAndLabel'
import TextAreaInputComment from '../common/TextAreaInputComment'

export default {
  name: 'evaluation-questions',
  components: {
    Multiselect,
    YesNoInput,
    HelptextPopup,
    LoadingOverlay,
    HeadingAndLabel,
    TextAreaInputComment
  },
  created: function () {
    this.getOptions()
    this.setUp()
  },
  data: function () {
    return {
      activityChosen: false,
      selectedActivity: null,
      scoringOfPeopleAnswer: null,
      scoringOfPeopleComment: null,
      automaticDecisionsAnswer: null,
      automaticDecisionsComment: null,
      largeScaleMonitoringAnswer: null,
      largeScaleMonitoringComment: null,
      sensitiveInformationAnswer: null,
      sensitiveInformationComment: null,
      largeScaleProcessingAnswer: null,
      largeScaleProcessingComment: null,
      matchingDatasetsAnswer: null,
      matchingDatasetsComment: null,
      vulnerableDataSubjectsAnswer: null,
      vulnerableDataSubjectsComment: null,
      innovationAnswer: null,
      innovationComment: null,
      preventionOfUseAnswer: null,
      preventionOfUseComment: null,
      isLoadingActivities: false,
      approvedActivityWithoutDpiaOptions: [],
      myEvaluation: {
        name: null,
        conclusion: false,
        processingActivityIds: [],
        answers: [],
        comment: null
      }
    }
  },
  props: {
    evaluation: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean
    },
    sentFromExistingEvaluation: {
      type: Boolean
    },
    sentFromActivity: {
      type: Boolean,
      default: false
    },
    activityId: {
      type: String
    }
  },
  methods: {
    getOptions () {
      this.isLoadingActivities = true
      axios.get('/api/processingActivities/', {
        params: {
          filter: null,
          textSearch: null
        },
        paramsSerializer: params => {
          return queryString.stringify(params, { arrayFormat: 'index', skipNull: true })
        }
      })
        .then(response => {
          if (response.status === 200 && response.data) {
            this.approvedActivityWithoutDpiaOptions = response.data.filter(activity => {
              return activity.assignedUser.statusCode === 1 && (
                !activity.routineAndBestPractices.dpiaEvaluationInformation ||
                activity.routineAndBestPractices.dpiaEvaluationInformation.dpiaEvaluationStatus === 0
              )
            }).sort((a, b) =>
              a.aboutProcessingActivity.title.localeCompare(b.aboutProcessingActivity.title, 'no')
            )
          }
        })
        .catch(e => {
          console.error('CATCH: ', e)
        })
        .finally(() => {
          this.isLoadingActivities = false
        })
    },

    setUp () {
      if (this.sentFromActivity) {
        axios.get('/api/processingActivities/' + this.activityId)
          .then(response => {
            this.updateSelectedActivityForEvaluation(response.data)
          })
          .catch(e => {
            this.errMsg = this.$t('errMsg.getGenericErrorMessage') + '\n' + e.message
          })
      }
      this.myEvaluation = this.evaluation
      this.myEvaluation.name = this.myEvaluation.name ? this.myEvaluation.name : null
      this.myEvaluation.processingActivityIds = this.myEvaluation.processingActivityIds ? this.myEvaluation.processingActivityIds : []
      this.myEvaluation.answers = this.myEvaluation.answers ? this.myEvaluation.answers : []
      this.myEvaluation.comment = this.myEvaluation.comment ? this.myEvaluation.comment : null
      if (this.myEvaluation.answers.length > 0) {
        for (const QaA of this.myEvaluation.answers) {
          switch (QaA.question) {
            case 'scoringOfPeopleQuestion':
              this.scoringOfPeopleAnswer = QaA.answer
              this.scoringOfPeopleComment = QaA.comment
              break
            case 'automaticDecisionsQuestion':
              this.automaticDecisionsAnswer = QaA.answer
              this.automaticDecisionsComment = QaA.comment
              break
            case 'largeScaleMonitoringQuestion':
              this.largeScaleMonitoringAnswer = QaA.answer
              this.largeScaleMonitoringComment = QaA.comment
              break
            case 'sensitiveInformationQuestion':
              this.sensitiveInformationAnswer = QaA.answer
              this.sensitiveInformationComment = QaA.comment
              break
            case 'largeScaleProcessingQuestion':
              this.largeScaleProcessingAnswer = QaA.answer
              this.largeScaleProcessingComment = QaA.comment
              break
            case 'matchingDatasetsQuestion':
              this.matchingDatasetsAnswer = QaA.answer
              this.matchingDatasetsComment = QaA.comment
              break
            case 'vulnerableDataSubjectsQuestion':
              this.vulnerableDataSubjectsAnswer = QaA.answer
              this.vulnerableDataSubjectsComment = QaA.comment
              break
            case 'innovationQuestion':
              this.innovationAnswer = QaA.answer
              this.innovationComment = QaA.comment
              break
            case 'preventionOfUseQuestion':
              this.preventionOfUseAnswer = QaA.answer
              this.preventionOfUseComment = QaA.comment
              break
          }
        }
      } else {
        this.myEvaluation.answers = []
      }
    },

    customLabel ({ aboutProcessingActivity }) {
      return `${aboutProcessingActivity.title}`
    },

    disableAlert () {
      this.$emit('disable-alert')
    },

    updateSelectedActivityForEvaluation (activity) {
      this.selectedActivity = activity
      if (activity) {
        this.myEvaluation.name = activity.aboutProcessingActivity.title
        this.myEvaluation.processingActivityIds = [{ id: activity.id, title: activity.aboutProcessingActivity.title }]
        this.activityChosen = true
      } else {
        this.myEvaluation.name = null
        this.myEvaluation.processingActivityIds = []
        this.activityChosen = false
      }
    },

    updateScoringOfPeopleAnswer (answer) {
      this.updateAnswers('scoringOfPeopleQuestion', answer)
    },
    updateScoringOfPeopleComment (comment) {
      this.updateComments('scoringOfPeopleQuestion', comment)
    },

    updateAutomaticDecisionsAnswer (answer) {
      this.updateAnswers('automaticDecisionsQuestion', answer)
    },
    updateAutomaticDecisionsComment (comment) {
      this.updateComments('automaticDecisionsQuestion', comment)
    },

    updateLargeScaleMonitoringAnswer (answer) {
      this.updateAnswers('largeScaleMonitoringQuestion', answer)
    },
    updateLargeScaleMonitoringComment (comment) {
      this.updateComments('largeScaleMonitoringQuestion', comment)
    },

    updateSensitiveInformationAnswer (answer) {
      this.updateAnswers('sensitiveInformationQuestion', answer)
    },
    updateSensitiveInformationComment (comment) {
      this.updateComments('sensitiveInformationQuestion', comment)
    },

    updateLargeScaleProcessingAnswer (answer) {
      this.updateAnswers('largeScaleProcessingQuestion', answer)
    },
    updateLargeScaleProcessingComment (comment) {
      this.updateComments('largeScaleProcessingQuestion', comment)
    },

    updateMatchingDatasetsAnswer (answer) {
      this.updateAnswers('matchingDatasetsQuestion', answer)
    },
    updateMatchingDatasetsComment (comment) {
      this.updateComments('matchingDatasetsQuestion', comment)
    },

    updateVulnerableDataSubjectsAnswer (answer) {
      this.updateAnswers('vulnerableDataSubjectsQuestion', answer)
    },
    updateVulnerableDataSubjectsComment (comment) {
      this.updateComments('vulnerableDataSubjectsQuestion', comment)
    },

    updateInnovationAnswer (answer) {
      this.updateAnswers('innovationQuestion', answer)
    },
    updateInnovationComment (comment) {
      this.updateComments('innovationQuestion', comment)
    },

    updatePreventionOfUseAnswer (answer) {
      this.updateAnswers('preventionOfUseQuestion', answer)
    },
    updatePreventionOfUseComment (comment) {
      this.updateComments('preventionOfUseQuestion', comment)
    },

    updateComment (comment) {
      this.myEvaluation.comment = comment
    },

    updateAnswers (question, answer) {
      const questionIndex = this.myEvaluation.answers.findIndex(obj => obj.question === question)
      if (questionIndex !== -1) {
        if (this.myEvaluation.answers[questionIndex].comment !== null && this.myEvaluation.answers[questionIndex].comment !== undefined) {
          this.myEvaluation.answers[questionIndex] = { question, answer, comment: this.myEvaluation.answers[questionIndex].comment }
        } else {
          this.myEvaluation.answers[questionIndex] = { question, answer }
        }
        this.$emit('update-evaluation', this.myEvaluation)
      } else {
        this.myEvaluation.answers.push({ question, answer, comment: null })
      }
    },

    updateComments (question, comment) {
      const questionIndex = this.myEvaluation.answers.findIndex(obj => obj.question === question)
      if (questionIndex !== -1) {
        this.myEvaluation.answers[questionIndex].comment = comment
        this.$emit('update-evaluation', this.myEvaluation)
      } else {
        this.myEvaluation.answers.push({ question, answer: null, comment })
      }
    }

  },
  watch: {
    myEvaluation: {
      handler () {
        this.$emit('update-evaluation', this.myEvaluation)
      },
      deep: true
    },
    evaluation: {
      handler () {
        this.setUp()
      }
    }
  }
}
</script>
