<template>
  <div v-if="editMode">
    <multiselect
      :id="id"
      v-model="selectedItems"
      class="mb-2"
      :clear-on-select="true"
      :close-on-select="false"
      :hide-selected="false"
      label="name"
      :max-height="300"
      :multiple="true"
      :options="getOptions()"
      :placeholder="placeholder"
      :show-labels="false"
      track-by="id"
      @input="updateSelectInput" />
  </div>
  <div v-else>
    <b-list-group v-if="selectedItems.length" class="flex-wrap" horizontal>
      <div v-for="item in selectedItems" :key="item.id">
        <b-badge :id="item.name" class="m-1" variant="primary">
          {{ item.name }}
        </b-badge>
        <StaticCodePopup v-if="item.hasOwnProperty('description') && item.description !== null && item.description !== ''" :target="item.name" :helptext="item.description"/>
      </div>
    </b-list-group>
    <p v-else class="empty-value">{{ emptyDescription }}</p>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import StaticCodePopup from '../staticCodes/StaticCodePopover'
import { sortByNameAlphabetically } from '../../shared/sorting'

export default {
  name: 'multi-select-input',
  components: {
    Multiselect,
    StaticCodePopup
  },
  data: function () {
    return {
      selectedItems: []
    }
  },
  props: {
    emptyDescription: {
      type: String
    },
    id: {
      type: String
    },
    selectedOptions: {
      type: Array
    },
    options: {
      type: Array
    },
    placeholder: {
      type: String
    },
    editMode: {
      type: Boolean
    }
  },
  created () {
    this.selectedItems = [...this.selectedOptions].sort(sortByNameAlphabetically)
  },
  methods: {
    updateSelectInput () {
      this.selectedItems.sort(sortByNameAlphabetically)
      this.$emit('update-select', this.selectedItems)
    },
    getOptions () {
      return [...this.options].sort(sortByNameAlphabetically)
    }
  },
  watch: {
    selectedOptions: function () {
      this.selectedItems = [...this.selectedOptions].sort(sortByNameAlphabetically)
    }
  }
}
</script>
