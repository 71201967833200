<template>
  <div v-if="editMode">
    <multiselect
      :id="id"
      v-model="selectedItem"
      :allow-empty="true"
      class="mb-2"
      :clear-on-select="true"
      :hide-selected="false"
      label="name"
      :max-height="300"
      :options="getOptions()"
      :placeholder="placeholder"
      :show-labels="false"
      track-by="id"
      @input="updateSelectInput" />
  </div>
  <div v-else>
    <p v-if="!editMode && selectedItem != null" class="font-weight-bold">
      {{ selectedItem.name }}
    </p>
    <p v-else-if="!editMode && selectedItem == null" class="empty-value">
      {{emptyDescription}}
    </p>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import { sortByNameAlphabetically } from '../../shared/sorting'

export default {
  name: 'single-select-input',
  components: {
    Multiselect
  },
  data: function () {
    return {
      selectedItem: null
    }
  },
  props: {
    emptyDescription: {
      type: String
    },
    id: {
      type: String
    },
    selectedOption: {
      type: Object
    },
    options: {
      type: Array
    },
    placeholder: {
      type: String
    },
    editMode: {
      type: Boolean
    }
  },
  created () {
    this.selectedItem = this.selectedOption
  },
  methods: {
    updateSelectInput () {
      this.$emit('update-select', this.selectedItem)
    },
    getOptions () {
      return [...this.options].sort(sortByNameAlphabetically)
    }
  }
}
</script>
