import Vue from 'vue'
import Router from 'vue-router'
import Protocol from './views/Protocol.vue'
import Login from './views/Login.vue'
import AboutProtocol from './views/AboutProtocol.vue'
import StaticCode from './views/StaticCode.vue'
import Settings from './views/Settings.vue'
import PageNotFound from './views/PageNotFound.vue'
import ErrorPopup from './views/ErrorPopup.vue'
import Dpia from './views/Dpia.vue'
import DashboardContainer from './views/DashboardContainer.vue'
import AreaStatistics from './views/AreaStatistics.vue'
import { localApi } from './services/local.api.service'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'protocol',
      component: Protocol,
      meta: { authorize: [localApi.roles.user, localApi.roles.admin] },
      props: true
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: DashboardContainer,
      meta: { authorize: [localApi.roles.user, localApi.roles.admin] }
    },
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: { authorize: [] }
    },
    {
      path: '/aboutprotocol',
      name: 'aboutprotocol',
      component: AboutProtocol,
      meta: { authorize: [localApi.roles.admin] }
    },
    {
      path: '/settings',
      name: 'settings',
      component: Settings,
      meta: { authorize: [localApi.roles.admin] }
    },
    {
      path: '/staticcode',
      name: 'staticcode',
      component: StaticCode,
      meta: { authorize: [localApi.roles.user, localApi.roles.admin] }
    },
    {
      path: '/dpia',
      name: 'dpia',
      component: Dpia,
      meta: { authorize: [localApi.roles.user, localApi.roles.admin] }
    },
    {
      path: '/errorpopup',
      name: 'errorpopup',
      component: ErrorPopup,
      meta: { authorize: [] }
    },
    {
      path: '/areastatistics',
      name: 'areastatistics',
      component: AreaStatistics,
      meta: { authorize: [localApi.roles.admin] }
    },
    {
      path: '*',
      component: PageNotFound,
      meta: { authorize: [] }
    }
  ]
})
