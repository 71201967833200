<template>
  <div>
    <DashboardDataWrapper :inputFilter="{areaIds: this.$route.meta.user.areas.map((area) => area.id) }"
      @update-filter-options="updateFilterOptions">
      <template v-slot:filter="{fetchActivitiesCallback, updateFilterOptions, changeSortCallback}">
        <div v-if="$route.meta.user.isAdmin" class="mt-3 ml-4 mr-5">
          <filter-component
            @change-sort="changeSortCallback"
            :callback="fetchActivitiesCallback"
            @update-filter-options="updateFilterOptions"
          />
        </div>
      </template>

      <template v-slot:dashboardTop="{ statistics, activities, fetchActivitiesCallback }">
        <div class="mt-3 d-flex">
          <div
            v-if="activities.length === 0"
            class="shadow-sm bg-white statisticsCard m-1"
          >
            <p class="h2 text-muted text-center">{{$t("dashboard.noActivities.goTo")}}
              <router-link :to="{
                name: 'protocol'
                }">{{$t("dashboard.noActivities.protocol")}}</router-link> {{$t("dashboard.noActivities.addActivities")}}
            </p>
          </div>
          <StatisticsVisualizer
            v-if="
                activities.length
                !==0"
            class="pb-4"
            :statistics="statistics"
            :filter=" {...dashboardFilterOptions}"
            :title="$t('filter.allStatus')"
          />
          <DashboardActivity
            @update-processing-activities="fetchActivitiesCallback()"
            :isAdminDashboard="true"
            :activities="activities"
            :sortActivityOn="'lastModified'"
            :cardTitle="$t('dashboard.lastModified')"
          />
          <DashboardActivity
            @update-processing-activities="fetchActivitiesCallback()"
            :isAdminDashboard="true"
            :activities="activities"
            :sortActivityOn="'nextDeadline'"
            :cardTitle="$t('dashboard.nextDeadline')"
          />
        </div>
      </template>

      <template v-slot:board="{activities, fetchActivitiesCallback, isLoading}">
        <Board
          :activities="activities"
          :isAdmin="true"
          v-bind:isLoading="isLoading"
          @update-processing-activities="fetchActivitiesCallback()"
        ></Board>
      </template>
    </DashboardDataWrapper>
  </div>
</template>

<script>
import DashboardDataWrapper from '../components/dashboard/DashboardDataWrapper.vue'
import StatisticsVisualizer from '../components/dashboard/StatisticsVisualizer.vue'
import Board from '../components/dashboard/Board.vue'
import DashboardActivity from '../components/dashboard/DashboardActivity.vue'
import FilterComponent from '../components/dashboard/StatusBoardFilter.vue'

export default {
  components: {
    DashboardDataWrapper,
    StatisticsVisualizer,
    Board,
    DashboardActivity,
    FilterComponent
  },
  data: function () {
    return {
      dashboardFilterOptions: {}
    }
  },
  methods: {
    updateFilterOptions (filter) {
      this.dashboardFilterOptions = filter
    }
  }
}
</script>
