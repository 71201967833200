<template>
  <div>
    <b-spinner
      variant="secondary"
      class="spinner mb-4"
      v-if="this.isLoading"
    />
    <multi-select-input
      v-if="!isLoading"
      id="about-areas"
      class="about-multiselect mb-2" style="width: 34vw"
      :editMode="editMode"
      :emptyDescription="$t('dashboard.noAreas')"
      :options="areasOptions"
      :placeholder="$t('activity.aboutRecordAndProcessing.area')"
      :selectedOptions="selectedAreas"
      @update-select="updateAreas"
    />
  </div>
</template>

<script>
import MultiSelectInput from '../common/MultiSelectInput.vue'
import axios from 'axios'

export default {
  components: {
    MultiSelectInput
  },
  data: function () {
    return {
      areasOptions: [],
      selectedAreas: this.selectedAreasProp
    }
  },
  props: {
    editMode: {
      type: Boolean
    },
    user: {
      type: Object
    },
    selectedAreasProp: {
      type: Array
    },
    isLoading: {
      type: Boolean
    }
  },
  created: function () {
    this.getAreasOptions()
  },
  methods: {
    async getAreasOptions () {
      axios
        .get('/api/areas')
        .then((res) => {
          if (res.status === 200) {
            this.areasOptions = res.data
          }
        })
        .catch(() => {
          this.areasErrMsg = this.$t('errMsg.getGenericErrorMessage')
        })
        .finally(() => {})
    },
    updateAreas (areas) {
      this.selectedAreas = areas.map((area) => area)
      this.$emit('change-areas', this.selectedAreas)
    }
  }
}
</script>

<style>
</style>
