import Vue from 'vue'
import VueLocalStorage from 'vue-localstorage'
import Languages from '../../locales'

Vue.use(VueLocalStorage)
const supportedLanguages = Object.getOwnPropertyNames(Languages)
const setInitialLanguage = () => {
  const language = Vue.localStorage.get('language')
  const dom = document.documentElement
  if (language) {
    dom.setAttribute('lang', language)
    return language
  }
  const initialLanguage = 'no'
  Vue.localStorage.set('language', initialLanguage)
  dom.setAttribute('lang', 'no')
  return initialLanguage
}

export default {
  namespaced: true,
  state: {
    language: setInitialLanguage()
  },
  mutations: {
    SET_LANGUAGE (state, lang) {
      Vue.localStorage.set('language', lang)
      state.language = lang
      const dom = document.documentElement
      dom.setAttribute('lang', lang)
    }
  },
  actions: {
    setLanguage ({ commit }, languages) {
      if (typeof languages === 'string') {
        commit('SET_LANGUAGE', languages)
      } else {
        const language = supportedLanguages.find(sl =>
          languages.find(l => (l.split(new RegExp(sl, 'gi')).length - 1 > 0 ? sl : null)))
        commit('SET_LANGUAGE', language)
      }
    }
  }
}
