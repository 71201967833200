<template>
<div v-if="$route.meta.user.isAdmin"
      :class="'shadow-sm bg-white pl-4 pr-4 pt-3 ml-4 mr-4 mb-4 mt-1'"
      style="minHeight: 14rem">
    <h2 class="h2">{{ $t('admin.tenantActivityCardSettings') }}</h2>
    <p class="helptext">{{ $t('activity.activityCardsettingsHelpText', {nrOfOptions: maxOptions})}}</p>
    <b-spinner variant="secondary" class="spinner" v-if="loading"/>
    <fieldset class="fieldset-accordion-style" v-if="!this.loading">
          <b-form-checkbox
            id="checkbox-assignedUserStatusAndDeadlineRow"
            size="sm"
            @change="updateTentantActivityCardSettings()"
            :disabled="isMaxOptionsSelected && !tenantActivityCardSettings.assignedUserStatusAndDeadlineRow"
            v-model="tenantActivityCardSettings.assignedUserStatusAndDeadlineRow"
            name="checkbox-assignedUserStatusAndDeadlineRow">
            {{ $t('activity.assignedUserStatusAndDeadlineRow')}}
        </b-form-checkbox>
          <b-form-checkbox
            id="checkbox-purpose"
            size="sm"
            @change="updateTentantActivityCardSettings()"
            :disabled="isMaxOptionsSelected && !tenantActivityCardSettings.purpose"
            v-model="tenantActivityCardSettings.purpose"
            name="checkbox-purpose">
            {{ $t('activity.aboutRecordAndProcessing.purpose')}}
        </b-form-checkbox>
        <b-form-checkbox
            id="checkbox-areas"
            size="sm"
            @change="updateTentantActivityCardSettings()"
            :disabled="isMaxOptionsSelected && !tenantActivityCardSettings.areas"
            v-model="tenantActivityCardSettings.areas"
            name="checkbox-areas">
            {{ $t('activity.aboutRecordAndProcessing.areas') }}
        </b-form-checkbox>
        <b-form-checkbox
            id="checkbox-responsibleManager"
            size="sm"
            @change="updateTentantActivityCardSettings()"
            :disabled="isMaxOptionsSelected && !tenantActivityCardSettings.responsibleManager"
            v-model="tenantActivityCardSettings.responsibleManager"
            name="checkbox-responsibleManager">
            {{ $t('activity.aboutRecordAndProcessing.responsibleManager') }}
        </b-form-checkbox>
        <b-form-checkbox
            id="checkbox-categoriesOfDataSubjects"
            size="sm"
            @change="updateTentantActivityCardSettings()"
            :disabled="isMaxOptionsSelected && !tenantActivityCardSettings.categoriesOfDataSubjects"
            v-model="tenantActivityCardSettings.categoriesOfDataSubjects"
            name="checkbox-categoriesOfDataSubjects">
            {{ $t('activity.aboutRecordAndProcessing.categoriesOfDataSubjects') }}
        </b-form-checkbox>
        <b-form-checkbox
            id="checkbox-sharedResponsibility"
            size="sm"
            @change="updateTentantActivityCardSettings()"
            :disabled="isMaxOptionsSelected && !tenantActivityCardSettings.sharedResponsibility"
            v-model="tenantActivityCardSettings.sharedResponsibility"
            name="checkbox-sharedResponsibility">
            {{ $t('activity.sharedResponsibility') }}
        </b-form-checkbox>
        <b-form-checkbox
            id="checkbox-disclosuresOfData"
            size="sm"
            @change="updateTentantActivityCardSettings()"
            :disabled="isMaxOptionsSelected && !tenantActivityCardSettings.disclosuresOfData"
            v-model="tenantActivityCardSettings.disclosuresOfData"
            name="checkbox-disclosuresOfData">
            {{ $t('activity.disclosureOfData') }}
        </b-form-checkbox>
        <b-form-checkbox
            id="checkbox-routinesAndApprovalComment"
            size="sm"
            @change="updateTentantActivityCardSettings()"
            :disabled="isMaxOptionsSelected && !tenantActivityCardSettings.routinesAndApprovalComment"
            v-model="tenantActivityCardSettings.routinesAndApprovalComment"
            name="checkbox-routinesAndApprovalComment">
            {{ $t('activity.routinesAndApprovalTab.comment') }}
        </b-form-checkbox>
            </fieldset>
</div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'tenant-activity-card-settings',
  data: function () {
    return {
      tenantActivityCardSettings: {
        id: null,
        assignedUserStatusAndDeadlineRow: true,
        purpose: false,
        areas: true,
        responsibleManager: false,
        categoriesOfDataSubjects: false,
        sharedResponsibility: true,
        disclosuresOfData: true,
        routinesAndApprovalComment: false
      },
      maxOptions: 5,
      loading: {},
      errMsg: {}
    }
  },
  created () {
    this.getTentantActivityCardSettings()
  },
  computed: {
    numberOfOptionsChecked: function () {
      return (Object.values(this.tenantActivityCardSettings).filter(val => val === true)).length
    },
    isMaxOptionsSelected: function () {
      return this.numberOfOptionsChecked >= this.maxOptions
    }
  },
  methods: {
    async getTentantActivityCardSettings () {
      this.loading = true
      axios.get('api/tenantActivityCardSettings/').then((res) => {
        if (res.status === 200 && res.data) {
          this.tenantActivityCardSettings = res.data
          this.loading = false
        }
      }).catch(() => {
        this.errMsg.text = this.$t('errMsg.getGenericErrorMessage')
        this.loading = false
      })
    },
    updateTentantActivityCardSettings () {
      console.log('update')
      axios.put('api/tenantActivityCardSettings/' + this.tenantActivityCardSettings.id, this.tenantActivityCardSettings).then(async () => {
      }).catch(() => {
        this.errMsg.text = this.$t('errMsg.postGenericErrorMessage')
      })
    }
  }
}
</script>
