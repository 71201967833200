<template>
  <div v-if="editMode">
    <b-form-radio-group
      :id="id"
      v-model="localValue"
      :options="yesNoOptions"
      @change="updateYesNoInput"></b-form-radio-group>
  </div>
  <div v-else>
    <p v-if="!editMode && localValue != null" class="font-weight-bold m-0">
      {{ getText(localValue) }}
    </p>
    <p v-else-if="!editMode && localValue == null" class="empty-value">
      {{ emptyDescription }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'yes-no-input',
  props: {
    id: {
      type: String
    },
    emptyDescription: {
      type: String
    },
    value: {
      type: Boolean,
      required: false,
      default: false
    },
    editMode: {
      type: Boolean
    }
  },
  data: function () {
    return {
      yesNoOptions: [
        { text: this.$i18n.t('general.yes'), value: true },
        { text: this.$i18n.t('general.no'), value: false }
      ],
      localValue: this.value
    }
  },
  computed: {
    getCheckedClassName: function () {
      if (this.value == null) return ''
      return this.value === true ? 'check-yes' : 'check-no'
    }
  },
  methods: {
    getText (value) {
      if (value === undefined || value === null) return ''
      return value ? this.$t('general.yes') : this.$t('general.no')
    },
    updateYesNoInput (val) {
      this.$emit('update:value', val)
      this.$emit('update-yes-no', val)
    }
  }
}
</script>
<style lang="scss" scoped>
@import './../../styles/_variables.scss';
.check-yes {
    color: $nc-green-1;
}
.check-no {
    color: $nc-red-1;
}
</style>
