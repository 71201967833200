<!-- eslint-disable vue/multi-word-component-names -->
<script>
export default {
  name: 'heading',
  template: '#anchored-heading-template',
  props: {
    level: {
      type: Number,
      required: true
    }
  },
  render: function (createElement) {
    return createElement('h' + this.level, this.$slots.default)
  }
}
</script>
