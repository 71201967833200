<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div>
    <div v-if="isDataDisclosed">
      <b-form-group class="activity-box-inner">
        <heading-and-label :editMode="editMode" :labelFor="'disclosure-business-' + idx" :level="4">
          15.1 {{ $t('activity.disclosureOfDataTab.bussiness/Organisation') }}
        </heading-and-label>
        <single-select-input
          :id="'disclosure-business-' + idx"
          class="mb-2 color-multiselect"
          :editMode="editMode"
          :emptyDescription="$t('general.emptydata')"
          :options="businessOrganisationOptions"
          :placeholder="$t('activity.disclosureOfDataTab.bussiness/Organisation')"
          :selectedOption="selectedBusinessOrganisation"
          @update-select="updateBusinessOrganisation" />

        <heading-and-label :editMode="editMode" :labelFor="'disclosure-business-2-' + idx" :level="4">
          {{ $t('activity.disclosureOfDataTab.bussiness/Organisation') }}
        </heading-and-label>
        <text-input
          :id="'disclosure-business-2-' + idx"
          class="mb-2"
          :editMode="editMode"
          :emptyDescription="$t('general.emptydata')"
          :placeholder="$t('activity.disclosureOfDataTab.bussiness/Organisation')"
          :value="myDisclosure.customBusinessOrganisationName"
          @update-text="updateBusinessOrganisationName" />

        <heading-and-label :editMode="editMode" :labelFor="'disclosure-contact-' + idx" :level="4">
          {{ $t('activity.disclosureOfDataTab.contactInformation') }}
        </heading-and-label>
        <text-input
          :id="'disclosure-contact-' + idx"
          class="mb-2"
          :editMode="editMode"
          :emptyDescription="$t('general.emptydata')"
          :placeholder="$t('activity.disclosureOfDataTab.contactInformation')"
          :value="myDisclosure.contactInformation"
          @update-text="updateContactInformation" />
      </b-form-group>

      <b-form-group class="activity-box-inner">
        <heading-and-label :editMode="editMode" :labelFor="'disclosure-categories-' + idx" :level="4">
          15.2 {{ $t('activity.disclosureOfDataTab.categoriesOfDataProvided') }}
        </heading-and-label>
        <multi-select-input
          :id="'disclosure-categories-' + idx"
          class="mb-2 color-multiselect"
          :editMode="editMode"
          :emptyDescription="$t('general.emptydata')"
          :options="selectedCategoriesOfPersonalData"
          :placeholder="$t('activity.disclosureOfDataTab.categoriesOfDataProvided')"
          :selectedOptions="selectedPersonalData"
          @update-select="updateCategoriesOfPersonalData" />
      </b-form-group>

      <b-form-group class="activity-box-inner">
        <heading-and-label :editMode="editMode" :labelFor="'disclosure-purpose-' + idx" :level="4">
          15.3 {{ $t('activity.disclosureOfDataTab.purposeOfSharingData') }}
        </heading-and-label>
        <single-select-input
          :id="'disclosure-purpose-' + idx"
          class="mb-2 color-multiselect"
          :editMode="editMode"
          :emptyDescription="$t('general.emptydata')"
          :multiple="false"
          :options="purposeOfDataSharingOptions"
          :placeholder="$t('activity.disclosureOfDataTab.purposeOfSharingData')"
          :selectedOption="selectedPurposeOfDataSharing"
          @update-select="updatePurposeOfDataSharing" />

        <div v-if="selectedPurposeOfDataSharing">
          <div v-if="selectedPurposeOfDataSharing.id === 1">
            <heading-and-label :editMode="editMode" :labelFor="'disclosure-legal-obligation-' + idx" :level="4">
              {{ $t('activity.disclosureOfDataTab.legalObligationForSharing') }}
            </heading-and-label>
            <text-input
              :id="'disclosure-legal-obligation-' + idx"
              class="mb-2"
              :editMode="editMode"
              :emptyDescription="$t('general.emptydata')"
              :placeholder="$t('activity.disclosureOfDataTab.legalObligationForSharing')"
              :value="myDisclosure.sharingPurpose.legalObligationForSharing"
              @update-text="updateLegalObligationForSharing" />
          </div>

          <div v-if="selectedPurposeOfDataSharing.id === 2">
            <heading-and-label :editMode="editMode" :labelFor="'disclosure-legal-obligation-chapter' + idx" :level="4">
              {{ $t('activity.disclosureOfDataTab.legalObligationFromChapter5InGDPR') }}
            </heading-and-label>
            <multi-select-input
              :id="'disclosure-legal-obligation-chapter' + idx"
              class="mb-2 color-multiselect"
              :editMode="editMode"
              :emptyDescription="$t('general.emptydata')"
              :options="legalObligationsFromGdprOptions"
              :placeholder="$t('activity.disclosureOfDataTab.legalObligationFromChapter5InGDPR')"
              :selectedOptions="myDisclosure.sharingPurpose.legalObligationsFromGdpr"
              @update-select="updateLegalObligationFromGdpr" />

            <heading-and-label :editMode="editMode" :labelFor="'disclosure-legal-chapter5-note' + idx" :level="4">
              {{ $t('activity.disclosureOfDataTab.legalObligationFromChapter5InGDPR') }}
            </heading-and-label>
            <text-input
              :id="'disclosure-legal-chapter5-note' + idx"
              class="mb-2"
              :editMode="editMode"
              :emptyDescription="$t('general.emptydata')"
              :placeholder="$t('activity.disclosureOfDataTab.legalObligationFromChapter5InGDPR')"
              :value="myDisclosure.sharingPurpose.legalObligationFromGdprComment"
              @update-text="updateLegalObligationFromGdprComment" />
          </div>

          <div v-if="[1, 2].includes(selectedPurposeOfDataSharing.id)">
            <heading-and-label :editMode="editMode" :labelFor="'disclosure-archive-reference' + idx" :level="4">
              {{ $t('activity.disclosureOfDataTab.archivesReference') }}
            </heading-and-label>
            <text-input
              :id="'disclosure-archive-reference' + idx"
              class="mb-2"
              :editMode="editMode"
              :emptyDescription="$t('general.emptydata')"
              :placeholder="$t('activity.disclosureOfDataTab.archivesReference')"
              :value="myDisclosure.sharingPurpose.archivesReferences"
              @update-text="updateArchivesReferences" />
          </div>

          <div v-if="[2, 3].includes(selectedPurposeOfDataSharing.id)">
            <heading-and-label :editMode="editMode" :labelFor="'disclosure-description' + idx" :level="4">
              {{ $t('activity.disclosureOfDataTab.description') }}
            </heading-and-label>
            <text-area-input
              :id="'disclosure-description' + idx"
              class="mb-2"
              :editMode="editMode"
              :emptyDescription="$t('general.emptydata')"
              :placeholder="$t('activity.disclosureOfDataTab.description')"
              :value="myDisclosure.sharingPurpose.description"
              @update-text="updateDescription" />
          </div>

          <div v-if="selectedPurposeOfDataSharing.id === 3">
            <heading-and-label :editMode="editMode" :labelFor="'disclosure-consent' + idx" :level="4">
              {{ $t('activity.disclosureOfDataTab.consentForm') }}
            </heading-and-label>
            <file-input
              :id="'disclosure-consent' + idx"
              :activityId="activityId"
              :attachment="myDisclosure.sharingPurpose.consentFormAttachmentInfo"
              class="mb-2 color-multiselect"
              :editMode="editMode"
              :emptyDescription="$t('general.emptydata')"
              @remove-attachment="removeAttachment"
              @update-attachment="updateConsentFormAttachment" />
          </div>
        </div>
      </b-form-group>
    </div>
  </div>
</template>

<script>
import FileInput from '../../common/FileInput'
import HeadingAndLabel from '../../common/HeadingAndLabel'
import MultiSelectInput from '../../common/MultiSelectInput'
import SingleSelectInput from '../../common/SingleSelectInput'
import TextAreaInput from '../../common/TextAreaInput'
import TextInput from '../../common/TextInput'

export default {
  name: 'disclosure',
  components: {
    FileInput,
    HeadingAndLabel,
    MultiSelectInput,
    SingleSelectInput,
    TextAreaInput,
    TextInput
  },
  props: {
    index: {
      type: String
    },
    disclosure: {
      type: Object
    },
    isDataDisclosed: {
      type: Boolean
    },
    editMode: {
      type: Boolean,
      required: true
    },
    activityId: {
      type: String
    },
    selectedCategoriesOfPersonalData: {
      type: Array,
      required: true
    },
    businessOrganisationOptions: {
      type: Array
    },
    purposeOfDataSharingOptions: {
      type: Array
    },
    legalObligationsFromGdprOptions: {
      type: Array
    }
  },
  data: function () {
    return {
      myDisclosure: {},
      selectedBusinessOrganisation: null,
      selectedPersonalData: [],
      selectedPurposeOfDataSharing: null
    }
  },
  created: function () {
    this.setUp()
  },
  methods: {
    setUp () {
      this.idx = this.index
      this.myDisclosure = this.disclosure
      if (this.myDisclosure.businessOrganisation) this.updateBusinessOrganisation(this.myDisclosure.businessOrganisation)
      if (this.myDisclosure.categoriesOfPersonalDataProvided) this.updateCategoriesOfPersonalData(this.myDisclosure.categoriesOfPersonalDataProvided)
      if (!this.myDisclosure.sharingPurpose) this.myDisclosure.sharingPurpose = {}
      if (this.myDisclosure.sharingPurpose.type) this.setUpPurposeOfDataSharing(this.myDisclosure.sharingPurpose.type)
      if (this.myDisclosure.sharingPurpose.legalObligationsFromGdpr) this.updateLegalObligationFromGdpr(this.myDisclosure.sharingPurpose.legalObligationsFromGdpr)
    },
    updateBusinessOrganisationName (businessOrganisationName) {
      this.myDisclosure.customBusinessOrganisationName = businessOrganisationName
    },
    updateBusinessOrganisation (businessOrganisation) {
      if (businessOrganisation) {
        this.selectedBusinessOrganisation = businessOrganisation
        this.myDisclosure.businessOrganisation = businessOrganisation
        this.myDisclosure.businessOrganisationId = businessOrganisation.id
      } else {
        this.selectedBusinessOrganisation = null
        this.myDisclosure.businessOrganisation = null
        this.myDisclosure.businessOrganisationId = null
      }
    },
    updateContactInformation (contactInformation) {
      this.myDisclosure.contactInformation = contactInformation
    },
    updateCategoriesOfPersonalData (categories) {
      this.selectedPersonalData = categories
      this.myDisclosure.categoryOfPersonalDataProvidedIds = categories.map(category => category.id)
    },
    setUpPurposeOfDataSharing (sharingPurposeTypeId) {
      const type = this.purposeOfDataSharingOptions.find(x => x.id === sharingPurposeTypeId)
      this.updatePurposeOfDataSharing(type)
    },
    updatePurposeOfDataSharing (sharingPurposeType) {
      if (sharingPurposeType) {
        this.selectedPurposeOfDataSharing = sharingPurposeType
        this.myDisclosure.sharingPurpose.type = sharingPurposeType.id
      } else {
        this.selectedPurposeOfDataSharing = null
        this.myDisclosure.sharingPurpose.type = null
      }
      if (this.myDisclosure.sharingPurpose.type === 1) {
        this.updateLegalObligationFromGdpr([])
        this.updateLegalObligationFromGdprComment('')
        this.updateDescription('')
        this.myDisclosure.sharingPurpose.consentFormAttachmentInfo = null
        this.myDisclosure.sharingPurpose.consentFormAttachment = null
      }
      if (this.myDisclosure.sharingPurpose.type === 2) {
        this.updateLegalObligationForSharing('')
        this.myDisclosure.sharingPurpose.consentFormAttachmentInfo = null
        this.myDisclosure.sharingPurpose.consentFormAttachment = null
        if (!this.myDisclosure.sharingPurpose.legalObligationsFromGdpr) {
          this.myDisclosure.sharingPurpose.legalObligationsFromGdpr = []
        }
      }

      if (this.myDisclosure.sharingPurpose.type === 3) {
        this.updateLegalObligationFromGdpr([])
        this.updateLegalObligationFromGdprComment('')
        this.updateLegalObligationForSharing('')
        this.updateArchivesReferences('')
      }
    },
    updateLegalObligationForSharing (legalObligationForSharing) {
      this.myDisclosure.sharingPurpose.legalObligationForSharing = legalObligationForSharing
    },
    updateLegalObligationFromGdprComment (comment) {
      this.myDisclosure.sharingPurpose.legalObligationFromGdprComment = comment
    },
    updateLegalObligationFromGdpr (legalObligationFromGdpr) {
      this.myDisclosure.sharingPurpose.legalObligationFromGdprIds = legalObligationFromGdpr.map(x => x.id)
    },
    updateArchivesReferences (archivesReferences) {
      this.myDisclosure.sharingPurpose.archivesReferences = archivesReferences
    },
    updateDescription (description) {
      this.myDisclosure.sharingPurpose.description = description
    },
    updateConsentFormAttachment (attachment) {
      this.myDisclosure.sharingPurpose.consentFormAttachment = attachment
    },
    removeAttachment (attachmentId) {
      if (this.myDisclosure.sharingPurpose.consentFormAttachmentInfo.id === attachmentId) {
        this.myDisclosure.sharingPurpose.consentFormAttachmentInfo = null
      }
    }
  },
  watch: {
    deep: true,
    immediate: true,
    disclosure () {
      this.setUp()
    },
    myDisclosure: {
      handler () {
        this.$emit('update-disclosure', this.myDisclosure)
      },
      deep: true
    }
  }
}
</script>
