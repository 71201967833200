<template>
  <div class="mt-2">

  <b-button-group>
    <b-button
      v-b-toggle="`collapse-${index}`"
      class="mb-2 btn-nc moreLess"
    >
      {{ getToggleDataProcessorText(dataProcessor) }}
    </b-button>
    <b-button v-if="editMode"
      class="mb-2 btn-nc-red"
      @click.prevent="removeDataProcessor(dataProcessor)"
    >
      <i class="nc-delete-white" />
    </b-button>
  </b-button-group>

  <b-collapse :id="`collapse-${index}`" class="mt-2">
    <heading-and-label
      :editMode="editMode"
      labelFor="processing-kind-of"
      :level="3"
    >
      11.1 {{ $t('activity.processingAndStorageTab.kindOfPersonalDataToShare') }}
    </heading-and-label>
    <multi-select-input
      id="processing-kind-of"
      class="color-multiselect"
      :editMode="editMode"
      :emptyDescription="$t('general.emptydata')"
      :options="selectedCategoriesOfPersonalData"
      :placeholder="$t('activity.processingAndStorageTab.selectKindOfPersonalData')"
      :selectedOptions="selectedPersonalDataToShare"
      @update-select="updateDataProcesorSharedPersonalData"
    />
      <HelptextPopup
        :target="`Q11-2-${index}`"
        :helptext="$t('helptext.q11-2')"
      />
      <h3 class="mt-1">11.2 {{ $t('activity.processingAndStorageTab.isTheDataProcessorAThirdCountry') }}</h3>
      <yes-no-input
        :editMode="editMode"
        :emptyDescription="$t('general.emptydata')"
        :value.sync="myDataProcessor.isDataProcessorOutsideEu"
        @update-yes-no="updateIsDataProcessorOutsideEu($event)"
      />
      <div class="mt-3">
        <b-form-group>
          <heading-and-label
            :editMode="editMode"
            labelFor="processing-business-organization"
            :level="4"
          >
            {{ $t('activity.processingAndStorageTab.businessOrganisation') }}
          </heading-and-label>
          <single-select-input
            id="processing-business-organization"
            class="color-multiselect"
            :editMode="editMode"
            :emptyDescription="$t('general.emptydata')"
            :options="businessOrganisationOptions"
            :placeholder="$t('activity.processingAndStorageTab.businessOrganisationPlaceholder')"
            :selectedOption="selectedDataProcessorBusinessOrganisation"
            @update-select="updateDataProcessorBusinessOrganisation($event)"
          />

          <heading-and-label
            :editMode="editMode"
            labelFor="processing-business-other"
            :level="4"
          >
            {{ $t('activity.processingAndStorageTab.businessOrganisationOther') }}
          </heading-and-label>
          <text-input
            id="processing-business-other"
            class="mb-2"
            :editMode="editMode"
            :emptyDescription="$t('general.emptydata')"
            :placeholder="$t('activity.processingAndStorageTab.businessOrganisationOther')"
            :value="myDataProcessor.customBusinessOrganisationName"
            @update-text="updateDataProcessorBusinessOrganisationName($event)"
          />

          <heading-and-label
            :editMode="editMode"
            labelFor="processing-agreement"
            :level="4"
          >
              {{ $t('activity.processingAndStorageTab.agreement') }}
          </heading-and-label>
          <text-input
            id="processing-agreement"
            class="mb-2"
            :editMode="editMode"
            :emptyDescription="$t('general.emptydata')"
            :placeholder="$t('activity.processingAndStorageTab.agreement')"
            :value="myDataProcessor.agreement"
            @update-text="updateThirdCountryAgreement($event)"
          />

          <div v-if="isDataProcessorOutsideEu">
            <heading-and-label
              :editMode="editMode"
              labelFor="processing-reason-for-transfer"
              :level="4"
            >
              {{ $t('activity.processingAndStorageTab.reasonForTransfer') }}
            </heading-and-label>
            <text-input
              id="processing-reason-for-transfer"
              class="mb-2"
              :editMode="editMode"
              :emptyDescription="$t('general.emptydata')"
              :placeholder="$t('activity.processingAndStorageTab.reasonForTransfer')"
              :value="myDataProcessor.reasonForTransfer"
              @update-text="updateReasonForTransfer($event)"
            />

            <heading-and-label
              :editMode="editMode"
              labelFor="processing-legal-obligation-chapter"
              :level="4"
            >
              {{ $t('activity.disclosureOfDataTab.legalObligationFromChapter5InGDPR') }}
            </heading-and-label>
            <multi-select-input
              id="processing-legal-obligation-chapter"
              class="mb-2 color-multiselect"
              :editMode="editMode"
              :emptyDescription="$t('general.emptydata')"
              :options="legalObligationsChapterFive"
              :placeholder="$t('activity.disclosureOfDataTab.legalObligationFromChapter5InGDPR')"
              :selectedOptions="selectedLegalObligationsChapterFive"
              @update-select="updateLegalObligationsChapterFive($event)"
            />

            <heading-and-label
              :editMode="editMode"
              labelFor="processing-legal-obligation-description"
              :level="4"
            >
              {{ $t('activity.lawfulnessOfProcessing.description') }}
            </heading-and-label>
            <text-input
              id="processing-legal-obligation-description"
              class="mb-2"
              :editMode="editMode"
              :emptyDescription="$t('general.emptydata')"
              :placeholder="$t('activity.lawfulnessOfProcessing.description')"
              :value="myDataProcessor.legalObligationDescription"
              @update-text="updateLegalObligationDescription"
            />

            <heading-and-label
              :editMode="editMode"
              labelFor="processing-transfer-file"
              :level="4"
            >
              {{ $t('file.file') }}
            </heading-and-label>
            <file-input
              :id="`processing-transfer-file-${index}`"
              :activityId="activityId"
              :attachment="myDataProcessor.reasonForTransferAttachmentInfo"
              class="mb-2"
              :editMode="editMode"
              :emptyDescription="$t('general.emptydata')"
              @remove-attachment="removeReasonForTransferAttachmentInfo($event)"
              @update-attachment="updateReasonForTransferAttachmentInfo($event)"
            />
          </div>
          <div v-else>
            <heading-and-label
              :editMode="editMode"
              labelFor="processing-agreement-file"
              :level="4"
            >
              {{ $t('file.file') }}
            </heading-and-label>
            <file-input
              :id="`processing-agreement-file-${index}`"
              :activityId="activityId"
              :attachment="myDataProcessor.agreementAttachmentInfo"
              class="mb-2"
              :editMode="editMode"
              :emptyDescription="$t('general.emptydata')"
              @remove-attachment="removeAgreementAttachmentInfo($event)"
              @update-attachment="updateAgreementAttachmentInfo($event)"
            />
          </div>
        </b-form-group>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import FileInput from '../../common/FileInput'
import HeadingAndLabel from '../../common/HeadingAndLabel'
import MultiSelectInput from '../../common/MultiSelectInput'
import SingleSelectInput from '../../common/SingleSelectInput'
import TextInput from '../../common/TextInput'
import YesNoInput from '../../common/YesNoInput'
import HelptextPopup from '../../processingActivity/HelptextPopup'

export default {
  name: 'dataprocessor',
  components: {
    FileInput,
    HeadingAndLabel,
    MultiSelectInput,
    SingleSelectInput,
    TextInput,
    HelptextPopup,
    YesNoInput
  },
  props: {
    index: {
      type: String
    },
    editMode: {
      type: Boolean,
      required: true
    },
    activityId: {
      type: String
    },
    dataProcessor: {
      type: Object
    },
    selectedCategoriesOfPersonalData: {
      type: Array,
      default: () => []
    },
    businessOrganisationOptions: {
      type: Array,
      default: () => []
    },
    legalObligationsChapterFive: {
      type: Array,
      default: () => []
    },
    removeDataProcessor: {
      type: Function
    }
  },
  data: function () {
    return {
      myDataProcessor: {},
      personalDataToShareOptions: [],
      isDataProcessorOutsideEu: [],
      selectedDataProcessorBusinessOrganisation: [],
      selectedLegalObligationsChapterFive: [],
      selectedPersonalDataToShare: []
    }
  },
  created: function () {
    this.setUp()
  },
  methods: {
    setUp () {
      this.myDataProcessor = this.dataProcessor
      this.updateIsDataProcessorOutsideEu(
        this.myDataProcessor.isDataProcessorOutsideEu
      )
      this.updateDataProcessorBusinessOrganisation(
        this.myDataProcessor.businessOrganisation
      )
      if (
        this.myDataProcessor.sharedPersonalDataCategories
      ) {
        this.isThereADataProcessorInvolved = true
        this.updateDataProcesorSharedPersonalData(
          this.myDataProcessor.sharedPersonalDataCategories
        )
        if (
          !this.myDataProcessor.reasonsForTransferLegalObligation
        ) {
          this.myDataProcessor.reasonsForTransferLegalObligation =
            []
        }
        this.updateLegalObligationsChapterFive(
          this.myDataProcessor.reasonsForTransferLegalObligation
        )
        this.updateLegalObligationDescription(
          this.myDataProcessor.legalObligationDescription
        )
      }
    },
    updateDataProcesorSharedPersonalData (categories) {
      this.selectedPersonalDataToShare = categories

      this.myDataProcessor.sharedPersonalDataCategoryIds =
        categories.map((category) => category.id)
    },
    updateIsDataProcessorOutsideEu (isDataProcessorOutsideEu) {
      if (isDataProcessorOutsideEu === undefined) {
        isDataProcessorOutsideEu = null
      }

      this.isDataProcessorOutsideEu = isDataProcessorOutsideEu
      this.myDataProcessor.isDataProcessorOutsideEu = isDataProcessorOutsideEu

      if (!isDataProcessorOutsideEu || isDataProcessorOutsideEu === null) {
        this.myDataProcessor.reasonForTransfer = null
        this.selectedLegalObligationsChapterFive = []
        this.myDataProcessor.reasonForTransferAttachmentInfo = null
      } else {
        this.myDataProcessor.agreementAttachmentInfo = null
      }
    },
    updateDataProcessorBusinessOrganisation (businessOrganisation) {
      if (businessOrganisation) {
        this.selectedDataProcessorBusinessOrganisation = businessOrganisation
        this.myDataProcessor.businessOrganisationId = businessOrganisation.id
      } else {
        this.selectedDataProcessorBusinessOrganisation = null
        this.myDataProcessor.businessOrganisationId = null
      }
    },
    updateDataProcessorBusinessOrganisationName (businessOrganisationName) {
      this.myDataProcessor.customBusinessOrganisationName = businessOrganisationName
    },
    updateThirdCountryAgreement (thirdCountryAgreement) {
      this.myDataProcessor.agreement = thirdCountryAgreement
    },
    updateReasonForTransfer (reasonForTransfer) {
      this.myDataProcessor.reasonForTransfer = reasonForTransfer
    },
    updateLegalObligationsChapterFive (legalObligationsChapterFive) {
      this.selectedLegalObligationsChapterFive = legalObligationsChapterFive

      this.myDataProcessor.reasonForTransferLegalObligationIds =
        legalObligationsChapterFive.map((obligation) => obligation.id)
    },
    updateReasonForTransferAttachmentInfo (reasonForTransferAttachment) {
      this.myDataProcessor.reasonForTransferAttachment = reasonForTransferAttachment
    },
    removeReasonForTransferAttachmentInfo (attachmentId) {
      if (
        this.myDataProcessor
          .reasonForTransferAttachmentInfo.id === attachmentId
      ) {
        this.myDataProcessor.reasonForTransferAttachmentInfo = null
      }
    },
    updateAgreementAttachmentInfo (agreementAttachment) {
      this.myDataProcessor.agreementAttachment =
        agreementAttachment
    },
    removeAgreementAttachmentInfo (attachmentId) {
      if (
        this.myDataProcessor.agreementAttachmentInfo.id === attachmentId
      ) {
        this.myDataProcessor.agreementAttachmentInfo = null
      }
    },
    getToggleDataProcessorText (dataProcessor) {
      if (!dataProcessor.id) {
        return this.$t('activity.processingAndStorageTab.newDataProcessor')
      }
      if (dataProcessor.businessOrganisation) {
        return dataProcessor.businessOrganisation.name
      }
      if (dataProcessor.customBusinessOrganisationName) {
        return dataProcessor.customBusinessOrganisationName
      }
      return this.$t(
        'activity.processingAndStorageTab.businessOrganisationIsntSelected'
      )
    },
    updateLegalObligationDescription (description) {
      this.myDataProcessor.legalObligationDescription = description
    }
  },
  watch: {
    deep: true,
    immediate: true,
    dataProcessor () {
      this.setUp()
    }
  }
}
</script>
